<div>
    <dx-data-grid
        id="bdsReportGrid"
        [dataSource]="gridSource"
        [allowColumnResizing]="false"
        height="80vh"
        minHeight="200px"
    >
        <dxi-column
            dataField="car_init"
            caption="Car Init."
            sortOrder="asc"
            [sortIndex]="0"
        ></dxi-column>
        <dxi-column
            dataField="car_no"
            caption="Car No."
            sortOrder="asc"
            [sortIndex]="1"
        ></dxi-column>
        <dxi-column dataField="car_class" caption="Car Class"></dxi-column>
        <dxi-column dataField="active_status" caption="Active Status"></dxi-column>
    </dx-data-grid>
</div>
