import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Optional,
    Output,
    Self,
    SimpleChanges,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NgControl,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import DataSource from 'devextreme/data/data_source';
import { nameof } from '@bds/core';
import { BdsCustomerService } from '@bds/reference-data-access';
import { RtCustomerFact } from '@bds/reference-models';
import { dxMatStyle } from '@bds/smart-components';
import {
    BdsCustomerDialogComponent,
    BdsCustomerDialogModel,
    BdsCustomerSearchBasicDialogComponent,
    BdsCustomerSearchDialogModel,
} from '@bds/customer';

@Component({
    selector: 'bds-customer-advanced-select',
    templateUrl: './bds-customer-advanced-select.component.html',
    styleUrls: ['./bds-customer-advanced-select.component.scss'],
})
export class BdsCustomerAdvancedSelectComponent
    implements ControlValueAccessor, Validator, OnInit, OnChanges
{
    static nextId = 0;

    consigneeAddInlineButtonOptions = {
        type: 'default',
        icon: 'add',
        stylingMode: 'text',
        onClick: () => {
            this.createConsignee();
        },
    };
    consigneeSearchInlineButtonOptions = {
        type: 'default',
        icon: 'search',
        stylingMode: 'text',
        onClick: () => {
            this.searchForConsignee();
        },
    };

    dataSource: DataSource;
    disabled: boolean;
    id = `bds-customer-advanced-select-${BdsCustomerAdvancedSelectComponent.nextId++}`;
    readonly: boolean;
    searchExp: string[];
    selectedItem: RtCustomerFact;
    val: string;
    valueExp: string;

    get style(): string {
        return dxMatStyle(this.matStyle);
    }

    get value(): string {
        return this.val;
    }

    set value(v: string) {
        if (v !== this.val) {
            this.val = v;
            this.onChangeCallback(v);
        }
    }

    @Input() defaultCustomerType?: string;
    @Input() dense = true;
    @Input() filter: [] = null;
    @Input() isDisabled: boolean = false;
    @Input() isReadonly: boolean = false;
    @Input() isRequired: boolean = false;
    @Input() matFloatLabel = 'always';
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() pageSize = 10;
    @Output() selectionChange: EventEmitter<RtCustomerFact> = new EventEmitter();

    @Input() displayValue = function (data: RtCustomerFact) {
        return data
            ? data.customerCity && data.customerState
                ? `${data.customerName} - ${data.customerCity}, ${data.customerState} (${data.customerNo})`
                : `${data.customerName} (${data.customerNo})`
            : '';
    };

    constructor(
        private customerApiService: BdsCustomerService,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit() {
        this.setControlValidators();

        this.searchExp = [
            this.customerApiService.adapter.metadata.find(
                (f) => f.client === nameof<RtCustomerFact>('customerNo'),
            ).server,
            this.customerApiService.adapter.metadata.find(
                (f) => f.client === nameof<RtCustomerFact>('customerName'),
            ).server,
            this.customerApiService.adapter.metadata.find(
                (f) => f.client === nameof<RtCustomerFact>('customerCity'),
            ).server,
            this.customerApiService.adapter.metadata.find(
                (f) => f.client === nameof<RtCustomerFact>('customerState'),
            ).server,
        ];
        this.valueExp = this.customerApiService.adapter.metadata.find(
            (f) => f.client === nameof<RtCustomerFact>('customerNo'),
        ).server;

        this.setDataSource();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filter) {
            this.selectedItem = null;
            this.value = null;
            this.setDataSource();
        }

        if (changes.isRequired && !changes.isRequired.firstChange) {
            // TODO: May need to clear first
            this.setControlValidators();
        }

        if (changes.isReadonly) {
            this.setDisabledState(this.isReadonly);
        }

        if (changes.isDisabled) {
            this.setDisabledState(this.isDisabled);
        }
    }

    createConsignee() {
        const data: BdsCustomerDialogModel = {
            title: 'Create Consignee',
            actionText: 'Create Consignee',
            action: 'create',
            defaultType: this.defaultCustomerType,
        };
        const dialogRef = this.dialog.open(BdsCustomerDialogComponent, {
            data: data,
        });
        dialogRef.afterClosed().subscribe((s) => {
            if (!!s) {
                this.selectedItem = s;
                this.value = s.customerNo;
                this.selectionChange.emit(s);
            }
        });
    }

    getDisplayValue(data: RtCustomerFact) {
        return this.displayValue(data);
    }

    getError(): string {
        if (this.ngControl && this.ngControl.errors) {
            if (this.ngControl.errors['required']) {
                return 'Required';
            }
        }

        return '';
    }

    onBlur() {
        this.onTouchedCallback();
    }

    onChangeCallback(event) {}

    onSelectionChanged(event) {
        if (event && (event.selectedItem || event.selectedItem === null)) {
            const selectedCust = event.selectedItem as RtCustomerFact;

            if (selectedCust && this.value !== selectedCust.customerNo) {
                this.selectedItem = selectedCust;
                this.value = selectedCust.customerNo;
                this.selectionChange.emit(selectedCust);
            } else if (!selectedCust) {
                this.selectedItem = null;
                this.value = null;
                this.selectionChange.emit(null);
            }
        }
    }

    onTouchedCallback() {}

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    searchForConsignee() {
        // Clear the Skip so that the initial rows will show on the grid
        this.setDataSource();
        const data = new BdsCustomerSearchDialogModel();
        data.actionText = 'Select Consignee';
        data.title = 'Select Consignee';
        data.source = this.dataSource;

        const dialogRef = this.dialog.open(BdsCustomerSearchBasicDialogComponent, {
            data: data,
        });
        dialogRef.afterClosed().subscribe((s: RtCustomerFact) => {
            if (s) {
                this.selectedItem = s;
                this.value = s.customerNo;
                this.selectionChange.emit(s);
            }
        });
    }

    setDataSource() {
        this.dataSource = new DataSource({
            store: this.customerApiService.getODataStore(),
            filter: this.filter,
            paginate: true,
            pageSize: 10,
        });
    }

    setControlValidators() {
        if (this.ngControl) {
            const control = this.ngControl.control;
            const validators: ValidatorFn[] = control.validator ? [control.validator] : [];

            if (this.isRequired) {
                validators.push(Validators.required);
            }

            control.setValidators(validators);
            control.updateValueAndValidity();
        }
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    validate(c: AbstractControl): ValidationErrors {
        const validators: ValidatorFn[] = [];
        if (this.isRequired) {
            validators.push(Validators.required);
        }

        return validators;
    }

    writeValue(value: string): void {
        if (value !== undefined && value !== this.val) {
            this.value = value;
        }
    }
}
