import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RtCustomerFact, RtCustomerFactAdapter } from '@bds/reference-models';
import { Observable, of } from 'rxjs';
import { catchError, map, delay, shareReplay } from 'rxjs/operators';
import ODataStore from 'devextreme/data/odata/store';
import { nameof } from '@bds/core';
import { ApiErrorHandlerService } from '@bds/helpers';
import DataSource from 'devextreme/data/data_source';
//import { BdsEditMaskService } from './bds-edit-mask.service';

export abstract class BdsCustomerApiServiceOptions {
    apiUrl = `api/customer/`;
    odataUrl = `odata/customer`;
}

@Injectable({
    providedIn: 'root',
})
export class BdsCustomerService {
    controllerName = 'customer';

    constructor(
        private http: HttpClient,
        @Inject('BASE_API_URL') private apibaseUrl: string,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        //private options: BdsCustomerApiServiceOptions,
        public adapter: RtCustomerFactAdapter,
        private apiErrorHandler: ApiErrorHandlerService, //private maskService: BdsEditMaskService
    ) {}

    create(customer: RtCustomerFact): Observable<RtCustomerFact | null> {
        return this.http
            .post<RtCustomerFact>(
                `${this.odatabaseUrl}${this.controllerName}`,
                this.adapter.toServer(customer),
            )
            .pipe(
                map((data: any) => {
                    return !!data ? this.adapter.adapt(data) : null;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    delete(customer: RtCustomerFact): Observable<RtCustomerFact> {
        console.warn('RailtracService.delete() is a dummy method');
        return of(customer).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    getDataSource(): DataSource {
        // const keyField = this.adapter.metadata.find(
        //     (f) => f.client === nameof<RtCustomerFact>('customerNo'),
        // )!.server;

        return new DataSource({
            store: this.getODataStore(),
            map: (item) => {
                // item.customerTelephone = this.maskService.formatPhoneNumber(item.customerTelephone);
                // item.customerFax = this.maskService.formatPhoneNumber(item.customerFax);

                return item;
            },
        });
    }

    getODataStore(): ODataStore {
        const keyField = this.adapter.metadata.find(
            (f) => f.client === nameof<RtCustomerFact>('customerNo'),
        )!.server;

        return new ODataStore({
            version: 4,
            url: `${this.odatabaseUrl}${this.controllerName}`,
            key: keyField,
        });
    }

    getByCustNo(custNo: string): Observable<RtCustomerFact> {
        if (custNo) {
            const filter = `$filter=customerNo eq '${custNo}'`;
            return this.http
                .get<any>(`${this.odatabaseUrl}${this.controllerName}?$top=1&${filter}`)
                .pipe(
                    map((data) => {
                        if (data && data.value && data.value.length) {
                            return this.adapter.adapt(data.value[0]);
                        }
                        return null;
                    }),
                    shareReplay(),
                    catchError((err) => this.apiErrorHandler.handleError(err)),
                );
        } else {
            return of(null);
        }
    }

    patch(customer: RtCustomerFact): Observable<RtCustomerFact> {
        console.warn('RailtracService.patch() is a dummy method');
        return of(customer).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    read(key: string, expand?: string[]): Observable<RtCustomerFact> {
        return this.readViaOdata(key, expand);
    }

    readViaApi(key: string): Observable<RtCustomerFact> {
        return this.http
            .get<RtCustomerFact>(`${this.apibaseUrl}${this.controllerName}/${key}`)
            .pipe(
                map((data: any) => this.adapter.adapt(data)),
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    readViaOdata(key: string, expand?: string[]): Observable<RtCustomerFact> {
        let expandUrl = '';
        if (!!expand && expand.length > 0) {
            expandUrl = '?$expand=';
            expand.forEach((child) => (expandUrl = expandUrl + child + ','));
            expandUrl = expandUrl.slice(0, expandUrl.length - 1);
        }
        return this.http
            .get<RtCustomerFact>(`${this.odatabaseUrl}${this.controllerName}('${key}')` + expandUrl)
            .pipe(
                map((data: any) => this.adapter.adapt(data)),
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    update(key: string, customer: RtCustomerFact): Observable<RtCustomerFact | null> {
        return this.http
            .put<RtCustomerFact>(
                `${this.odatabaseUrl}${this.controllerName}/('${key}')`,
                this.adapter.toServer(customer),
            )
            .pipe(
                map((data: any) => {
                    return !!data ? this.adapter.adapt(data) : null;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }
}
