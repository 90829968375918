import { Component, ViewChild } from '@angular/core';
import { RailtracReportsService } from '../../railtrac-reports.service';
import { PageService } from '@bds/core';
import { ReportConstants } from '../../models/report-parameters.model';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'lib-off-lease-report',
    templateUrl: './off-lease-report.component.html',
    styleUrl: './off-lease-report.component.css',
})
export class OffLeaseReportComponent {
    public gridId = 137;
    public apiCall: string = ReportConstants.ReportDataApiCall;
    public pageHeader: string = 'Off Lease Report';
    public gridSource: any;
    @ViewChild('bdsReportGrid') dataGrid: DxDataGridComponent;

    constructor(public reportService: RailtracReportsService, public pageService: PageService) {}

    ngOnInit() {
        this.pageService.setHeader(this.pageHeader);
        this.gridSource = this.reportService.CreateAspNetDatastore(
            this.apiCall,
            this.gridId.toString(),
        );
    }
}
