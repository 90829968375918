import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'CalculateCLMAge' })
export class CalculateCLMAge implements PipeTransform {
    transform(rowData: any): number {
        var date1 = new Date(rowData.clm_date_time);
        var today = new Date();
        var diff = Math.abs(date1.getTime() - today.getTime());
        var diffDays = Math.ceil(diff / (1000 * 3600 * 24));

        return diffDays;
    }
}
