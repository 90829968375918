<div class="row">
    <div class="col-12">
        <mat-toolbar style="font-size: 0.66rem" class="mat-elevation-z3">
            <button mat-button (click)="reapplyClms()">
                <fa-icon [icon]="iconReapply"></fa-icon>
                Reapply CLMs
            </button>
            <button mat-button (click)="addClm()">
                <fa-icon [icon]="iconAdd"></fa-icon>
                Add CLM
            </button>
            <button mat-button (click)="updateClm()" [disabled]="!selectedClm">
                <fa-icon [icon]="iconEdit"></fa-icon>
                Edit CLM
            </button>
            <button mat-button (click)="deleteClm()" [disabled]="!selectedClm">
                <fa-icon [icon]="iconDelete"></fa-icon>
                Delete CLM
            </button>
            <button mat-button (click)="addToDestinationCriteria()">
                <fa-icon [icon]="iconPopout"></fa-icon>
                Add to Destination Criteria
            </button>
            <button mat-button (click)="addToOriginCriteria()">
                <fa-icon [icon]="iconPopout"></fa-icon>
                Add to Origin Criteria
            </button>
            <button
                mat-button
                (click)="ApplyDateToTrip(TripDateType.ConsigneeNotificationDate)"
                [disabled]="!selectedClm"
            >
                <fa-icon [icon]="iconCalendar"></fa-icon>
                Set Consignee Notification
            </button>
            <button
                mat-button
                (click)="ApplyDateToTrip(TripDateType.ConsigneeDeliveryDate)"
                [disabled]="!selectedClm"
            >
                <fa-icon [icon]="iconCalendar"></fa-icon>
                Set Consignee Delivery
            </button>
            <button
                mat-button
                (click)="ApplyDateToTrip(TripDateType.ConsigneeReleaseDate)"
                [disabled]="!selectedClm"
            >
                <fa-icon [icon]="iconCalendar"></fa-icon>
                Set Consignee Release
            </button>
            <button
                mat-button
                (click)="ApplyDateToTrip(TripDateType.TripCloseDate)"
                [disabled]="!selectedClm"
            >
                <fa-icon [icon]="iconCalendar"></fa-icon>
                Set Trip Close
            </button>
        </mat-toolbar>
    </div>
</div>
<!-- <div class="container-fluid">
    <bds-fastrac-design-display
        [gridId]="gridId"
        [fastracId]="fastracId"
        [showFastrac]="showFastrac"
        (showFastracChange)="onFastracVisibleChanged($event)"
        (fastracIdChange)="onFastracIdChanged($event)"
        (fastracDesignsChange)="onFastracDesignsChanged($event)"
        (applyFastrac)="onApplyFastrac($event)"
    >
    </bds-fastrac-design-display>
</div> -->
<div class="row">
    <div *ngIf="errors && errors.length" class="error-warning">
        <div *ngFor="let error of errors">{{ error.errorMessage }}</div>
    </div>
</div>
<div class="row">
    <!-- <dx-data-grid
        bds-grid
        #grid
        id="bdsClmGrid"
        [dataSource]="gridSource"
        [(selectedRowKeys)]="selectedRowKeys"
        (onSelectionChanged)="onSelectionChanged($event)"
        height="65vh"
        minHeight="200px"
        [gridId]="gridId"
        (bdsShowFastrac)="onShowFastrac()"
        (fastracIdChange)="onGridFastracIdChanged($event)"
        [bdsFastracDesignList]="fastracList"
        [bdsFastracVisible]="showFastrac"
        [bdsUseFastrac]="true"
        [fastracDesign]="currentFastrac"
        [fastracId]="fastracId"
        [bdsCustomizeColumns]="customizeColumns"
        [(bdsGridMode)]="gridMode"
        [bdsAllowAddRecord]="true"        
        [bdsUseZeroPadDates]="true"       
    >
    </dx-data-grid> -->
    <div class="col-12 col-sm-6 main-grid">
        <dx-data-grid
            bds-grid
            [bdsAllowGridModeToggle]="allowModeToggle"
            id="bdsClmGrid"
            [dataSource]="gridSource"
            height="600px"
            [allowColumnReordering]="true"
            [allowColumnResizing]="true"
            [columnAutoWidth]="true"
            [showBorders]="true"
            [(selectedRowKeys)]="selectedRowKeys"
            (onSelectionChanged)="onSelectionChanged($event)"
        >
            <dxo-remote-operations [groupPaging]="true"></dxo-remote-operations>
            <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
            <dxo-filter-row [visible]="true" applyFilter="onClick"></dxo-filter-row>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
            <dxo-column-chooser [enabled]="true" mode="dragAndDrop"></dxo-column-chooser>
            <dxo-paging [pageSize]="20"></dxo-paging>
            <dxo-selection
                [mode]="gridSelectionMode"
                [allowSelectAll]="false"
                showCheckBoxesMode="always"
            ></dxo-selection>
            <dxo-export [enabled]="true" fileName="RAILTRAC CLM Data"></dxo-export>
            <dxo-scrolling
                mode="virtual"
                rowRenderingMode="virtual"
                columnRenderingMode="virtual"
            ></dxo-scrolling>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>

            <dxi-column dataField="carInit" caption="Car Init"></dxi-column>
            <dxi-column dataField="carNo" caption="Car Number"></dxi-column>
            <dxi-column dataField="clmDateTime" caption="CLM Date" dataType="datetime"></dxi-column>
            <dxi-column dataField="sightCode" caption="Sight Code">
                <dxo-lookup
                    [dataSource]="clmSightCodes"
                    valueExpr="id"
                    [displayExpr]="formatClmSightCodeDisplay"
                >
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="locationCity" caption="Location City"></dxi-column>
            <dxi-column dataField="locationState" caption="Location State"></dxi-column>
            <dxi-column dataField="loadEmptyFlag" caption="Load/Empty"></dxi-column>
            <dxi-column dataField="trainJunction" caption="Train Junction"></dxi-column>
            <dxi-column dataField="road" caption="Road"></dxi-column>
            <dxi-column dataField="destinationCity" caption="Destination City"></dxi-column>
            <dxi-column dataField="destinationState" caption="Destination State"></dxi-column>
            <!--<dxi-column
                dataField="clmProcessedDate"
                caption="CLM Processed Date"
                dataType="datetime"
            ></dxi-column>
            <dxi-column dataField="clmEvent" caption="CLM Event"></dxi-column>
            <dxi-column dataField="routeCode" caption="Route Code"></dxi-column>
            <dxi-column
                dataField="actualHoursToDestination"
                caption="Actual Hours To Destination"
            ></dxi-column>
            <dxi-column
                dataField="actualHoursToOrigin"
                caption="Actual Hours To Origin"
            ></dxi-column>
            <dxi-column dataField="locationSplc" caption="Location SPLC"></dxi-column>
            <dxi-column dataField="destinationSplc" caption="Destination SPLC"></dxi-column>
            <dxi-column dataField="source" caption="Source"></dxi-column>
            <dxi-column dataField="previousSplc" caption="Previous SPLC"></dxi-column>
            <dxi-column dataField="milesFromPrevious" caption="Miles From Previous"></dxi-column>
            <dxi-column dataField="dwellTime" caption="Dwell Time"></dxi-column>
            <dxi-column
                dataField="clmDifferentialTime"
                caption="CLM Differential Time"
            ></dxi-column> -->
            <dxi-column dataField="clmType" caption="CLM Type">
                <dxo-lookup
                    [dataSource]="clmTypes"
                    valueExpr="clmType"
                    displayExpr="clmTypeDisplay"
                >
                </dxo-lookup>
            </dxi-column>
        </dx-data-grid>
    </div>

    <div class="col-12 col-sm-6">
        <rt-trip-criteria
            [customerNumber]="customerNumber"
            [destinationCity]="destinationCity"
            [destinationState]="destinationState"
            [originCity]="originCity"
            [originState]="originState"
            [tripId]="tripId"
        >
        </rt-trip-criteria>
    </div>
</div>

<ng-template #clmDetails let-data>
    <h2 matDialogTitle>{{ data.title }}</h2>
    <mat-dialog-content>
        <rt-clm-details
            #clmForm
            #details
            [clm]="data.clm"
            [disabledFields]="data.disabledFields"
            [highlightDirty]="true"
        >
        </rt-clm-details>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button class="text-uppercase" color="accent" cdkFocusInitial mat-dialog-close>
            Cancel
        </button>
        <button
            mat-button
            class="text-uppercase"
            color="accent"
            (click)="SaveClmClick()"
            [disabled]="!clmForm.clmForm.valid"
        >
            Save
        </button>
    </mat-dialog-actions>
</ng-template>
