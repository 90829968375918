import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { bdsGridModeType } from '@bds/components';
import { RailtracReportsService } from '../../railtrac-reports.service';
import { PageService } from '@bds/core';
import { ReportConstants } from '../../models/report-parameters.model';

@Component({
    selector: 'lib-eta-performance-route',
    templateUrl: './delivery-perf-consignee.component.html',
    styleUrl: './delivery-perf-consignee.component.css',
})
export class DeliveryPerfConsigneeComponent implements OnInit {
    public gridId = 146;
    public gridMode: bdsGridModeType = 'single';
    public apiCall: string = ReportConstants.ReportDataApiCall;
    public pageHeader: string = 'Specialty Request Delivery Performance by Consignee Report';
    public gridSource: any;
    public selectedRowKeys: Array<number> = [];
    public displayReport: boolean = false;
    public controlNames: string[] = ['Trip Close Begin Date', 'Trip Close End Date'];

    constructor(public reportService: RailtracReportsService, public pageService: PageService) {}

    ngOnInit() {
        this.pageService.setHeader(this.pageHeader);
    }

    onGetReportClick(event: any) {
        this.displayReport = true;
        this.gridSource = this.reportService.CreateAspNetDatastore(
            ReportConstants.ReportDataApiCall,
            this.gridId.toString(),
            event.startDate,
            event.endDate,
        );
    }

    onCancelClick() {
        this.displayReport = false;
    }
}
