import { Component, OnInit } from '@angular/core';
import { bdsGridModeType } from '@bds/components';
import { RailtracReportsService } from '../../railtrac-reports.service';
import { PageService } from '@bds/core';
import { ReportConstants } from '../../models/report-parameters.model';

@Component({
    selector: 'lib-car-with-no-outage',
    templateUrl: './car-with-no-outage.component.html',
    styleUrl: './car-with-no-outage.component.css',
})
export class CarWithNoOutageComponent implements OnInit {
    public gridId = 136;
    public gridMode: bdsGridModeType = 'single';
    public apiCall: string = ReportConstants.ReportDataApiCall;
    public pageHeader: string = 'Car With No Outage Table Report';
    public gridSource: any;

    constructor(public reportService: RailtracReportsService, public pageService: PageService) {}

    ngOnInit() {
        this.pageService.setHeader(this.pageHeader);
        this.gridSource = this.reportService.CreateAspNetDatastore(
            this.apiCall,
            this.gridId.toString(),
        );
    }
}
