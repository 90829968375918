import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'CalculateTotalDays' })
export class CalculateTotalDays implements PipeTransform {
    transform(rowData: any): number {
        var totalDays = rowData.idle_days + rowData.days_out + rowData.hold_days + rowData.days_in;

        return totalDays;
    }
}
