import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BdsSplcErpcService } from '@bds/data-access';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DataSource from 'devextreme/data/data_source';

@Component({
    selector: 'lib-bds-splc-erpc-select-dialog',
    templateUrl: './bds-splc-erpc-select-dialog.component.html',
    styleUrl: './bds-splc-erpc-select-dialog.component.css',
})
export class BdsSplcErpcSelectDialogComponent implements OnInit {
    @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
    dataSource: DataSource;
    selectedRowKeys: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<BdsSplcErpcSelectDialogComponent, any>,
        private splcErpcService: BdsSplcErpcService,
    ) {}

    ngOnInit(): void {
        this.dataSource = new DataSource({
            store: this.splcErpcService.getODataStore(),
            paginate: true,
            pageSize: 15,
            select: ['erpcCity', 'erpcState', 'splc', 'city', 'state'],
            key: 'splc',
        });
        this.dataSource.filter(this.data.filter);
    }

    onSelectClick(): void {
        const data = this.dataGrid.instance.getSelectedRowsData();
        if (data.length > 0) {
            this.dataGrid.instance.clearSelection();
            this.dialogRef.close(data);
        }
    }
}
