<rt-report-parameters
    [controlNames]="controlNames"
    (getReport)="onGetReportClick($event)"
    (cancelReport)="onCancelClick()"
>
</rt-report-parameters>
<div *ngIf="displayReport">
    <dx-data-grid
        id="bdsReportGrid"
        [dataSource]="gridSource"
        height="80vh"
        minHeight="200px"
        [allowColumnResizing]="false"
    >
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
        <dxi-column caption="Car ID">
            <dxi-column
                dataField="car_init"
                caption="Car Init."
                sortOrder="asc"
                [sortIndex]="0"
            ></dxi-column>
            <dxi-column
                dataField="car_no"
                caption="Car No."
                sortOrder="asc"
                [sortIndex]="1"
            ></dxi-column>
        </dxi-column>
        <!-- <dxi-column cellTemplate="carIDTemplate" caption="Car ID"></dxi-column>
        <div *dxTemplate="let item of 'carIDTemplate'">
            {{ item.data.car_init }}
        </div> -->
        <dxi-column
            dataField="ship_date_time"
            dataType="date"
            [format]="{ type: 'shortDate' }"
            caption="Ship Date"
        ></dxi-column>
        <dxi-column caption="Bad Order">
            <dxi-column
                dataField="bad_order_start"
                dataType="date"
                [format]="{ type: 'shortDate' }"
                caption="Start Date"
            ></dxi-column>
            <dxi-column
                dataField="bad_order_end"
                dataType="date"
                [format]="{ type: 'shortDate' }"
                caption="End Date"
            ></dxi-column>
        </dxi-column>
        <dxi-column dataField="bad_order_delay" caption="Delay Days"></dxi-column>
        <dxi-column dataField="fleet_id" caption="Fleet"></dxi-column>
        <dxi-column dataField="business_group" caption="Business Group"></dxi-column>
        <dxi-column dataField="le" caption="L/E"></dxi-column>
        <dxi-column dataField="road" caption="Road"></dxi-column>
        <dxi-column caption="Location">
            <dxi-column dataField="loc_city" caption="City"></dxi-column>
            <dxi-column dataField="loc_state" caption="State"></dxi-column>
        </dxi-column>
        <!-- <dxi-column
            cellTemplate="locationTemplate"
            caption="Location"
            width="auto"
            [minWidth]="100"
            allowSearch="true"
        ></dxi-column>

        <div *dxTemplate="let item of 'locationTemplate'">
            <p class="">{{ item.data.loc_city }} {{ item.data.loc_state }}</p>
        </div> -->

        <dxi-column dataField="trn_jct" caption="Trn/Jct"></dxi-column>
        <dxi-column dataField="bad_order_reason" caption="Reason"></dxi-column>
        <dxi-column dataField="comments" caption="Comments"></dxi-column>
        <dxo-summary>
            <dxi-total-item column="comments" summaryType="count"> </dxi-total-item>
        </dxo-summary>
    </dx-data-grid>
    <!-- <dx-data-grid
        bds-grid
        id="bdsReportGrid"
        [dataSource]="gridSource"
        height="80vh"
        minHeight="200px"
        dataRowTemplate="dataRowTemplateName"
    >
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
        <dxi-column caption="Car ID" sortOrder="asc" [sortIndex]="0"></dxi-column>
        <dxi-column
            dataType="date"
            [format]="{ type: 'shortDate' }"
            caption="Ship Date"
        ></dxi-column>
        <dxi-column caption="Bad Order">
            <dxi-column
                dataType="date"
                [format]="{ type: 'shortDate' }"
                caption="Start Date"
            ></dxi-column>
            <dxi-column
                dataType="date"
                [format]="{ type: 'shortDate' }"
                caption="End Date"
            ></dxi-column>
        </dxi-column>
        <dxi-column caption="Delay Days"> </dxi-column>
        <dxi-column caption="Fleet"> </dxi-column>
        <dxi-column caption="Business Group"> </dxi-column>
        <dxi-column caption="L/E"> </dxi-column>
        <dxi-column caption="Road"> </dxi-column>
        <dxi-column caption="Location"> </dxi-column>
        <dxi-column caption="Trn/Jct"> </dxi-column>
        <dxi-column caption="Reason"> </dxi-column>
        <dxi-column caption="Comments"> </dxi-column>
        <dxo-summary>
            <dxi-total-item column="comments" summaryType="count"> </dxi-total-item>
        </dxo-summary>

        <ng-container *dxTemplate="let item of 'dataRowTemplateName'">
            <tr class="main-row">
                <td>{{ item.data.car_init }} {{ item.data.car_no }}</td>
                <td>{{ item.data.ship_date_time }}</td>
                <td>{{ item.data.bad_order_start }}</td>
                <td>{{ item.data.bad_order_end }}</td>
                <td>{{ item.data.bad_order_delay }}</td>
                <td>{{ item.data.fleet_id }}</td>
                <td>{{ item.data.business_group }}</td>
                <td>{{ item.data.le }}</td>
                <td>{{ item.data.road }}</td>
                <td>{{ item.data.loc_city }} {{ item.data.loc_state }}</td>
                <td>{{ item.data.trn_jct }}</td>
                <td>{{ item.data.bad_order_reason }}</td>
                <td>{{ item.data.comments }}</td>
            </tr>
        </ng-container>
    </dx-data-grid> -->
</div>
