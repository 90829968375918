<dx-data-grid
    id="bdsReportGrid"
    bds-grid
    #bdsReportGrid
    [dataSource]="gridSource"
    height="80vh"
    minHeight="200px"
    dataRowTemplate="dataRowTemplateName"
    [allowColumnResizing]="false"
>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
    <dxi-column caption="Fleet ID"></dxi-column>
    <dxi-column caption="Comm Class"></dxi-column>
    <dxi-column caption="AT CONSIGNEE"></dxi-column>
    <dxi-column caption="AT ORIGIN EMPTY"></dxi-column>
    <dxi-column caption="AT ORIGIN LOADED"></dxi-column>
    <dxi-column caption="AT SHOP"></dxi-column>
    <dxi-column caption="NO BILLS"></dxi-column>
    <dxi-column caption="TO CONSIGNEE"></dxi-column>
    <dxi-column caption="TO ORIGIN"></dxi-column>
    <dxi-column caption="TO SHOP"></dxi-column>
    <dxi-column caption="Grand total"></dxi-column>
    <ng-container *dxTemplate="let item of 'dataRowTemplateName'">
        <tr>
            <td>{{ item.data.fleet_id }}</td>
            <td>{{ item.data.comm_class }}</td>
            <td>{{ item.data.at_consignee }}</td>
            <td>{{ item.data.at_origin_empty }}</td>
            <td>{{ item.data.at_origin_loaded }}</td>
            <td>{{ item.data.at_shop }}</td>
            <td>{{ item.data.no_bills }}</td>
            <td>{{ item.data.to_consignee }}</td>
            <td>{{ item.data.to_origin }}</td>
            <td>{{ item.data.to_shop }}</td>
            <td>{{ item.data.GRAND_TOTAL }}</td>
        </tr>
    </ng-container>
</dx-data-grid>
