<h1 mat-dialog-title>{{ data?.title }}</h1>
<mat-dialog-content>
    <rt-clm-grid
        [carInit]="trip.carInit"
        [carNumber]="trip.carNo"
        [tripId]="trip.ormId"
        [shipmentDate]="trip.shipDatetime"
        (clmSelect)="onCLMSelect($event)"
    ></rt-clm-grid>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
        mat-button
        class="text-uppercase"
        color="accent"
        mat-dialog-close
        (click)="onCancelClick()"
    >
        Cancel
    </button>

    <button
        mat-button
        [disabled]="!selectedCLM"
        class="text-uppercase"
        color="accent"
        (click)="outputSelectedCLM()"
    >
        {{ data?.actionText }}
    </button>
</mat-dialog-actions>
