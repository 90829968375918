import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule, Routes } from '@angular/router';
import { BdsDxDataGridModule } from '@bds/components';
import { BourqueCoreModule } from '@bds/core';
import { BdsFastracModule } from '@bds/fastrac';
import { BdsHelpersModule } from '@bds/helpers';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { BdsRouteComponent } from '../components/bds-route.component';
import { BdsRouteModule } from '../components/bds-route.module';
import { RouteCreateScreenComponent } from './route-create-screen/route-create-screen.component';
import { RouteDetailScreenComponent } from './route-detail-screen/route-detail-screen.component';
import { RouteScreenComponent } from './route-screen/route-screen.component';
import { BdsGridDetailsModule } from '@bds/components';
import { AuthorizeGuard } from '@bds/auth';
import { fastracGuard } from '@bds/railtrac-internal-services';
import { FastracGrid } from '@bds/models';

const routes: Routes = [
    {
        path: 'routes',
        canActivate: [AuthorizeGuard, fastracGuard],
        data: { gridId: FastracGrid.routes },
        component: RouteScreenComponent,
        children: [
            { path: 'detail/:routeCode', component: RouteDetailScreenComponent },
            { path: 'create', component: RouteCreateScreenComponent },
        ],
    },
];

@NgModule({
    declarations: [
        RouteScreenComponent,
        RouteDetailScreenComponent,
        BdsRouteComponent,
        RouteCreateScreenComponent,
    ],
    imports: [
        BdsRouteModule,
        RouterModule.forChild(routes),
        BourqueCoreModule,
        BdsDxDataGridModule,
        BdsFastracModule,
        BdsGridDetailsModule,
        CommonModule,
        DxDataGridModule,
        FontAwesomeModule,
        MatButtonModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule,
        ReactiveFormsModule,
        MatExpansionModule,
        MatTabsModule,
        MatListModule,
        MatSidenavModule,
        MatChipsModule,
        BdsHelpersModule,
    ],
    exports: [BdsRouteComponent],
})
export class RtRouteScreenModule {}
