import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FastracScreenBaseComponent } from '@bds/fastrac';
import { FastracColsService } from '@bds/services';
import { CommunicationService } from '@bds/core';
import { CarIdTransformService } from '@bds/helpers';
import { bdsGridModeType } from '@bds/components';
import { RailtracReportsService } from '../../railtrac-reports.service';
import { PageService } from '@bds/core';
import DataSource from 'devextreme/data/data_source';

@Component({
    selector: 'rt-smart-report',
    templateUrl: './rt-smart-report.component.html',
    styleUrls: ['./rt-smart-report.component.css'],
})
export class RtSmartReportComponent
    extends FastracScreenBaseComponent
    implements OnInit, OnDestroy
{
    @Input() gridId: number;
    @Input() apiCall: string;
    @Input() pageHeader: string = 'Report';

    public gridMode: bdsGridModeType = 'single';
    public gridSource: any;
    public selectedRowKeys: Array<number> = [];

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public gridColumnService: FastracColsService,
        public communicationService: CommunicationService,
        public carIdTransformService: CarIdTransformService,
        public reportService: RailtracReportsService,
        public pageService: PageService,
    ) {
        super(router, route, gridColumnService, communicationService, carIdTransformService);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    ngOnInit() {
        super.ngOnInit();
        this.pageService.setHeader(this.pageHeader);
        this.gridSource = new DataSource(
            this.reportService.CreateAspNetDatastore(this.apiCall, this.gridId.toString()),
        );
    }
}
