import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UpdateTripService {
    constructor() {}
    private updateTripSource = new Subject<any>();

    updateTripChangeEmitted$ = this.updateTripSource.asObservable();

    emitUpdateTripChange(data: any): void {
        this.updateTripSource.next(data);
    }
}
