<rt-report-parameters
    [controlNames]="controlNames"
    (getReport)="onGetReportClick($event)"
    (cancelReport)="onCancelClick()"
>
</rt-report-parameters>
<div *ngIf="displayReport">
    <dx-data-grid
        id="bdsReportGrid"
        [dataSource]="gridSource"
        height="80vh"
        minHeight="200px"
        [allowColumnResizing]="false"
    >
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
        <dxi-column caption="Car ID">
            <dxi-column
                dataField="car_init"
                caption="Car Init."
                sortOrder="asc"
                [sortIndex]="0"
            ></dxi-column>
            <dxi-column
                dataField="car_no"
                caption="Car No."
                sortOrder="asc"
                [sortIndex]="1"
            ></dxi-column>
        </dxi-column>
        <dxi-column
            dataField="ship_date_time"
            dataType="date"
            [format]="{ type: 'shortDate' }"
            caption="Ship Date"
        ></dxi-column>
        <dxi-column
            dataField="bad_order_start"
            dataType="date"
            [format]="{ type: 'shortDate' }"
            caption="Trouble Start"
        ></dxi-column>
        <dxi-column
            dataField="bad_order_end"
            dataType="date"
            [format]="{ type: 'shortDate' }"
            caption="Trouble End"
        ></dxi-column>
        <dxi-column dataField="bad_order_delay" caption="Delay (Hrs)"></dxi-column>
        <dxi-column dataField="" caption="Trouble Type"></dxi-column>
        <dxi-column dataField="" caption="L/E"></dxi-column>
        <dxi-column dataField="road" caption="Road"></dxi-column>
        <dxi-column caption="Location">
            <dxi-column dataField="loc_city" caption="City"></dxi-column>
            <dxi-column dataField="loc_state" caption="State"></dxi-column>
        </dxi-column>
        <dxi-column dataField="trn_jct" caption="Trn/Jct"></dxi-column>
        <dxi-column dataField="bad_order_reason" caption="Reason"></dxi-column>
        <dxi-column dataField="comments" caption="Comments"></dxi-column>
    </dx-data-grid>
</div>
