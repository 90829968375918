<rt-report-parameters
    [controlNames]="controlNames"
    (getReport)="onGetReportClick($event)"
    (cancelReport)="onCancelClick()"
>
</rt-report-parameters>
<div *ngIf="displayReport">
    <dx-data-grid
        id="bdsReportGrid"
        [dataSource]="gridSource"
        height="80vh"
        minHeight="200px"
        [allowColumnResizing]="false"
        dataRowTemplate="dataRowTemplateName"
    >
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
        <dxi-column dataField="fleet_id" caption="Fleet ID"></dxi-column>
        <dxi-column dataField="business_group" caption="Business Group"></dxi-column>
        <dxi-column caption="Origin">
            <dxi-column dataField="return_city" caption="City"></dxi-column>
            <dxi-column dataField="return_state" caption="State"></dxi-column>
        </dxi-column>
        <dxi-column caption="*  At Origin  *">
            <dxi-column dataField="tot_at_origin_empty" caption="Empty"></dxi-column>
            <dxi-column dataField="tot_at_origin_loaded" caption="Loaded"></dxi-column>
        </dxi-column>
        <dxi-column dataField="total_at_origin" caption="Total"></dxi-column>

        <dxo-summary>
            <dxi-total-item
                column="return_state"
                displayFormat="Totals:"
                alignment="right"
            ></dxi-total-item>
            <dxi-total-item
                column="return_state"
                displayFormat="Avg:"
                alignment="right"
            ></dxi-total-item>
            <dxi-total-item
                column="return_state"
                displayFormat="Min:"
                alignment="right"
            ></dxi-total-item>
            <dxi-total-item
                column="return_state"
                displayFormat="Max:"
                alignment="right"
            ></dxi-total-item>
            <dxi-total-item
                column="tot_at_origin_empty"
                summaryType="sum"
                displayFormat="{0}"
                showInColumn="tot_at_origin_empty"
            ></dxi-total-item>
            <dxi-total-item
                column="tot_at_origin_empty"
                summaryType="avg"
                displayFormat="{0}"
                showInColumn="tot_at_origin_empty"
                valueFormat="###0.##"
            ></dxi-total-item>
            <dxi-total-item
                column="tot_at_origin_empty"
                summaryType="min"
                displayFormat="{0}"
                showInColumn="tot_at_origin_empty"
            ></dxi-total-item>
            <dxi-total-item
                column="tot_at_origin_empty"
                summaryType="max"
                displayFormat="{0}"
                showInColumn="tot_at_origin_empty"
            ></dxi-total-item>
            <dxi-total-item
                column="tot_at_origin_loaded"
                summaryType="sum"
                displayFormat="{0}"
                showInColumn="tot_at_origin_loaded"
            ></dxi-total-item>
            <dxi-total-item
                column="tot_at_origin_loaded"
                summaryType="avg"
                displayFormat="{0}"
                showInColumn="tot_at_origin_loaded"
                valueFormat="###0.##"
            ></dxi-total-item>
            <dxi-total-item
                column="tot_at_origin_loaded"
                summaryType="min"
                displayFormat="{0}"
                showInColumn="tot_at_origin_loaded"
            ></dxi-total-item>
            <dxi-total-item
                column="tot_at_origin_loaded"
                summaryType="max"
                displayFormat="{0}"
                showInColumn="tot_at_origin_loaded"
            ></dxi-total-item>
            <dxi-total-item
                column="total_at_origin"
                summaryType="sum"
                displayFormat="{0}"
                showInColumn="total_at_origin"
            ></dxi-total-item>
            <dxi-total-item
                column="total_at_origin"
                summaryType="avg"
                displayFormat="{0}"
                showInColumn="total_at_origin"
                valueFormat="###0.##"
            ></dxi-total-item>
            <dxi-total-item
                column="total_at_origin"
                summaryType="min"
                displayFormat="{0}"
                showInColumn="total_at_origin"
            ></dxi-total-item>
            <dxi-total-item
                column="total_at_origin"
                summaryType="max"
                displayFormat="{0}"
                showInColumn="total_at_origin"
            ></dxi-total-item>
        </dxo-summary>
    </dx-data-grid>
</div>
