import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
    BdsDialogConfirmComponent,
    BdsDialogInfoComponent,
    BdsDialogInfoModel,
    bdsGridModeType,
} from '@bds/components';
import { CommunicationService } from '@bds/core';
import {
    BdsDestinationCriteriaDialogComponent,
    BdsDestinationCriteriaDialogModel,
} from '@bds/destination-criteria';
import { FastracScreenBaseComponent } from '@bds/fastrac';
import { AsciiConverterService, CarIdTransformService } from '@bds/helpers';
import { CarIdTransformService as CarIdTransformLocalService } from '@bds/railtrac-internal-services';
import { ClmType, RtClm, RtClmSightCode, RtClmView } from '@bds/railtrac-models';
import { faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons';
import {
    faClipboardCheck,
    faEdit,
    faPlus,
    faRss,
    faTrashAlt,
    faCalendar,
} from '@fortawesome/pro-solid-svg-icons';
import { default as DataSource, default as DxDataSource } from 'devextreme/data/data_source';
import { EMPTY, NEVER } from 'rxjs';
import { catchError, finalize, switchMap, take, tap } from 'rxjs/operators';
import { BdsOriginCriteriaDialogComponent } from '../bds-origin-criteria/bds-origin-criteria-dialog/bds-origin-criteria-dialog.component';
import { BdsOriginCriteriaDialogModel } from '../bds-origin-criteria/models/bds-origin-criteria-dialog.model';
import { RailtracClmService } from '../railtrac-clm/railtrac-clm.service';
import { FastracColsService } from '@bds/services';
import { ErrorListItem } from '@bds/rt-advance-models';
import {
    CreateTripService,
    UpdateTripService,
    RailtracClmDetailsComponent,
    ClmFunctionsService,
} from '@bds/railtrac';
import { EdmLiteral } from 'devextreme/data/odata/utils';
import { ListRefreshService } from '@bds/destination-criteria';
import { OriginListRefreshService } from '../bds-origin-criteria/services/origin-list-refresh.service';
import { DatePipe } from '@angular/common';
import { TripDateType } from '../enum/tripDateType.enum';

@Component({
    selector: 'rt-clm',
    templateUrl: './rt-clm.component.html',
    styleUrls: ['./rt-clm.component.scss'],
})
export class RtClmComponent
    extends FastracScreenBaseComponent
    implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
    TripDateType = TripDateType;
    allowModeToggle = false;
    carInit = '';
    carNo = '';
    clmSightCodes: Array<RtClmSightCode> = [];
    clmTypes: Array<{ clmType: string; clmTypeDisplay: string }>;
    gridSource: DxDataSource;
    gridColumns: any;
    gridSelectionMode: bdsGridModeType = 'single';
    iconAdd = faPlus;
    iconClm = faRss;
    iconDelete = faTrashAlt;
    iconEdit = faEdit;
    iconPopout = faExternalLinkAlt;
    iconReapply = faClipboardCheck;
    iconCalendar = faCalendar;
    reapplyClmsLoading = false;
    selectedClm: RtClm;
    selectedRowKeys: Array<number> = [];
    fastracId = 0;
    showFastrac = false;
    public gridMode: bdsGridModeType = 'single';
    isSaving = false;
    errors: ErrorListItem[] = [];

    @Input() gridId = 98;
    @Input() carId = '';
    @Input() customerNumber = '';
    @Input() destinationCity = '';
    @Input() destinationState = '';
    @Input() disabledFields: Array<string> | string = '';
    @Input() originCity = '';
    @Input() originState = '';
    @Input() tripId: number;
    @Input() shipmentDate: Date = null;
    @Input() loadEmpty = '';

    @Output() onClmReapplied = new EventEmitter();
    @Output() onApplyDateToTrip = new EventEmitter<any>();

    // @ts-ignore
    @ViewChild('clmDetails') clmDetails: TemplateRef;
    @ViewChild('details') details: RailtracClmDetailsComponent;
    private dialogRef: MatDialogRef<any, any> = null;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public gridColumnService: FastracColsService,
        public communicationService: CommunicationService,
        public carIdTransformService: CarIdTransformService,
        public carIdTransformLocalService: CarIdTransformLocalService,
        public clmService: RailtracClmService,
        private asciiService: AsciiConverterService,
        public dialog: MatDialog,
        private _snackbar: MatSnackBar,
        private listRefreshService: ListRefreshService,
        private originListService: OriginListRefreshService,
        private datePipe: DatePipe,
        public createTripService: CreateTripService,
        public updateTripService: UpdateTripService,
        private clmFunctionService: ClmFunctionsService,
    ) {
        super(router, route, gridColumnService, communicationService, carIdTransformService);
        this.clmTypes = [
            { clmType: 'Archive', clmTypeDisplay: 'Archived' },
            { clmType: 'Current', clmTypeDisplay: 'Current' },
            { clmType: 'Historical', clmTypeDisplay: 'History' },
        ];

        clmService.getClmSightCodes().subscribe((sightCodes: Array<RtClmSightCode>) => {
            sightCodes.sort((a, b) => (a.id < b.id ? -1 : 1));

            for (const sc of sightCodes) {
                if (sc.id === 'Q') {
                    sc.description = 'Start of a Shipment.';
                    break;
                }
            }

            this.clmSightCodes = sightCodes;
        });
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.carId) {
            this.parseCarId();
        }

        //to handle 'Reapply CLMs' menu item selection in Shipemnt details section
        this.clmFunctionService.reapplyClmChangeEmitted$.subscribe((data) => {
            this.reapplyClms();
        });

        this.reloadGrid();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.errors = [];
        const tripIdChange = changes['tripId'];

        if (changes.carId && !changes.carId.firstChange) {
            this.parseCarId();
            this.reloadGrid();
        } else if (!!tripIdChange) {
            this.reloadGrid();
        }
    }

    ngAfterViewInit() {
        this.clmFunctionService.emitRtClmInitChange(true);
    }

    onGridModeChanged(e): void {
        console.log('onGridModeChanged', e);
    }

    onSelectionChanged(e): void {
        this.errors = [];
        this.selectedClm = e.selectedRowsData[0];
    }

    addToDestinationCriteria(): void {
        if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
            this.clmService
                .getClm(this.selectedRowKeys[0])
                .pipe(take(1))
                .subscribe((clm) => {
                    const destinationInfo: BdsDestinationCriteriaDialogModel = {
                        customerNo: this.customerNumber,
                        locationCity: clm.locationCity,
                        locationState: clm.locationState,
                        railroad: clm.road,
                        sightCode: clm.sightCode,
                        isClone: false,
                        isNew: true,
                    };

                    const dialogRef = this.dialog.open(BdsDestinationCriteriaDialogComponent, {
                        width: '550px',
                        data: destinationInfo,
                    });

                    dialogRef.afterClosed().subscribe((result) => {
                        if (result.event == 'Save') {
                            this.listRefreshService.refreshList();
                        }
                    });
                });
        } else {
            const info: BdsDialogInfoModel = {
                title: 'Add to Destination Criteria',
                content: 'Please select a CLM to add.',
            };

            this.dialog.open(BdsDialogInfoComponent, {
                data: info,
            });
        }
    }

    addToOriginCriteria(): void {
        if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
            this.clmService
                .getClm(this.selectedRowKeys[0])
                .pipe(take(1))
                .subscribe((clm) => {
                    const originInfo: BdsOriginCriteriaDialogModel = {
                        // The originCode comes from city/state instead of being defined here
                        returnCity: this.originCity,
                        returnState: this.originState,
                        locationCity: clm.locationCity,
                        locationState: clm.locationState,
                        railroad: clm.road,
                        sightCode: clm.sightCode,
                        isClone: false,
                        isNew: true,
                    };

                    const dialogRef = this.dialog.open(BdsOriginCriteriaDialogComponent, {
                        width: '550px',
                        data: originInfo,
                    });

                    dialogRef.afterClosed().subscribe((result) => {
                        if (result.event == 'Save') {
                            this.originListService.refreshList();
                        }
                    });
                });
        } else {
            const info: BdsDialogInfoModel = {
                title: 'Add to Origin Criteria',
                content: 'Please select a CLM to add.',
            };

            this.dialog.open(BdsDialogInfoComponent, {
                data: info,
            });
        }
    }

    getODataClmApiSource(): DataSource {
        const ds = this.clmService.getODataStore();
        if (this.carId) {
            const carIdParts = this.carId.split(' ');

            if (this.shipmentDate) {
                const dateLiteral = this.datePipe.transform(
                    this.shipmentDate,
                    "yyyy-MM-dd'T'HH:mm:ss.SSZZZZZ",
                    'UTC',
                );

                ds.filter([
                    ['carInit', '=', carIdParts[0]],
                    'and',
                    ['carNo', '=', carIdParts[1]],
                    'and',
                    ['clmDateTime', '>=', new EdmLiteral(dateLiteral)],
                ]);
            } else {
                ds.filter([['carInit', '=', carIdParts[0]], 'and', ['carNo', '=', carIdParts[1]]]);
            }
            ds.sort({ selector: 'clmDateTime', desc: true });
        }
        return ds;
    }

    gridSelectionChanged = (e) => {
        console.log(e);
        console.log(this.selectedRowKeys);
    };

    private parseCarId() {
        const parsedId = this.carIdTransformLocalService.parseCarId(this.carId);
        this.carInit = parsedId.carInit;
        this.carNo = parsedId.carNo;
    }

    reapplyClms() {
        this.reapplyClmsLoading = true;
        this.clmService
            .reapplyClms(this.carInit, this.carNo)
            .pipe(
                tap(() => this.reloadGrid()),
                finalize(() => {
                    this.reapplyClmsLoading = false;
                    this.createTripService.emitRefreshTripGridChange(true);
                }),
            )
            .subscribe(
                () => {
                    this.onClmReapplied.emit();
                    this._snackbar.open('CLMs Reapplied!', 'Ok', {
                        duration: 3000,
                    });
                },
                (error) => {
                    this._snackbar.open(error.error, 'Error', {
                        duration: 3000,
                    });
                },
            );
    }

    updateClm() {
        this.errors = [];
        this.clmService.getClm(this.selectedClm.ormId).subscribe((clm) => {
            let dialogData = {
                title: `Edit ${this.selectedClm.carInit} ${this.selectedClm.carNo}`,
                clm: clm,
                disabledFields: this.disabledFields,
            };

            this.dialogRef = this.dialog.open(this.clmDetails, {
                data: dialogData,
            });

            this.dialogRef
                .afterClosed()
                .pipe(
                    switchMap((val: RtClmView) => {
                        if (val) {
                            return this.clmService.updateClm(val);
                        } else {
                            return NEVER;
                        }
                    }),
                    tap(() => {
                        this.reloadGrid();
                        this.afterSaveComplete();
                    }),
                    catchError((err) => {
                        this.afterError(err);
                        return EMPTY;
                    }),
                    finalize(() => {
                        this.isSaving = false;
                    }),
                )
                .subscribe(() => {
                    this.onClmReapplied.emit();
                    this._snackbar.open('Updated CLM', 'Ok', {
                        duration: 3000,
                    });
                });
        });
    }

    addClm() {
        this.errors = [];
        const newClm = new RtClm();
        newClm.carInit = this.carInit;
        newClm.carNo = this.carNo;
        newClm.loadEmptyFlag = this.loadEmpty;

        this.dialogRef = this.dialog.open(this.clmDetails, {
            data: {
                title: 'Add CLM',
                clm: newClm,
            },
        });

        this.dialogRef
            .afterClosed()
            .pipe(
                switchMap((val: RtClmView) => {
                    if (val) {
                        val.ormId = 0;
                        val.dwellTime = 0;
                        val.clmType = ClmType.Current;
                        return this.clmService.addClm(val);
                    } else {
                        return NEVER;
                    }
                }),
                tap(() => {
                    this.reloadGrid();
                    this.afterSaveComplete();
                }),
                catchError((err) => {
                    this.afterError(err);
                    return EMPTY;
                }),
                finalize(() => {
                    this.isSaving = false;
                }),
            )
            .subscribe(() => {
                this.onClmReapplied.emit();
                this._snackbar.open('Added CLM', 'Ok', {
                    duration: 3000,
                });
            });
    }

    deleteClm() {
        const dialog = this.dialog.open(BdsDialogConfirmComponent, {
            data: {
                actionText: 'Delete',
                content: `Do you want to delete the highlighted CLM(s)?`,
            },
        });

        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this.clmService.deleteClm(this.selectedClm).subscribe({
                    next: (v) => {
                        this.reapplyClms();
                        this._snackbar.open('Deleted CLM', 'Ok', {
                            duration: 3000,
                        });
                    },
                    error: (error) => {
                        let errorMessage = 'Error during CLM delete.';
                        if (error && error.error) {
                            var errorObject = error.error;
                            if (
                                errorObject.validationErrors &&
                                errorObject.validationErrors.length > 0
                            ) {
                                errorMessage = errorObject.validationErrors[0].validationMessage;
                            } else if (errorObject.errors && errorObject.errors.length > 0) {
                                errorMessage = errorObject.errors[0];
                            }
                        }
                        this._snackbar.open(errorMessage, 'Error', {
                            duration: 3000,
                        });
                    },
                    complete: () => {
                        this.reloadGrid();
                    },
                });
            }
        });
    }

    reloadGrid() {
        this.errors = [];
        this.selectedClm = null;
        this.gridSource = this.getODataClmApiSource();
    }

    formatClmSightCodeDisplay(val: RtClmSightCode) {
        return `${val.id} - ${val.name}`;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private afterError(error: ErrorListItem[]) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const customerErrors = [];

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.errors = error;

        this._snackbar.open('Error saving CLM!', 'Error', {
            duration: 3000,
            panelClass: 'error-snack',
        });
        this.isSaving = false;
    }

    private afterSaveComplete(message?: string) {
        if (!message) {
            message = 'Changes saved!';
        }

        this.isSaving = false;
        this._snackbar.open(message, 'Ok', { duration: 3000 });
    }

    SaveClmClick() {
        this.dialogRef.close(this.details.ClmForm.getRawValue());
    }

    /**
     * This method is called when the user clicks the buttons to set CLM date to one of these fields in
     * trip and set the date type to actual
     * @param dateType '1' - Consignee Notification Date
     * '2' - Consignee Delivery Date
     * '3' - Consignee Release Date
     * '4' - Trip Close Date
     */
    ApplyDateToTrip(dateType: string): void {
        const clmDate = this.datePipe.transform(
            this.selectedClm.clmDateTime,
            "yyyy-MM-dd'T'HH:mm:ss.SS",
        );

        const data = { dateValue: clmDate, dateType: dateType };
        this.updateTripService.emitUpdateTripChange(data);
    }
}
