import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { RtDivertedTripsService } from '@bds/data-access';
import { RtvTripDiversion } from '@bds/railtrac-models';
import * as moment from 'moment';

@Component({
    selector: 'rt-diversion-list',
    templateUrl: './rt-diversion-list.component.html',
    styleUrls: ['./rt-diversion-list.component.scss'],
})
export class RtDiversionListComponent implements OnChanges {
    dateTimeFormat = 'MM/dd/yyyy HH:mm';
    diversions$: Observable<RtvTripDiversion[]>;

    @Input() tripId: number;

    constructor(public divertedTripService: RtDivertedTripsService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tripId && this.tripId) {
            this.diversions$ = this.divertedTripService.getDiversionsByTrip(this.tripId);
        }
    }

    calculateCarId(data: RtvTripDiversion): string {
        return `${data.carInit} ${data.carNo}`;
    }

    calculateDivertLoc(data: RtvTripDiversion): string {
        return `${data.divLocCity}, ${data.divLocState}`;
    }

    calculateDivertDateTime(data: RtvTripDiversion): string {
        return `${moment(data.divertDateTime).utc().format('MM/DD/YYYY HH:mm')}`;
    }
}
