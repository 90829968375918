<ng-template #jeopardizedIcon>
    <fa-duotone-icon
        class="shipment-flag-icon shipment-flag-icon-jeopardy"
        [class.flagged-shipment]="!!jeopardyStatus"
        [icon]="iconJeopardy"
        [fixedWidth]="true"
        [title]="!!jeopardyStatus ? 'This shipment is jeopardized.' : ''"
    ></fa-duotone-icon>
</ng-template>
<ng-template #hotlistedGeneralIcon>
    <fa-duotone-icon
        class="shipment-flag-icon shipment-flag-icon-hotlist-general"
        [class.flagged-shipment]="isHotlistedGeneral"
        [icon]="iconHotListGeneral"
        [fixedWidth]="true"
        [title]="!!isHotlistedGeneral ? 'This shipment is hotlisted.' : ''"
    >
    </fa-duotone-icon>
</ng-template>
<ng-template #hotlistedMechanicalIcon>
    <fa-duotone-icon
        class="shipment-flag-icon shipment-flag-icon-hotlist-mechanical"
        [class.flagged-shipment]="isHotlistedMechanical"
        [icon]="iconHotListMechanical"
        [fixedWidth]="true"
        [title]="
            !!isHotlistedMechanical ? 'This shipment is hotlisted due to a mechanical issue.' : ''
        "
    >
    </fa-duotone-icon>
</ng-template>
<ng-template #hotlistedLeaseIcon>
    <fa-duotone-icon
        class="shipment-flag-icon shipment-flag-icon-hotlist-lease"
        [class.flagged-shipment]="isHotlistedLease"
        [icon]="iconHotListLease"
        [fixedWidth]="true"
        [title]="!!isHotlistedLease ? 'This shipment is hotlisted due to a leasing issue.' : ''"
    >
    </fa-duotone-icon>
</ng-template>
<ng-template #flaggedTroubleIcon>
    <fa-duotone-icon
        class="shipment-flag-icon shipment-flag-icon-trouble"
        [class.flagged-shipment]="isHotlistedTrouble"
        [icon]="iconHotListTrouble"
        [fixedWidth]="true"
        [title]="!!isHotlistedTrouble ? 'This shipment is flagged as in trouble.' : ''"
    >
    </fa-duotone-icon>
</ng-template>
<ng-template #flaggedInterfaceErrorsIcon>
    <fa-duotone-icon
        class="shipment-flag-icon shipment-flag-icon-interface-error"
        [class.flagged-shipment]="!!interfaceValidationErrorCount"
        [icon]="iconInterfaceValiationErrors"
        [fixedWidth]="true"
        [title]="!!interfaceValidationErrorCount ? 'This shipment has validation errors.' : ''"
    >
    </fa-duotone-icon>
</ng-template>
<ng-template #commentCodeIcon>
    <ng-container [ngSwitch]="lastCommentCode">
        <fa-duotone-icon
            *ngSwitchCase="'SD'"
            class="shipment-flag-icon shipment-flag-comment shipment-flag-comment-sd"
            [class.flagged-shipment]="isHotlistedComment"
            [icon]="iconCommentSD"
            [fixedWidth]="true"
        >
        </fa-duotone-icon>
        <fa-duotone-icon
            *ngSwitchCase="'H'"
            class="shipment-flag-icon shipment-flag-comment shipment-flag-comment-h"
            [class.flagged-shipment]="isHotlistedComment"
            [icon]="iconCommentH"
            [fixedWidth]="true"
        >
        </fa-duotone-icon>
        <fa-duotone-icon
            *ngSwitchCase="'M'"
            class="shipment-flag-icon shipment-flag-comment shipment-flag-comment-m"
            [class.flagged-shipment]="isHotlistedComment"
            [icon]="iconCommentM"
            [fixedWidth]="true"
        >
        </fa-duotone-icon>
        <fa-layers *ngSwitchCase="'L'" class="shipment-flag-icon shipment-flag-comment">
            <fa-duotone-icon
                class="shipment-flag-comment-l1"
                [class.flagged-shipment]="isHotlistedComment"
                [icon]="iconCommentL1"
                [fixedWidth]="true"
            ></fa-duotone-icon>
            <fa-duotone-icon
                class="shipment-flag-comment-l2"
                [class.flagged-shipment]="isHotlistedComment"
                [icon]="iconCommentL2"
                [fixedWidth]="true"
                transform="shrink-9"
            ></fa-duotone-icon>
        </fa-layers>
        <fa-duotone-icon
            *ngSwitchDefault
            class="shipment-flag-comment-0"
            [icon]="iconComment0"
            [fixedWidth]="true"
        >
        </fa-duotone-icon>
    </ng-container>
</ng-template>
<ng-template #newEquipmentIcon>
    <fa-duotone-icon
        class="shipment-flag-icon flag-icon-question-mark"
        [class.flagged]="showNewEquipmentMsg"
        [icon]="iconUnknownEquipment"
        [fixedWidth]="true"
        [title]="showNewEquipmentMsg ? 'This equipment is unknown.' : ''"
    >
    </fa-duotone-icon>
</ng-template>
<ng-template #newTripChangedFleetIcon>
    <fa-duotone-icon
        class="shipment-flag-icon flag-icon-generic-warning"
        [class.flagged]="showTripChangedFleet"
        [icon]="iconWarning"
        [fixedWidth]="true"
        [title]="
            showTripChangedFleet
                ? 'Fleet changes will only be applied to the trip. Please update Equipment assignment if necessary.'
                : ''
        "
    >
    </fa-duotone-icon>
</ng-template>
<ng-template #newTripChangedBusGrpIcon>
    <fa-duotone-icon
        class="shipment-flag-icon flag-icon-generic-warning"
        [class.flagged]="showTripChangedBusGrp"
        [icon]="iconWarning"
        [fixedWidth]="true"
        [title]="
            showTripChangedBusGrp
                ? 'Business Group changes will only be applied to the trip. Please update Equipment assignment if necessary.'
                : ''
        "
    >
    </fa-duotone-icon>
</ng-template>
<div *ngIf="showWarnings" class="container-fluid" style="background-color: whitesmoke">
    <div class="d-flex flex-row">
        <mat-card
            class="m-1 mt-3"
            *ngIf="!!jeopardyStatus && !hideJeopardized"
            bdsHoverClass="mat-elevation-z8"
        >
            <mat-card-header>
                <div mat-card-avatar class="bds-jeopardized-icon-avatar">
                    <ng-container *ngTemplateOutlet="jeopardizedIcon"></ng-container>
                </div>
                <mat-card-title>Jeopardized Shipment</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <span>This shipment is jeopardized:</span>
                <ul>
                    <li *ngFor="let s of jeopardyStatus" style="color: red">{{ s }}</li>
                </ul>
            </mat-card-content>
        </mat-card>
        <mat-card
            class="m-1 mt-3"
            *ngIf="isHotlistedGeneral && !hideHotlistGeneral"
            bdsHoverClass="mat-elevation-z8"
        >
            <mat-card-header>
                <div mat-card-avatar>
                    <ng-container *ngTemplateOutlet="hotlistedGeneralIcon"></ng-container>
                </div>
                <mat-card-title>Hotlist</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <span>This shipment is hotlisted.</span>
            </mat-card-content>
        </mat-card>
        <mat-card
            class="m-1 mt-3"
            *ngIf="isHotlistedMechanical && !hideHotlistMechanical"
            bdsHoverClass="mat-elevation-z8"
        >
            <mat-card-header>
                <div mat-card-avatar>
                    <ng-container *ngTemplateOutlet="hotlistedMechanicalIcon"></ng-container>
                </div>
                <mat-card-title>Mechanical Hotlist</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <span>This shipment is hotlisted due to a mechanical issue.</span>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button>Fix</button>
                <button mat-button (click)="clickDismissHotlistMechanical()">Dismiss</button>
            </mat-card-actions>
        </mat-card>
        <mat-card
            class="m-1 mt-3"
            *ngIf="isHotlistedLease && !hideHotlistLease"
            bdsHoverClass="mat-elevation-z8"
        >
            <mat-card-header>
                <div mat-card-avatar>
                    <ng-container *ngTemplateOutlet="hotlistedLeaseIcon"></ng-container>
                </div>
                <mat-card-title>Lease Hotlist</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <span>This shipment is hotlisted due to a leasing issue.</span>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button>Fix</button>
                <button mat-button (click)="clickDismissHotlistLease()">Dismiss</button>
            </mat-card-actions>
        </mat-card>
        <mat-card
            class="m-1 mt-3"
            *ngIf="isHotlistedTrouble && !hideHotlistTrouble"
            bdsHoverClass="mat-elevation-z8"
        >
            <mat-card-header>
                <div mat-card-avatar>
                    <ng-container *ngTemplateOutlet="flaggedTroubleIcon"></ng-container>
                </div>
                <mat-card-title>Trouble</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <span>This shipment is flagged as in trouble.</span>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button>Fix</button>
                <button mat-button (click)="clickDismissHotlistTrouble()">Dismiss</button>
            </mat-card-actions>
        </mat-card>
        <mat-card
            class="m-1 mt-3"
            *ngIf="interfaceValidationErrorCount > 0 && !hideHotlistInterfaceErrors"
            bdsHoverClass="mat-elevation-z8"
        >
            <mat-card-header>
                <div mat-card-avatar>
                    <ng-container *ngTemplateOutlet="flaggedInterfaceErrorsIcon"></ng-container>
                </div>
                <mat-card-title>Interface Errors</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <span>
                    This shipment has
                    <span class="warn warning">{{ interfaceValidationErrorCount }}</span>
                    interface validation error{{ interfaceValidationErrorCount === 1 ? '' : 's' }}.
                </span>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button>Fix</button>
                <button mat-button (click)="clickDismissHotlistInterfaceErrors()">Dismiss</button>
            </mat-card-actions>
        </mat-card>
        <mat-card
            class="m-1 mt-3"
            *ngIf="isHotlistedComment && !hideHotlistCommentCode"
            bdsHoverClass="mat-elevation-z8"
        >
            <mat-card-header>
                <div mat-card-avatar>
                    <ng-container *ngTemplateOutlet="commentCodeIcon"></ng-container>
                </div>
                <mat-card-title>Comment</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <span>{{ trip.comments }}</span>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button>Fix</button>
                <button mat-button (click)="clickDismissHotlistCommentCode()">Dismiss</button>
            </mat-card-actions>
        </mat-card>
        <mat-card class="m-1 mt-3" *ngIf="showNewEquipmentMsg" bdsHoverClass="mat-elevation-z8">
            <mat-card-header>
                <div mat-card-avatar>
                    <ng-container *ngTemplateOutlet="newEquipmentIcon"></ng-container>
                </div>
                <mat-card-title>Unknown Equipment</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <span>This equipment will be added on save.</span>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button (click)="clickDismissNewEquipment()">Dismiss</button>
            </mat-card-actions>
        </mat-card>
        <mat-card class="m-1 mt-3" *ngIf="showTripChangedFleet" bdsHoverClass="mat-elevation-z8">
            <mat-card-header>
                <div mat-card-avatar>
                    <ng-container *ngTemplateOutlet="newTripChangedFleetIcon"></ng-container>
                </div>
                <mat-card-title>Fleet Change</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <span
                    >Fleet changes will only be applied to the trip. Please update Equipment
                    assignment if necessary.</span
                >
            </mat-card-content>
            <mat-card-actions>
                <button mat-button (click)="clickDismissNewTripChangedFleet()">Dismiss</button>
            </mat-card-actions>
        </mat-card>
        <mat-card class="m-1 mt-3" *ngIf="showTripChangedBusGrp" bdsHoverClass="mat-elevation-z8">
            <mat-card-header>
                <div mat-card-avatar>
                    <ng-container *ngTemplateOutlet="newTripChangedBusGrpIcon"></ng-container>
                </div>
                <mat-card-title>Business Group Change</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <span
                    >Business Group changes will only be applied to the trip. Please update
                    Equipment assignment if necessary.</span
                >
            </mat-card-content>
            <mat-card-actions>
                <button mat-button (click)="clickDismissNewTripChangedBusGrp()">Dismiss</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>

<dx-validation-group #dxvg>
    <div class="container mt-1" [formGroup]="tripForm">
        <div
            class="row"
            bdsScrollSpySection="tripshipmentdetailsanchor"
            [scrollMargin]="116"
            #tripshipmentdetailsanchor
        >
            <div class="col-12 col-lg-4">
                <h4 class="d-flex flex-row">
                    <span>Shipment Details</span>
                    <span class="flex-fill"></span>
                    <span>
                        <span class="ml-2"
                            ><ng-container [ngTemplateOutlet]="jeopardizedIcon"></ng-container
                        ></span>
                        <span class="ml-2"
                            ><ng-container [ngTemplateOutlet]="hotlistedGeneralIcon"></ng-container
                        ></span>
                        <span class="ml-2"
                            ><ng-container
                                [ngTemplateOutlet]="hotlistedMechanicalIcon"
                            ></ng-container
                        ></span>
                        <span class="ml-2"
                            ><ng-container [ngTemplateOutlet]="hotlistedLeaseIcon"></ng-container
                        ></span>
                        <span class="ml-2"
                            ><ng-container [ngTemplateOutlet]="flaggedTroubleIcon"></ng-container
                        ></span>
                        <span class="ml-2"
                            ><ng-container
                                [ngTemplateOutlet]="flaggedInterfaceErrorsIcon"
                            ></ng-container
                        ></span>
                        <span class="ml-2"
                            ><ng-container [ngTemplateOutlet]="commentCodeIcon"></ng-container
                        ></span>
                        <span class="ml-2"
                            ><ng-container [ngTemplateOutlet]="newEquipmentIcon"></ng-container
                        ></span>
                        <span class="ml-2"
                            ><ng-container
                                [ngTemplateOutlet]="newTripChangedFleetIcon"
                            ></ng-container
                        ></span>
                        <span class="ml-2"
                            ><ng-container
                                [ngTemplateOutlet]="newTripChangedBusGrpIcon"
                            ></ng-container
                        ></span>
                    </span>
                </h4>
                <div class="row">
                    <div [class.col-5]="!allowChangeKey" [class.col-4]="allowChangeKey">
                        <mat-form-field
                            *ngIf="allowChangeKey"
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label> Car Init </mat-label>
                            <input matInput bdsShoutcase tabindex="0" formControlName="carInit" />
                            <mat-error *ngIf="tripForm.controls.carInit.errors">
                                {{ getErrorText(tripForm.controls.carInit) }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field
                            *ngIf="!allowChangeKey"
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>
                                Car Init
                                <span class="dirty-indicator" *ngIf="highlightDirty">*</span>
                            </mat-label>
                            <input
                                matInput
                                bdsShoutcase
                                [tabindex]="allowChangeKey ? 0 : -1"
                                [readonly]="!allowChangeKey"
                                formControlName="carInit"
                            />
                            <mat-hint>Read only</mat-hint>
                        </mat-form-field>
                    </div>
                    <div [class.col-7]="!allowChangeKey" [class.col-4]="allowChangeKey">
                        <!-- <dx-select-box
                            *ngIf="allowChangeKey"
                            #carNumSelectBox
                            [stylingMode]="dxMatStyle"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.dx-mat-form-field-floatLabel-always]="matFloatLabel == 'always'"
                            class="bds-dx-custom-material flex-fill"
                            formControlName="carNo"
                            [dataSource]="equipmentNumLookup"
                            (onValueChanged)="onEquipmentNumberChanged($event)"
                            (onCustomItemCreating)="onDxSelectBoxCustomItemUpper($event)"
                            [searchEnabled]="true"
                            [readOnly]="!allowChangeKey"
                            label="Car Number"
                            [acceptCustomValue]="true"
                            validationMessageMode="always"
                            [isValid]="tripForm.controls.carNo.valid"
                        >
                            <dx-validator>
                                <dxi-validation-rule
                                    type="required"
                                    message="Car No is required."
                                ></dxi-validation-rule>
                                <dxi-validation-rule
                                    type="stringLength"
                                    min="6"
                                    message="Car No can only be 6 chars long."
                                ></dxi-validation-rule>
                                <dxi-validation-rule
                                    type="stringLength"
                                    max="6"
                                    message="Car No can only be 6 chars long."
                                ></dxi-validation-rule>
                            </dx-validator>
                        </dx-select-box> -->
                        <mat-form-field
                            *ngIf="allowChangeKey"
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label> Car Number </mat-label>
                            <input
                                matInput
                                bdsShoutcase
                                [tabindex]="0"
                                formControlName="carNo"
                                (blur)="onEquipmentNumberChanged($event)"
                                bdsZeroPad
                                [pad]="6"
                            />
                            <mat-error *ngIf="tripForm.controls.carNo.errors">
                                {{ getErrorText(tripForm.controls.carNo) }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field
                            *ngIf="!allowChangeKey"
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>
                                Car Number
                                <span class="dirty-indicator" *ngIf="highlightDirty">*</span>
                            </mat-label>
                            <input
                                matInput
                                bdsShoutcase
                                [readonly]="!allowChangeKey"
                                [tabindex]="allowChangeKey ? 0 : -1"
                                formControlName="carNo"
                            />
                            <mat-hint>Read only</mat-hint>
                        </mat-form-field>
                    </div>
                    <div *ngIf="allowChangeKey" class="col-4">
                        <mat-slide-toggle
                            [(ngModel)]="createAsLoadAndHold"
                            [ngModelOptions]="{ standalone: true }"
                            color="primary"
                            (change)="toggleLoadHold()"
                            >Load &amp; Hold</mat-slide-toggle
                        >
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>BOL Number </mat-label>
                            <input matInput bdsShoutcase formControlName="bolNo" />
                            <mat-error *ngIf="tripForm.controls.bolNo.errors">
                                {{ getErrorText(tripForm.controls.bolNo) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <dx-select-box
                            [stylingMode]="dxMatStyle"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.dx-mat-form-field-floatLabel-always]="matFloatLabel === 'always'"
                            class="bds-dx-custom-material flex-fill"
                            formControlName="fleetId"
                            [dataSource]="fleetDxLookupSource"
                            fieldTemplate="inputField"
                            itemTemplate="item"
                            valueExpr="fleetId"
                            [searchEnabled]="true"
                            [searchExpr]="['fleetId', 'fleetName']"
                            label="Fleet"
                        >
                            <dxo-drop-down-options minWidth="250px"></dxo-drop-down-options>
                            <div *dxTemplate="let data of 'item'">
                                <span>{{ data.fleetId }} - {{ data.fleetName }}</span>
                            </div>
                            <div *dxTemplate="let data of 'inputField'">
                                <dx-text-box
                                    label="Fleet"
                                    style="display: inline-block"
                                    [value]="
                                        data ? kvpDisplayTemplate(data.fleetId, data.fleetName) : ''
                                    "
                                ></dx-text-box>
                            </div>
                        </dx-select-box>
                    </div>
                    <div class="col-4">
                        <dx-select-box
                            [stylingMode]="dxMatStyle"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.dx-mat-form-field-floatLabel-always]="matFloatLabel === 'always'"
                            class="bds-dx-custom-material flex-fill"
                            formControlName="businessGroup"
                            [dataSource]="businessGroupDxLookupSource"
                            fieldTemplate="inputField"
                            itemTemplate="item"
                            valueExpr="businessGroup"
                            [searchEnabled]="true"
                            [searchExpr]="['businessGroup', 'businessGroupName']"
                            label="Business Group"
                        >
                            <dxo-drop-down-options minWidth="250px"></dxo-drop-down-options>
                            <div *dxTemplate="let data of 'item'">
                                <span *ngIf="data; else businessGroupNoItem"
                                    >{{ data.businessGroup }} - {{ data.businessGroupName }}</span
                                >
                                <ng-template #businessGroupNoItem><span></span></ng-template>
                            </div>
                            <div *dxTemplate="let data of 'inputField'">
                                <dx-text-box
                                    label="Business Group"
                                    style="display: inline-block"
                                    [value]="
                                        data
                                            ? kvpDisplayTemplate(
                                                  data.businessGroup,
                                                  data.businessGroupName
                                              )
                                            : ''
                                    "
                                ></dx-text-box>
                            </div>
                        </dx-select-box>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex flex-row">
                            <div class="flex-grow-1">
                                <dx-select-box
                                    #consigneeSelectBox
                                    [stylingMode]="dxMatStyle"
                                    [class.bds-mat-dense-form-field]="dense"
                                    [class.dx-mat-form-field-floatLabel-always]="
                                        matFloatLabel === 'always'
                                    "
                                    class="bds-dx-custom-material flex-fill"
                                    formControlName="customerNo"
                                    [dataSource]="customerFactDxLookupSource"
                                    fieldTemplate="inputField"
                                    itemTemplate="item"
                                    [valueExpr]="customerFactDxLookupValueExpr"
                                    [searchExpr]="customerFactDxLookupSearchExpr"
                                    [(selectedItem)]="selectedCustomer"
                                    [searchEnabled]="true"
                                    label="Consignee"
                                    (onValueChanged)="onCustomerFactChanged($event)"
                                    validationMessageMode="always"
                                    [required]="!createAsLoadAndHold"
                                >
                                    <dxi-button
                                        *ngIf="!trip.ormId"
                                        name="addConsignee"
                                        [options]="consigneeAddInlineButtonOptions"
                                    ></dxi-button>
                                    <dxi-button
                                        *ngIf="selectedCustomer && !trip.ormId"
                                        name="editConsignee"
                                        [options]="consigneeEditInlineButtonOptions"
                                    ></dxi-button>
                                    <dxi-button
                                        *ngIf="!trip.ormId"
                                        name="searchConsignee"
                                        [options]="consigneeSearchInlineButtonOptions"
                                    ></dxi-button>
                                    <dxi-button name="dropDown"></dxi-button>
                                    <div *dxTemplate="let data of 'item'">
                                        <span>{{ customerFactDxLookupDisplayExpr(data) }}</span>
                                    </div>
                                    <div *dxTemplate="let data of 'inputField'">
                                        <dx-text-box
                                            bdsShoutcase
                                            style="display: inline-block"
                                            width="100%"
                                            [value]="customerFactDxLookupDisplayExpr(data)"
                                            [label]="
                                                'Consignee' + (!createAsLoadAndHold ? ' *' : '')
                                            "
                                        ></dx-text-box>
                                    </div>
                                    <dx-validator>
                                        <dxi-validation-rule
                                            *ngIf="!createAsLoadAndHold"
                                            type="required"
                                            message="Consignee is required."
                                        ></dxi-validation-rule>
                                    </dx-validator>
                                </dx-select-box>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label
                                >Initiate L/E {{ !createAsLoadAndHold ? ' *' : '' }}</mat-label
                            >
                            <mat-select formControlName="loadEmpty">
                                <mat-option></mat-option>
                                <mat-option value="L">Load</mat-option>
                                <mat-option value="E">Empty</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <dx-validator>
                            <dxi-validation-rule
                                type="required"
                                message="Required."
                            ></dxi-validation-rule>
                        </dx-validator>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <bds-shipment-type-select
                            formControlName="shipmentType"
                            (selectionChange)="manualUpdate()"
                        ></bds-shipment-type-select>
                    </div>
                    <div class="col-7">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>Care of Name</mat-label>
                            <input
                                matInput
                                bdsShoutcase
                                readonly
                                tabindex="-1"
                                [value]="
                                    consigneeSelectBox.selectedItem
                                        ? consigneeSelectBox.selectedItem.careOfName
                                        : ''
                                "
                            />
                            <mat-hint>Read only</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex flex-row">
                            <div class="flex-grow-1">
                                <dx-select-box
                                    #routeCodeSelectBox
                                    [stylingMode]="dxMatStyle"
                                    [class.bds-mat-dense-form-field]="dense"
                                    [class.dx-mat-form-field-floatLabel-always]="
                                        matFloatLabel === 'always'
                                    "
                                    class="bds-dx-custom-material flex-fill"
                                    formControlName="routeCode"
                                    [dataSource]="routeCodeDxLookupSource"
                                    fieldTemplate="inputField"
                                    itemTemplate="item"
                                    [valueExpr]="routeCodeDxLookupValueExpr"
                                    [(selectedItem)]="selectedRoute"
                                    [searchEnabled]="true"
                                    [searchExpr]="routeCodeDxLookupSearchExpr"
                                    label="Route"
                                    validationMessageMode="always"
                                >
                                    <dxi-button
                                        *ngIf="!trip.ormId"
                                        name="addRoute"
                                        [options]="routeAddInlineButtonOptions"
                                    ></dxi-button>
                                    <dxi-button
                                        *ngIf="selectedRoute && !trip.ormId"
                                        name="editRoute"
                                        [options]="routeEditInlineButtonOptions"
                                    ></dxi-button>
                                    <dxi-button
                                        *ngIf="!trip.ormId"
                                        name="searchRoute"
                                        [options]="routeCodeSearchInlineButtonOptions"
                                    ></dxi-button>
                                    <dxi-button name="dropDown"></dxi-button>
                                    <div *dxTemplate="let data of 'item'">
                                        <span>{{ routeCodeDxLookupDisplayExpr(data) }}</span>
                                    </div>
                                    <div *dxTemplate="let data of 'inputField'">
                                        <dx-text-box
                                            style="display: inline-block"
                                            width="100%"
                                            [value]="routeCodeDxLookupDisplayExpr(data)"
                                            [label]="'Route' + (createAsLoadAndHold ? '' : ' *')"
                                        ></dx-text-box>
                                    </div>
                                    <dx-validator>
                                        <dxi-validation-rule
                                            type="required"
                                            message="Required"
                                        ></dxi-validation-rule>
                                    </dx-validator>
                                </dx-select-box>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="createAsLoadAndHold">
                    <div class="col-10">
                        <bds-erpc-origin-select
                            formControlName="originCity"
                            label="Origin City"
                            [enableSearchPopup]="true"
                            [enableAdd]="true"
                            [newWindowForAdd]="true"
                            [newWindowForEdit]="true"
                            [enableEdit]="tripForm.controls.originCity.value"
                            [displayValue]="displayValue"
                            [title]="'Select Origin'"
                            valueExpr="erpcOrigin"
                            (selected)="onOriginCitySelected($event)"
                        >
                            <mat-error *ngIf="tripForm.controls.originCity.errors"
                                >Required
                            </mat-error>
                        </bds-erpc-origin-select>
                    </div>
                    <div class="col-2">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label>State </mat-label>
                            <mat-select formControlName="originState">
                                <mat-option></mat-option>
                                <mat-option
                                    *ngFor="let item of stateCodeLookup | async"
                                    [value]="item.code"
                                >
                                    {{ item.code }} - {{ item.description }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-10">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label>Destination </mat-label>
                            <input matInput bdsShoutcase formControlName="destinationCity" />
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label
                                >State
                                <span class="dirty-indicator" *ngIf="highlightDirty"
                                    >*</span
                                ></mat-label
                            >
                            <mat-select formControlName="destinationState">
                                <mat-option></mat-option>
                                <mat-option
                                    *ngFor="let item of stateCodeLookup | async"
                                    [value]="item.code"
                                >
                                    {{ item.code }} - {{ item.description }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row" *ngIf="!createAsLoadAndHold">
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirtyOriginCity"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label
                                >Origin City
                                <span class="dirty-indicator" *ngIf="highlightDirtyOriginCity"
                                    >*</span
                                >
                            </mat-label>
                            <input matInput bdsShoutcase formControlName="originCity" />
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label
                                >State
                                <span class="dirty-indicator" *ngIf="highlightDirty"
                                    >*</span
                                ></mat-label
                            >
                            <mat-select formControlName="originState">
                                <mat-option></mat-option>
                                <mat-option
                                    *ngFor="let item of stateCodeLookup | async"
                                    [value]="item.code"
                                >
                                    {{ item.code }} - {{ item.description }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label>Destination </mat-label>
                            <input matInput bdsShoutcase formControlName="destinationCity" />
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label
                                >State
                                <span class="dirty-indicator" *ngIf="highlightDirty"
                                    >*</span
                                ></mat-label
                            >
                            <mat-select formControlName="destinationState">
                                <mat-option></mat-option>
                                <mat-option
                                    *ngFor="let item of stateCodeLookup | async"
                                    [value]="item.code"
                                >
                                    {{ item.code }} - {{ item.description }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row" *ngIf="!canEditReturnCityState">
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label>Release City </mat-label>
                            <input matInput bdsShoutcase formControlName="releaseCity" />
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label
                                >State
                                <span class="dirty-indicator" *ngIf="highlightDirty"
                                    >*</span
                                ></mat-label
                            >
                            <mat-select formControlName="releaseState">
                                <mat-option></mat-option>
                                <mat-option
                                    *ngFor="let item of stateCodeLookup | async"
                                    [value]="item.code"
                                >
                                    {{ item.code }} - {{ item.description }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label>Return City </mat-label>
                            <input matInput bdsShoutcase formControlName="returnCity" />
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label
                                >State
                                <span class="dirty-indicator" *ngIf="highlightDirty"
                                    >*</span
                                ></mat-label
                            >
                            <mat-select formControlName="returnState">
                                <mat-option></mat-option>
                                <mat-option
                                    *ngFor="let item of stateCodeLookup | async"
                                    [value]="item.code"
                                >
                                    {{ item.code }} - {{ item.description }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row" *ngIf="canEditReturnCityState">
                    <div class="col-10">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label>Release City </mat-label>
                            <input matInput bdsShoutcase formControlName="releaseCity" />
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label
                                >State
                                <span class="dirty-indicator" *ngIf="highlightDirty"
                                    >*</span
                                ></mat-label
                            >
                            <mat-select formControlName="releaseState">
                                <mat-option></mat-option>
                                <mat-option
                                    *ngFor="let item of stateCodeLookup | async"
                                    [value]="item.code"
                                >
                                    {{ item.code }} - {{ item.description }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-10">
                        <bds-splc-erpc-select
                            formControlName="returnCity"
                            label="Return City"
                            [title]="'Select Return City'"
                            [displayValue]="splcErpcDisplayValue"
                            [disabled]="false"
                            [enableSearchPopup]="true"
                            valueExpr="erpcCity"
                            [required]="canEditReturnCityState"
                            (selected)="onReturnCitySelected($event)"
                        >
                            <mat-error *ngIf="tripForm.controls.returnCity.invalid"
                                >Return City is required</mat-error
                            >
                        </bds-splc-erpc-select>
                    </div>
                    <div class="col-2">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label
                                >State
                                <span class="dirty-indicator" *ngIf="highlightDirty"
                                    >*</span
                                ></mat-label
                            >
                            <mat-select formControlName="returnState">
                                <mat-option></mat-option>
                                <mat-option
                                    *ngFor="let item of stateCodeLookup | async"
                                    [value]="item.code"
                                >
                                    {{ item.code }} - {{ item.description }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>Trip Status </mat-label>
                            <mat-select
                                formControlName="tripStatus"
                                (selectionChange)="onTripStatusChange($event)"
                            >
                                <mat-option></mat-option>
                                <mat-option value="O">Open</mat-option>
                                <mat-option value="C">Closed</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label>Car Status </mat-label>
                            <mat-select
                                formControlName="carStatus"
                                (selectionChange)="manualUpdate()"
                            >
                                <mat-option></mat-option>
                                <mat-option value="0">0 - At Origin</mat-option>
                                <mat-option value="1">1 - To Consignee</mat-option>
                                <mat-option value="2">2 - At Consignee</mat-option>
                                <mat-option value="3">3 - To Origin</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label>One Way/RT </mat-label>
                            <mat-select formControlName="oneWayRound">
                                <mat-option></mat-option>
                                <mat-option value="O">One Way</mat-option>
                                <mat-option value="R">Round Trip</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label>Shop Move </mat-label>
                            <mat-select formControlName="shopMove">
                                <mat-option></mat-option>
                                <mat-option value="Y">Yes</mat-option>
                                <mat-option value="N">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label>Diverted </mat-label>
                            <mat-select formControlName="divertYorN">
                                <mat-option></mat-option>
                                <mat-option value="Y">Yes</mat-option>
                                <mat-option value="N">No</mat-option>
                            </mat-select>
                            <mat-hint>Read only</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label>Free Runner </mat-label>
                            <mat-select formControlName="freeRunner">
                                <mat-option></mat-option>
                                <mat-option value="Y">Yes</mat-option>
                                <mat-option value="N">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex flex-row">
                            <div class="flex-grow-1">
                                <dx-select-box
                                    #supplierSelectBox
                                    [stylingMode]="dxMatStyle"
                                    [class.bds-mat-dense-form-field]="dense"
                                    [class.dx-mat-form-field-floatLabel-always]="
                                        matFloatLabel === 'always'
                                    "
                                    class="bds-dx-custom-material flex-fill"
                                    formControlName="supplierCode"
                                    [dataSource]="supplierFactDxLookupSource"
                                    fieldTemplate="inputField"
                                    itemTemplate="item"
                                    [valueExpr]="supplierFactDxLookupValueExpr"
                                    [searchExpr]="supplierFactDxLookupSearchExpr"
                                    [(selectedItem)]="selectedSupplier"
                                    [searchEnabled]="true"
                                    placeholder="Supplier Number (Supplier Code)"
                                    validationMessageMode="always"
                                    (onValueChanged)="onSupplierFactChanged($event)"
                                    [isValid]="tripForm.controls.supplierCode.valid"
                                >
                                    <dxi-button
                                        name="addSupplier"
                                        [options]="supplierAddInlineButtonOptions"
                                    ></dxi-button>
                                    <dxi-button
                                        *ngIf="selectedSupplier"
                                        name="editSupplier"
                                        [options]="supplierEditInlineButtonOptions"
                                    ></dxi-button>
                                    <dxi-button
                                        name="searchSupplier"
                                        [options]="supplierSearchInlineButtonOptions"
                                    ></dxi-button>
                                    <dxi-button name="dropDown"></dxi-button>
                                    <div *dxTemplate="let data of 'item'">
                                        <span>{{ supplierFactDxLookupDisplayExpr(data) }}</span>
                                    </div>
                                    <div *dxTemplate="let data of 'inputField'">
                                        <dx-text-box
                                            style="display: inline-block"
                                            width="100%"
                                            [value]="supplierFactInputDxLookupDisplayExpr(data)"
                                            label="Supplier Code"
                                        ></dx-text-box>
                                    </div>
                                </dx-select-box>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label>Supplier Name</mat-label>
                            <input matInput bdsShoutcase formControlName="supplierName" />
                            <mat-error *ngIf="tripForm.controls.supplierName.errors">
                                {{ getErrorText(tripForm.controls.supplierName) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>Unit Train ID </mat-label>
                            <input matInput bdsShoutcase formControlName="unitTrainId" />
                            <mat-error *ngIf="tripForm.controls.unitTrainId.errors">
                                {{ getErrorText(tripForm.controls.unitTrainId) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-8">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                            [class.mat-form-field-disabled]="false"
                        >
                            <mat-label>Bad Order Days </mat-label>
                            <input
                                matInput
                                bdsShoutcase
                                type="number"
                                formControlName="badOrderDays"
                            />
                            <span matSuffix>days</span>
                        </mat-form-field>
                    </div>
                    <div class="col-4" *ngIf="false">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>Car Condition </mat-label>
                            <mat-select formControlName="carCondition">
                                <mat-option></mat-option>
                                <mat-option value="G">Good</mat-option>
                                <mat-option value="B">Bad</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label
                                >Freight Payment Code
                                <span class="dirty-indicator" *ngIf="highlightDirty"
                                    >*</span
                                ></mat-label
                            >
                            <mat-select formControlName="freightPmtCode">
                                <mat-option></mat-option>
                                <mat-option value="C">COLLECT</mat-option>
                                <mat-option value="F">CUSTOMER - PP</mat-option>
                                <mat-option value="M">MIXED</mat-option>
                                <mat-option value="N">NON-REVENUE</mat-option>
                                <mat-option value="P">PREPAID</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>Shipper Number </mat-label>
                            <input matInput bdsShoutcase formControlName="shipperNo" />
                            <mat-error *ngIf="tripForm.controls.shipperNo.errors">
                                {{ getErrorText(tripForm.controls.shipperNo) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>Bill Detention </mat-label>
                            <mat-select formControlName="billDetentionYorN">
                                <mat-option></mat-option>
                                <mat-option value="Y">Yes</mat-option>
                                <mat-option value="N">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 col-lg-3">
                <h4>Dates</h4>
                <div class="row">
                    <div class="col-12">
                        <bds-date-time-picker
                            formControlName="shipDatetime"
                            label="Shipment Date *"
                            [isValid]="tripForm.controls.shipDatetime.valid"
                            [readOnly]="trip.ormId"
                            [isDefaultTime]="!this.trip.ormId"
                        ></bds-date-time-picker>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <bds-date-time-picker
                            formControlName="previousTripCloseDateTime"
                            label="Previous Trip Close"
                            [readOnly]="true"
                        ></bds-date-time-picker>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <bds-date-time-picker
                            formControlName="origEdaEtaCustomer"
                            label="RT ETA Consignee"
                            [readOnly]="true"
                        ></bds-date-time-picker>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <bds-date-time-picker
                            formControlName="origEdaEtaOrigin"
                            label="RT ETA Origin"
                            [readOnly]="true"
                        ></bds-date-time-picker>
                    </div>
                </div>
                <div class="row">
                    <div class="col-8">
                        <bds-date-time-picker
                            formControlName="destinationArrDateTime"
                            label="Consignee Notification"
                        >
                        </bds-date-time-picker>
                    </div>
                    <div class="col-4">
                        <mat-button-toggle-group
                            class="w-100"
                            formControlName="destinationArrType"
                            name="destinationArrType"
                        >
                            <mat-button-toggle value="E" class="w-50 bds-mat-dense-button">
                                <fa-icon
                                    [icon]="iconDotCircle"
                                    [fixedWidth]="true"
                                    class="toggleIndicator toggleFilled"
                                ></fa-icon>
                                <fa-icon
                                    [icon]="iconCircle"
                                    [fixedWidth]="true"
                                    class="toggleIndicator toggleEmpty"
                                ></fa-icon>
                                EST</mat-button-toggle
                            >
                            <mat-button-toggle value="A" class="w-50 bds-mat-dense-button">
                                <fa-icon
                                    [icon]="iconDotCircle"
                                    [fixedWidth]="true"
                                    class="toggleIndicator toggleFilled"
                                ></fa-icon>
                                <fa-icon
                                    [icon]="iconCircle"
                                    [fixedWidth]="true"
                                    class="toggleIndicator toggleEmpty"
                                ></fa-icon>
                                ACT</mat-button-toggle
                            >
                        </mat-button-toggle-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-8">
                        <bds-date-time-picker
                            formControlName="customerDeliveryDateTime"
                            label="Consignee Delivery"
                        ></bds-date-time-picker>
                    </div>
                    <div class="col-4">
                        <mat-button-toggle-group
                            class="w-100"
                            formControlName="customerDeliveryType"
                            name="customerDeliveryType"
                        >
                            <mat-button-toggle
                                value="E"
                                class="w-50 bds-mat-dense-button"
                                [class.isChecked]=""
                            >
                                <fa-icon
                                    [icon]="iconDotCircle"
                                    [fixedWidth]="true"
                                    class="toggleIndicator toggleFilled"
                                ></fa-icon>
                                <fa-icon
                                    [icon]="iconCircle"
                                    [fixedWidth]="true"
                                    class="toggleIndicator toggleEmpty"
                                ></fa-icon>
                                EST
                            </mat-button-toggle>
                            <mat-button-toggle value="A" class="w-50 bds-mat-dense-button">
                                <fa-icon
                                    [icon]="iconDotCircle"
                                    [fixedWidth]="true"
                                    class="toggleIndicator toggleFilled"
                                ></fa-icon>
                                <fa-icon
                                    [icon]="iconCircle"
                                    [fixedWidth]="true"
                                    class="toggleIndicator toggleEmpty"
                                ></fa-icon>
                                ACT
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-8">
                        <bds-date-time-picker
                            formControlName="customerReleaseDateTime"
                            label="Consignee Release Date"
                        ></bds-date-time-picker>
                    </div>
                    <div class="col-4">
                        <mat-button-toggle-group
                            color="primary"
                            class="w-100"
                            formControlName="customerReleaseType"
                            name="customerReleaseType"
                        >
                            <mat-button-toggle value="E" class="w-50 bds-mat-dense-button">
                                <fa-icon
                                    [icon]="iconDotCircle"
                                    [fixedWidth]="true"
                                    class="toggleIndicator toggleFilled"
                                ></fa-icon>
                                <fa-icon
                                    [icon]="iconCircle"
                                    [fixedWidth]="true"
                                    class="toggleIndicator toggleEmpty"
                                ></fa-icon>
                                EST</mat-button-toggle
                            >
                            <mat-button-toggle value="A" class="w-50 bds-mat-dense-button">
                                <fa-icon
                                    [icon]="iconDotCircle"
                                    [fixedWidth]="true"
                                    class="toggleIndicator toggleFilled"
                                ></fa-icon>
                                <fa-icon
                                    [icon]="iconCircle"
                                    [fixedWidth]="true"
                                    class="toggleIndicator toggleEmpty"
                                ></fa-icon>
                                ACT</mat-button-toggle
                            >
                        </mat-button-toggle-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-8">
                        <bds-date-time-picker
                            formControlName="tripCloseDateTime"
                            label="Trip Close"
                        ></bds-date-time-picker>
                    </div>
                    <div class="col-4">
                        <mat-button-toggle-group
                            class="w-100"
                            formControlName="tripCloseType"
                            name="tripCloseType"
                        >
                            <mat-button-toggle value="E" class="w-50 bds-mat-dense-button">
                                <fa-icon
                                    [icon]="iconDotCircle"
                                    [fixedWidth]="true"
                                    class="toggleIndicator toggleFilled"
                                ></fa-icon>
                                <fa-icon
                                    [icon]="iconCircle"
                                    [fixedWidth]="true"
                                    class="toggleIndicator toggleEmpty"
                                ></fa-icon>
                                EST</mat-button-toggle
                            >
                            <mat-button-toggle value="A" class="w-50 bds-mat-dense-button">
                                <fa-icon
                                    [icon]="iconDotCircle"
                                    [fixedWidth]="true"
                                    class="toggleIndicator toggleFilled"
                                ></fa-icon>
                                <fa-icon
                                    [icon]="iconCircle"
                                    [fixedWidth]="true"
                                    class="toggleIndicator toggleEmpty"
                                ></fa-icon>
                                ACT</mat-button-toggle
                            >
                        </mat-button-toggle-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-8"></div>
                    <div class="col-4"></div>
                </div>
                <div class="row">
                    <div class="col-8"></div>
                    <div class="col-4"></div>
                </div>
                <div class="row">
                    <div class="col-8"></div>
                    <div class="col-4"></div>
                </div>
            </div>
            <div class="col-12 col-md-7 col-lg-5">
                <mat-tab-group>
                    <mat-tab label="Reference">
                        <ng-template matTabContent>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field
                                        floatLabel="always"
                                        class="w-100"
                                        appearance="fill"
                                        [class.highlight-dirty]="highlightDirty"
                                        [class.bds-mat-dense-form-field]="dense"
                                    >
                                        <mat-label
                                            >Order Number
                                            <span class="dirty-indicator" *ngIf="highlightDirty"
                                                >*</span
                                            >
                                        </mat-label>
                                        <input matInput bdsShoutcase formControlName="tripNo" />
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-form-field
                                        floatLabel="always"
                                        class="w-100"
                                        appearance="fill"
                                        [class.highlight-dirty]="highlightDirty"
                                        [class.bds-mat-dense-form-field]="dense"
                                    >
                                        <mat-label
                                            >Customer PO Number
                                            <span class="dirty-indicator" *ngIf="highlightDirty"
                                                >*</span
                                            >
                                        </mat-label>
                                        <input matInput bdsShoutcase formControlName="custPoNo" />
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-form-field
                                        floatLabel="always"
                                        class="w-100"
                                        appearance="fill"
                                        [class.highlight-dirty]="highlightDirty"
                                        [class.bds-mat-dense-form-field]="dense"
                                    >
                                        <mat-label
                                            >Master Origin
                                            <span class="dirty-indicator" *ngIf="highlightDirty"
                                                >*</span
                                            >
                                        </mat-label>
                                        <input
                                            matInput
                                            bdsShoutcase
                                            formControlName="masterOrigin"
                                            [disabled]="true"
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field
                                        floatLabel="always"
                                        class="w-100"
                                        appearance="fill"
                                        [class.highlight-dirty]="highlightDirty"
                                        [class.bds-mat-dense-form-field]="dense"
                                    >
                                        <mat-label
                                            >Sales Rep
                                            <span class="dirty-indicator" *ngIf="highlightDirty"
                                                >*</span
                                            >
                                        </mat-label>
                                        <input matInput bdsShoutcase formControlName="salesRep" />
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-form-field
                                        floatLabel="always"
                                        class="w-100"
                                        appearance="fill"
                                        [class.highlight-dirty]="highlightDirty"
                                        [class.bds-mat-dense-form-field]="dense"
                                    >
                                        <mat-label
                                            >CSR Number
                                            <span class="dirty-indicator" *ngIf="highlightDirty"
                                                >*</span
                                            >
                                        </mat-label>
                                        <input matInput bdsShoutcase formControlName="csrNo" />
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-form-field
                                        floatLabel="always"
                                        class="w-100"
                                        appearance="fill"
                                        [class.highlight-dirty]="highlightDirty"
                                        [class.bds-mat-dense-form-field]="dense"
                                    >
                                        <mat-label
                                            >Master Trip Number
                                            <span class="dirty-indicator" *ngIf="highlightDirty"
                                                >*</span
                                            >
                                        </mat-label>
                                        <input
                                            matInput
                                            bdsShoutcase
                                            formControlName="masterTripNo"
                                            [disabled]="true"
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field
                                        floatLabel="always"
                                        class="w-100"
                                        appearance="fill"
                                        [class.highlight-dirty]="highlightDirty"
                                        [class.bds-mat-dense-form-field]="dense"
                                    >
                                        <mat-label
                                            >{{ getRefFieldLabel('trip_ref1') }}
                                            <span class="dirty-indicator" *ngIf="highlightDirty"
                                                >*</span
                                            >
                                        </mat-label>
                                        <input matInput bdsShoutcase formControlName="trip_ref1" />
                                        <mat-error *ngIf="tripForm.controls.trip_ref1.errors">
                                            {{ getErrorText(tripForm.controls.trip_ref1) }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-form-field
                                        floatLabel="always"
                                        class="w-100"
                                        appearance="fill"
                                        [class.highlight-dirty]="highlightDirty"
                                        [class.bds-mat-dense-form-field]="dense"
                                    >
                                        <mat-label
                                            >{{ getRefFieldLabel('trip_ref2') }}
                                            <span class="dirty-indicator" *ngIf="highlightDirty"
                                                >*</span
                                            >
                                        </mat-label>
                                        <input matInput bdsShoutcase formControlName="trip_ref2" />
                                        <mat-error *ngIf="tripForm.controls.trip_ref2.errors">
                                            {{ getErrorText(tripForm.controls.trip_ref2) }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-form-field
                                        floatLabel="always"
                                        class="w-100"
                                        appearance="fill"
                                        [class.highlight-dirty]="highlightDirty"
                                        [class.bds-mat-dense-form-field]="dense"
                                    >
                                        <mat-label
                                            >{{ getRefFieldLabel('trip_ref3') }}
                                            <span class="dirty-indicator" *ngIf="highlightDirty"
                                                >*</span
                                            >
                                        </mat-label>
                                        <input matInput bdsShoutcase formControlName="trip_ref3" />
                                        <mat-error *ngIf="tripForm.controls.trip_ref3.errors">
                                            {{ getErrorText(tripForm.controls.trip_ref3) }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field
                                        floatLabel="always"
                                        class="w-100"
                                        appearance="fill"
                                        [class.highlight-dirty]="highlightDirty"
                                        [class.bds-mat-dense-form-field]="dense"
                                    >
                                        <mat-label
                                            >{{ getRefFieldLabel('trip_ref4') }}
                                            <span class="dirty-indicator" *ngIf="highlightDirty"
                                                >*</span
                                            >
                                        </mat-label>
                                        <input matInput bdsShoutcase formControlName="trip_ref4" />
                                        <mat-error *ngIf="tripForm.controls.trip_ref4.errors">
                                            {{ getErrorText(tripForm.controls.trip_ref4) }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-form-field
                                        floatLabel="always"
                                        class="w-100"
                                        appearance="fill"
                                        [class.highlight-dirty]="highlightDirty"
                                        [class.bds-mat-dense-form-field]="dense"
                                    >
                                        <mat-label
                                            >{{ getRefFieldLabel('trip_ref5') }}
                                            <span class="dirty-indicator" *ngIf="highlightDirty"
                                                >*</span
                                            >
                                        </mat-label>
                                        <mat-select formControlName="trip_ref5">
                                            <mat-option></mat-option>
                                            <mat-option
                                                *ngFor="let value of getRefFieldValues('trip_ref5')"
                                                [value]="value.fieldValue"
                                                >{{ value.fieldValue }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-form-field
                                        floatLabel="always"
                                        class="w-100"
                                        appearance="fill"
                                        [class.highlight-dirty]="highlightDirty"
                                        [class.bds-mat-dense-form-field]="dense"
                                    >
                                        <mat-label
                                            >{{ getRefFieldLabel('trip_ref6') }}
                                            <span class="dirty-indicator" *ngIf="highlightDirty"
                                                >*</span
                                            >
                                        </mat-label>
                                        <mat-select formControlName="trip_ref6">
                                            <mat-option></mat-option>
                                            <mat-option
                                                *ngFor="let value of getRefFieldValues('trip_ref6')"
                                                [value]="value.fieldValue"
                                                >{{ value.fieldValue }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div>
                                <rt-trip-additional-ref
                                    [refFieldDefs]="tripRefFieldDefs"
                                    [trip]="trip"
                                    (additionalRefsChange)="onAdditionalRefsChanged($event)"
                                >
                                </rt-trip-additional-ref>
                            </div>
                            <div class="row" *ngIf="!isNew || multipleTrips">
                                <div class="col-4">
                                    <mat-form-field
                                        floatLabel="always"
                                        class="w-100"
                                        appearance="fill"
                                        [class.highlight-dirty]="highlightDirty"
                                        [class.bds-mat-dense-form-field]="dense"
                                    >
                                        <mat-label
                                            >Record Creation Source
                                            <span class="dirty-indicator" *ngIf="highlightDirty"
                                                >*</span
                                            ></mat-label
                                        >
                                        <input matInput readonly formControlName="importSource" />
                                        <mat-hint>Read only</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-form-field
                                        floatLabel="always"
                                        class="w-100"
                                        appearance="fill"
                                        [class.highlight-dirty]="highlightDirty"
                                        [class.bds-mat-dense-form-field]="dense"
                                    >
                                        <mat-label
                                            >Record Creation Date
                                            <span class="dirty-indicator" *ngIf="highlightDirty"
                                                >*</span
                                            ></mat-label
                                        >
                                        <input matInput readonly [value]="recordProcDate" />
                                        <mat-hint>Read only</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="col-4"></div>
                            </div>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="CLM History" *ngIf="!multipleTrips">
                        <ng-template matTabContent>
                            <rt-clm-grid
                                [carInit]="trip.carInit"
                                [carNumber]="trip.carNo"
                                [tripId]="trip.ormId"
                                [shipmentDate]="trip.shipDatetime"
                                [showNavigateButton]="true"
                                navigateButtonText="Maintain CLMs"
                                (navigate)="navigateToSection('tripclmanchor')"
                            ></rt-clm-grid>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
                <h4>Notes</h4>
                <p *ngIf="tripForm.controls['routeCode'].value === 'LH'">
                    <i>This is a load and hold shipment.</i>
                </p>
                <p *ngIf="tripForm.controls['divertYorN'].value === 'Y'">
                    <i>This is a diversion.</i>
                </p>
                <p *ngIf="jeopardyStatus">
                    <i
                        >This shipment is jeopardized:
                        <span *ngFor="let s of jeopardyStatus; let last = last">
                            {{ s }}<span *ngIf="!last">, </span>
                        </span>
                    </i>
                </p>
                <p *ngIf="isHotlistedGeneral">
                    <i>This shipment is hotlisted.</i>
                </p>
                <p *ngIf="isHotlistedMechanical">
                    <i>This shipment is hotlisted due to a mechanical issue.</i>
                </p>
                <p *ngIf="isHotlistedLease">
                    <i>This shipment is hotlisted due to a leasing issue.</i>
                </p>
                <p *ngIf="isHotlistedTrouble">
                    <i>This shipment is flagged as in trouble.</i>
                </p>
                <p *ngIf="interfaceValidationErrorCount > 0">
                    <i>This shipment has validation errors.</i>
                </p>
                <p *ngIf="isHotlistedComment">
                    <i>{{ trip.comments }}</i>
                </p>

                <div class="row">
                    <div class="col-4"></div>
                    <div class="col-4"></div>
                    <div class="col-4"></div>
                </div>
            </div>
        </div>

        <!--<dx-date-box name="requestedDeliveryDateTime"
                         class="w-100 bds-dx-custom-material"
                         formControlName="requestedDeliveryDateTime"
                         [stylingMode]="dxMatStyle"
                         type="datetime"
                         dateSerializationFormat="yyyy-MM-dd HH:mm:ss"
                         label="Requested Delivery">
            </dx-date-box>

            <mat-form-field floatLabel="always" class="w-100" appearance="fill" [class.highlight-dirty]="highlightDirty" [class.bds-mat-dense-form-field]="dense">
              <mat-label>Current Location <span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label>
              <input matInput bdsShoutcase formControlName="locationCity" />
            </mat-form-field>

            <mat-form-field floatLabel="always" class="w-100" appearance="fill" [class.highlight-dirty]="highlightDirty" [class.bds-mat-dense-form-field]="dense">
              <mat-label>Sight Code <span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label>
              <mat-select formControlName="sightCode">
                <mat-option></mat-option>
                <mat-option *ngFor="let item of sightCodeLookup | async" [value]="item.code">{{item.code}} - {{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field floatLabel="always" class="w-100" appearance="fill" [class.highlight-dirty]="highlightDirty" [class.bds-mat-dense-form-field]="dense">
              <mat-label>CLM Date <span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label>
              <input matInput bdsShoutcase [matDatepicker]="clmDateTime" formControlName="clmDateTime">
              <mat-datepicker-toggle matSuffix [for]="clmDateTime"></mat-datepicker-toggle>
              <mat-datepicker #clmDateTime></mat-datepicker>
            </mat-form-field>

            <mat-form-field floatLabel="always" class="w-100" appearance="fill" [class.highlight-dirty]="highlightDirty" [class.bds-mat-dense-form-field]="dense">
              <mat-label>Load/Empty <span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label>
              <mat-select formControlName="loadEmpty">
                <mat-option></mat-option>
                <mat-option value="L">Load</mat-option>
                <mat-option value="E">Empty</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field floatLabel="always" class="w-100" appearance="fill" [class.highlight-dirty]="highlightDirty" [class.bds-mat-dense-form-field]="dense">
              <mat-label>Road <span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label>
              <input matInput bdsShoutcase formControlName="road" />
            </mat-form-field>

            <mat-form-field floatLabel="always" class="w-100" appearance="fill" [class.highlight-dirty]="highlightDirty" [class.bds-mat-dense-form-field]="dense">
              <mat-label>Supplier Shipment <span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label>
              <mat-select formControlName="supplierShipment">
                <mat-option></mat-option>
                <mat-option value="Y">Yes</mat-option>
                <mat-option value="N">No</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field floatLabel="always" class="w-100" appearance="fill" [class.highlight-dirty]="highlightDirty" [class.bds-mat-dense-form-field]="dense">
              <mat-label>Supplier Code <span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label>
              <input matInput bdsShoutcase formControlName="supplierCode" />
            </mat-form-field>

            <mat-form-field floatLabel="always" class="w-100" appearance="fill" [class.highlight-dirty]="highlightDirty" [class.bds-mat-dense-form-field]="dense">
              <mat-label>Supplier Name <span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label>
              <input matInput bdsShoutcase formControlName="supplierName" />
            </mat-form-field>-->

        <div class="row" style="display: none">
            <div class="col-12 col-sm-6">
                <div class="row">
                    <div class="col-1">
                        <!--<button mat-icon-button color="primary" (click)="editCustomerFacts()" class="text-uppercase">
                            <fa-icon [icon]="iconEdit"></fa-icon>
                        </button>-->
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>Extracted </mat-label>
                            <mat-select formControlName="detentionExtractYorN">
                                <mat-option></mat-option>
                                <mat-option value="Y">Yes</mat-option>
                                <mat-option value="N">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label
                                >Railroad Delivery Date
                                <span class="dirty-indicator" *ngIf="highlightDirty"
                                    >*</span
                                ></mat-label
                            >
                            <input
                                matInput
                                bdsShoutcase
                                formControlName="railRoadDeliveryDateTime"
                            />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="row">
                    <div class="col-12"></div>
                </div>
                <div class="row"></div>
                <div class="row">
                    <div class="col-12"></div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.highlight-dirty]="highlightDirty"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>ETA Origin </mat-label>
                            <input
                                matInput
                                bdsShoutcase
                                [matDatepicker]="originalEtaToOrigin"
                                formControlName="originalEtaToOrigin"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="originalEtaToOrigin"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #originalEtaToOrigin></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
</dx-validation-group>
