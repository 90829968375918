import { Injectable } from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { nameof } from '@bds/core';

export class CLMFilterModel {
    carInit?: string;
    carNo?: string;
    clmFromDate?: string;
    clmToDate?: string;
}

@Injectable({
    providedIn: 'root',
})
export class CLMFilterMetadata {
    constructor() {}

    getFormGroup(obj?): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});

        formGroup.addControl(nameof<CLMFilterModel>('carInit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<CLMFilterModel>('carNo'), new UntypedFormControl(''));

        formGroup.addControl(nameof<CLMFilterModel>('clmFromDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<CLMFilterModel>('clmToDate'), new UntypedFormControl(''));
        formGroup.setValidators([dateRangeValidator]);
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}

export const dateRangeValidator: ValidatorFn = (
    formGrp: UntypedFormGroup,
): ValidationErrors | null => {
    const fromCtrl = formGrp.get('clmFromDate');
    const toCtrl = formGrp.get('clmToDate');

    const rangeCondition: boolean = new Date(fromCtrl.value) > new Date(toCtrl.value);
    return rangeCondition ? { dateRangeInvalid: true } : null;
};
