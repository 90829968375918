import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { BdsSplcErpcService } from '@bds/data-access';
import { RtSplcErpc } from '@bds/railtrac-models';
import { DxSelectBoxComponent } from 'devextreme-angular/ui/select-box';
import DataSource from 'devextreme/data/data_source';
import { BdsMatFormField } from '../../abstract-components/bds-abstract-control-value.accessor';
import { dxMatStyle } from '../../helpers/dx-mat-style';
import { BdsSplcErpcSelectDialogComponent } from './bds-splc-erpc-select-dialog/bds-splc-erpc-select-dialog.component';

@Component({
    selector: 'bds-splc-erpc-select',
    templateUrl: './bds-splc-erpc-select.component.html',
    styleUrl: './bds-splc-erpc-select.component.css',
    providers: [{ provide: MatFormFieldControl, useExisting: BdsSplcErpcSelectComponent }],
})
export class BdsSplcErpcSelectComponent extends BdsMatFormField<string> implements OnInit {
    dataSource: DataSource;
    @Input() dense = true;
    @Input() displayValue = this.defaultDisplay;
    @ViewChild(DxSelectBoxComponent) dxSelectBox;
    @Input() enableSearchPopup = false;
    id: string;
    @Input() matFloatLabel = 'always';
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() pageSize = 10;
    @Input() initValue;
    @Input() title: string;

    popupDim = { minWidth: '250px' };
    searchButtonOptions = {
        type: 'default',
        icon: 'search',
        stylingMode: 'text',
        onClick: () => {
            this.openSearchDialog();
        },
    };
    @Input() searchDisplay = this.defaultSearchDisplay;
    @Output() selected = new EventEmitter<RtSplcErpc>();
    @Input() splcDisplayExpr: string[] = ['erpcCity', 'erpcState', 'splc', 'city', 'state'];
    @Input() valueExpr: 'splc' | undefined = 'splc';

    splcErpc: RtSplcErpc;

    constructor(
        private splcErpcService: BdsSplcErpcService,
        @Optional() @Self() public ngControl: NgControl,
        private dialog: MatDialog,
    ) {
        super(ngControl);
    }

    private _filter: [];

    get filter() {
        return this._filter;
    }

    get errors() {
        return this.ngControl?.errors?.length > 0;
    }

    get required() {
        return this.ngControl?.errors?.required;
    }

    @Input() set filter(value: []) {
        this._filter = value;
    }

    @Input() set label(plh: string) {
        super.placeholder = plh;
    }

    get style(): string {
        return dxMatStyle(this.matStyle);
    }

    defaultDisplay(data: RtSplcErpc) {
        return data ? `${data.splc}` : '';
    }

    defaultSearchDisplay(data: RtSplcErpc) {
        return data ? `${data?.erpcCity}, ${data?.erpcState} (${data?.splc ?? ''})` : '';
    }

    getDisplayValue(data: RtSplcErpc) {
        return this.displayValue(data);
    }

    ngOnInit(): void {
        this.dataSource = new DataSource({
            store: this.splcErpcService.getODataStore(),
            paginate: true,
            pageSize: 10,
            searchExpr: this.splcDisplayExpr,
            select: ['splc', 'erpcCity', 'erpcState', 'city', 'state', 'rule260'],
            sort: ['erpcCity', 'erpcState'],
            key: 'splc',
        });
    }

    private openSearchDialog() {
        const dialogRef = this.dialog.open(BdsSplcErpcSelectDialogComponent, {
            data: { filter: this.filter, title: this.title },
        });

        dialogRef.afterClosed().subscribe((data) => {
            if (data[0]) {
                let value = data[0];
                this.value = value[this.valueExpr];
            }
        });
    }
}
