<rt-report-parameters
    [controlNames]="controlNames"
    (getReport)="onGetReportClick($event)"
    (cancelReport)="onCancelClick()"
>
</rt-report-parameters>

<div *ngIf="displayReport">
    <div class="container-fluid">
        <bds-fastrac-design-display
            [gridId]="gridId"
            [fastracId]="fastracId"
            [showFastrac]="showFastrac"
            [showGrouping]="true"
            (showFastracChange)="onFastracVisibleChanged($event)"
            (fastracIdChange)="onFastracIdChanged($event)"
            (fastracDesignsChange)="onFastracDesignsChanged($event)"
            (applyFastrac)="onApplyFastrac($event)"
        >
        </bds-fastrac-design-display>
    </div>

    <div class="railroad-grid-container">
        <dx-data-grid
            bds-grid
            #bdsReportGrid
            id="bdsReportGrid"
            [dataSource]="gridSource"
            [(selectedRowKeys)]="selectedRowKeys"
            height="80vh"
            minHeight="200px"
            [gridId]="gridId"
            (bdsShowFastrac)="onShowFastrac()"
            (fastracIdChange)="onGridFastracIdChanged($event)"
            [bdsFastracDesignList]="fastracList"
            [bdsFastracVisible]="showFastrac"
            [bdsUseFastrac]="true"
            [fastracDesign]="currentFastrac"
            [fastracId]="fastracId"
            [bdsCustomizeColumns]="customizeColumns"
            [(bdsGridMode)]="gridMode"
            [bdsAllowAddRecord]="false"
            [bdsAllowDeleteRecords]="false"
            [bdsAllowGrouping]="true"
            [bdsAutoExpandAll]="true"
            [bdsUseZeroPadDates]="true"
        >
            <dxo-summary>
                <dxi-total-item
                    displayFormat="Total:"
                    showInColumn="prev_trip_close_date_time"
                ></dxi-total-item>
                <dxi-total-item
                    displayFormat="Avg:"
                    showInColumn="prev_trip_close_date_time"
                ></dxi-total-item>
                <dxi-total-item
                    displayFormat="Min:"
                    showInColumn="prev_trip_close_date_time"
                ></dxi-total-item>
                <dxi-total-item
                    displayFormat="Max:"
                    showInColumn="prev_trip_close_date_time"
                ></dxi-total-item>
                <dxi-total-item
                    column="idle_days"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInColumn="idle_days"
                ></dxi-total-item>
                <dxi-total-item
                    column="idle_days"
                    summaryType="avg"
                    displayFormat="{0}"
                    showInColumn="idle_days"
                    valueFormat="###0.##"
                ></dxi-total-item>
                <dxi-total-item
                    column="idle_days"
                    summaryType="min"
                    displayFormat="{0}"
                    showInColumn="idle_days"
                ></dxi-total-item>
                <dxi-total-item
                    column="idle_days"
                    summaryType="max"
                    displayFormat="{0}"
                    showInColumn="idle_days"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_enroute"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInColumn="days_enroute"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_enroute"
                    summaryType="avg"
                    displayFormat="{0}"
                    showInColumn="days_enroute"
                    valueFormat="###0.##"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_enroute"
                    summaryType="min"
                    displayFormat="{0}"
                    showInColumn="days_enroute"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_enroute"
                    summaryType="max"
                    displayFormat="{0}"
                    showInColumn="days_enroute"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_at_cust"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInColumn="days_at_cust"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_at_cust"
                    summaryType="avg"
                    displayFormat="{0}"
                    showInColumn="days_at_cust"
                    valueFormat="###0.##"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_at_cust"
                    summaryType="min"
                    displayFormat="{0}"
                    showInColumn="days_at_cust"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_at_cust"
                    summaryType="max"
                    displayFormat="{0}"
                    showInColumn="days_at_cust"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_returned"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInColumn="days_returned"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_returned"
                    summaryType="avg"
                    displayFormat="{0}"
                    showInColumn="days_returned"
                    valueFormat="###0.##"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_returned"
                    summaryType="min"
                    displayFormat="{0}"
                    showInColumn="days_returned"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_returned"
                    summaryType="max"
                    displayFormat="{0}"
                    showInColumn="days_returned"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_trip"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInColumn="days_trip"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_trip"
                    summaryType="avg"
                    displayFormat="{0}"
                    showInColumn="days_trip"
                    valueFormat="###0.##"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_trip"
                    summaryType="min"
                    displayFormat="{0}"
                    showInColumn="days_trip"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_trip"
                    summaryType="max"
                    displayFormat="{0}"
                    showInColumn="days_trip"
                ></dxi-total-item>
            </dxo-summary>
        </dx-data-grid>
    </div>
</div>
