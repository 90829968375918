import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ClmFunctionsService {
    constructor() {}

    private reapplyClmSource = new Subject<boolean>();
    private rtClmInitSource = new Subject<boolean>();

    reapplyClmChangeEmitted$ = this.reapplyClmSource.asObservable();
    rtClmInitChangeEmitted$ = this.rtClmInitSource.asObservable();

    emitReapplyClmChange(data: boolean): void {
        this.reapplyClmSource.next(data);
    }

    emitRtClmInitChange(data: boolean): void {
        this.rtClmInitSource.next(data);
    }
}
