import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RtTrip } from '@bds/railtrac-models';
import { CLMSelectDialogModel } from '../models/clm-select-dialog.model';
import { RtClm } from '@bds/reference-models';

@Component({
    selector: 'rt-clm-select-dialog',
    templateUrl: './clm-select-dialog.component.html',
    styleUrls: ['./clm-select-dialog.component.scss'],
})
export class CLMSelectDialogComponent {
    trip = new RtTrip();
    selectedCLM: RtClm;

    constructor(
        public dialogRef: MatDialogRef<CLMSelectDialogComponent, RtClm>,
        @Inject(MAT_DIALOG_DATA) public data: CLMSelectDialogModel,
    ) {
        this.trip = data.trip;
    }

    onCancelClick(): void {
        this.dialogRef.close(null);
    }

    onCLMSelect(clm: RtClm): void {
        this.selectedCLM = clm;
    }

    outputSelectedCLM(): void {
        this.dialogRef.close(this.selectedCLM);
    }
}
