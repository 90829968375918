import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

const DATE_DISPLAY_FORMAT = 'MM/DD/YYYY HH:mm';

export class CustomValidators {
    static dateMinimum(minDate: Date): ValidatorFn {
        const retFunc = (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }

            const controlDate = moment(control.value);

            if (!controlDate.isValid()) {
                return null;
            }
            const zoneLessDateString = moment(minDate).utc().format('YYYY-MM-DDTHH:mm:ss');
            const validationDate = moment(zoneLessDateString);

            return controlDate.isSameOrAfter(validationDate)
                ? null
                : {
                      'date-minimum': {
                          'date-minimum': validationDate.format(DATE_DISPLAY_FORMAT),
                          actual: controlDate.format(DATE_DISPLAY_FORMAT),
                      },
                  };
        };
        return retFunc;
    }
}
