import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthorizeService } from '@bds/auth';
import { BdsDialogConfirmComponent, BdsDialogConfirmModel } from '@bds/components';
import {
    ClmSightCodeService,
    CommodityShippedService,
    RtCarHotListService,
} from '@bds/data-access';
import { Equipment, EquipmentService } from '@bds/equipment';
import {
    RtCarHotList,
    RtClm,
    RtClmSightCode,
    RtClmView,
    RtCommodityShipped,
    RtTrip,
    RtTripRefFieldDef,
} from '@bds/railtrac-models';
import { faList, faPlus } from '@fortawesome/pro-light-svg-icons';
import {
    faAddressBook,
    faArrowAltLeft,
    faCodeBranch,
    faCommentAltLines,
    faEllipsisVAlt,
    faExternalLink,
    faFlaskPotion,
    faHandHoldingUsd,
    faInfo,
    faRoute,
    faSave,
    faWrench,
} from '@fortawesome/pro-solid-svg-icons';
import { faBars, faSpinner, faRepeat } from '@fortawesome/pro-regular-svg-icons';
import {
    faCommentAltPlus,
    faObjectGroup,
    faPencilAlt,
    faTrashAlt,
} from '@fortawesome/pro-duotone-svg-icons';
import { faClmHistory, faTrain } from '@bds/fa-svg-icons';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of, Subject, forkJoin, firstValueFrom } from 'rxjs';
import { ScrollSpyService } from '@bds/helpers';
import { CarIdTransformService } from '@bds/railtrac-internal-services';
import { CommunicationMessage, CommunicationService } from '@bds/core';
import { catchError, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BdsHotListDetailsDialogComponent, BdsHotListDialogData } from '@bds/hotlist';
import { TripCarHotListDto } from '@bds/railtrac-models';
import { PopoutEvent } from '../popout-event';
import { RailtracClmService } from '../railtrac-clm/railtrac-clm.service';
import { RailtracTripService } from './railtrac-trip.service';
import {
    ClmFunctionsService,
    RailtracTripDetailsComponent,
    RtTripRefFieldDefService,
} from '@bds/railtrac';
import { CreateTripService } from './shared-service/create-trip.service';
import {
    RtTripCommodityShippedDialogComponent,
    RtTripCommodityShippedDialogOptions,
} from './rt-trip-commodity-shipped/rt-trip-commodity-shipped-dialog/rt-trip-commodity-shipped-dialog.component';
import { EquipmentApplicationPathProvider } from '@bds/equipment-pathing';
import { RtTripCommentsComponent } from './rt-trip-comments/rt-trip-comments.component';
import { RtClmComponent } from '../rt-clm/rt-clm.component';

export class TripIcons {
    add = faPlus;
    cancelNew = faArrowAltLeft;
    comments = faCommentAltLines;
    commodity = faFlaskPotion;
    customer = faAddressBook;
    detail = faInfo;
    detention = faHandHoldingUsd;
    diversion = faCodeBranch;
    group = faObjectGroup;
    history = faClmHistory;
    list = faList;
    menu = faBars;
    popout = faExternalLink;
    profile = faWrench;
    route = faRoute;
    save = faSave;
    saveAndCreateNew = faRepeat;
    spinner = faSpinner;
    train = faTrain;
    verticalEllipsis = faEllipsisVAlt;
}

export type TripPopoutComponent =
    | 'trip'
    | 'trip-assignment'
    | 'trip-clm'
    | 'trip-comments'
    | 'trip-details'
    | 'trip-detention'
    | 'trip-diversion'
    | 'trip-hotList'
    | 'trip-commodity'
    | 'trip-route'
    | 'trip-customer'
    | 'trip-criteria'
    | 'equipment-details';

@Component({
    selector: 'rt-trip',
    templateUrl: './railtrac-trip.component.html',
    styleUrls: ['./railtrac-trip.component.scss'],
    //providers: [RtCommodityShippedForm],
})
export class RailtracTripComponent implements OnInit, OnChanges, OnDestroy {
    alteredTrip: RtTrip;
    alteredTripRefs: RtTrip;
    carId = '';
    clms: RtClm[] = [];
    currentClm: RtClmView;
    commoditiesShipped: RtCommodityShipped[] = [];
    compartmentCommodities: RtCommodityShipped[] = [];
    compartmentCommoditiesModified: RtCommodityShipped[] = [];
    disabledClmFields: Array<string> | string = [`sightCode`];
    equipment: Equipment = <Equipment>{};
    errorMessage = '';
    hotLists: TripCarHotListDto[];
    icons = new TripIcons();
    isDirty = false;
    isNew = false;
    isSaving = false;
    isTripValid = false;
    sightCodes: Observable<RtClmSightCode[]>;
    unassignedCommodities: RtCommodityShipped[] = [];
    userName: string;
    routeReadOnly = true;
    isReapplyClms = false;

    @Input() allowPopout: boolean;
    @Output() cancel: EventEmitter<any> = new EventEmitter();
    @Output() popout: EventEmitter<PopoutEvent<number>> = new EventEmitter();
    @Input() trip: RtTrip;
    @Input() tripId: number;
    @Input() tripRefFieldDefs$: Observable<RtTripRefFieldDef[]>;
    @ViewChild('tripDetails') tripDetails: RailtracTripDetailsComponent;
    @ViewChild('tripclmpanel') tripclmpanel: MatExpansionPanel;
    @ViewChild('tripcommentspanel') tripcommentspanel: MatExpansionPanel;
    @ViewChild('tripcustpanel') tripcustpanel: MatExpansionPanel;
    @ViewChild('tripdiversionspanel') tripdiversionspanel: MatExpansionPanel;
    @ViewChild('tripequippanel') tripequippanel: MatExpansionPanel;
    @ViewChild('triproutepanel') triproutepanel: MatExpansionPanel;
    @ViewChild('hotListAnchor') hotListAnchor: MatExpansionPanel;

    @ViewChild(RtTripCommentsComponent)
    commentsComponent: RtTripCommentsComponent;
    @ViewChild(RtClmComponent) clmComponent: RtClmComponent;
    private unsub$ = new Subject<void>();

    iconEdit = faPencilAlt;
    iconAdd = faCommentAltPlus;
    iconDelete = faTrashAlt;

    constructor(
        private tripService: RailtracTripService,
        private equipmentService: EquipmentService,
        private _snackbar: MatSnackBar,
        public scrollSpyService: ScrollSpyService,
        private carIdTransformService: CarIdTransformService,
        private clmService: RailtracClmService,
        private authService: AuthorizeService,
        private router: Router,
        private clmSightCodeService: ClmSightCodeService,
        private hotListService: RtCarHotListService,
        private dialogService: MatDialog,
        public communicationService: CommunicationService,
        public createTripService: CreateTripService,
        public dialog: MatDialog,
        private commodityService: CommodityShippedService,
        private _createTripService: CreateTripService,
        private refFieldDefService: RtTripRefFieldDefService,
        public equipmentAppPathProvider: EquipmentApplicationPathProvider,
        private clmFunctionsService: ClmFunctionsService,
    ) {
        this._createTripService.refreshTripGridChangeEmitted$.subscribe((x) => {
            this.reloadTrip();
        });

        //Subscribe to rt-clm component's initialized event
        this.clmFunctionsService.rtClmInitChangeEmitted$.subscribe((x) => {
            if (x && this.isReapplyClms) {
                //emit reapply clms event if flag is set
                this.clmFunctionsService.emitReapplyClmChange(true);
            }
        });

        if (!this.tripRefFieldDefs$) {
            this.tripRefFieldDefs$ = this.refFieldDefService.getReferenceFieldDefs();
        }
    }

    /**
     * set selected section
     * @param section string
     */
    setCurrentSection(section: string): void {
        this.scrollSpyService.changeSection(section);
    }

    create(repeat: boolean): void {
        this.alteredTrip.importSource = this.userName;
        this.alteredTrip.procDateTime = new Date();
        this.alteredTrip.carCondition = 'G';

        this.tripService
            .create(this.alteredTrip)
            .pipe(
                tap((s) => {
                    this._snackbar.open('Trip created!', 'Ok', { duration: 3000 });
                    this.isSaving = false;
                    if (repeat) {
                        this.trip.carNo = '';
                        this.trip.carInit = '';
                    } else {
                        void this.router.navigate(['trips/detail', s.ormId], {
                            queryParamsHandling: 'preserve',
                        });
                    }
                }),
                catchError((error) => {
                    this.errorMessage = error.toString();
                    this._snackbar.open('Error saving changes!', 'Ok', {
                        duration: 3000,
                        panelClass: 'error-snack',
                    });
                    this.isSaving = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    getHotLists(): Observable<RtCarHotList[]> {
        return this.hotListService.getShipmentHotLists(this.trip?.carInit, this.trip?.carNo).pipe(
            tap((c) => {
                this.hotLists = c.map((response) => {
                    return { ...{ tripId: this.trip.ormId }, ...response } as TripCarHotListDto;
                });
            }),
        );
    }

    async ngOnChanges(changes: SimpleChanges) {
        const tripIdChange = changes['tripId'];
        if (!!tripIdChange) {
            this.errorMessage = '';
            if (tripIdChange.currentValue === 0) {
                this.trip = this.trip || new RtTrip();
                this.commoditiesShipped = [];
                this.isNew = true;
                this.trip.ormId = 0;
                this.clms = [];
                //this.trip.shipmentType = '01';
            } else {
                this.reloadTrip();
            }
        } else {
            void this.getHotListData();
            void this.getCommodityShippedData();
        }

        const tripChange = changes['trip'];
        if (!!tripChange && tripChange?.currentValue?.ormId) {
            this.errorMessage = '';
            const c = await firstValueFrom(
                this.clmService.getClmsForTrip(tripChange.currentValue.ormId),
            );
            this.clms = c ?? [];
        }
    }

    ngOnDestroy(): void {
        this.unsub$.next();
        this.unsub$.complete();
    }

    ngOnInit() {
        this.authService
            .getUser()
            .pipe(
                takeUntil(this.unsub$),
                map((u) => u && u['preferred_username']),
                tap((username) => {
                    this.userName = username;
                }),
            )
            .subscribe();

        this.sightCodes = this.clmSightCodeService.getAll();
    }

    onEquipmentChange(ormId: number | null): void {
        if (ormId) {
            this.equipmentService.read(ormId).subscribe((eq) => {
                this.equipment = eq;
            });
        } else {
            this.equipment = <Equipment>{};
        }
    }

    // commShipChange($event: RtCommodityShipped, idx: number) {
    //     this.compartmentCommoditiesModified[idx] = $event;
    // }

    getTrip(communicateOutside = false): Observable<RtTrip> {
        return this.tripService.read(this.tripId).pipe(
            tap((trip) => {
                this.trip = trip;

                this.carId = this.carIdTransformService.formatCarId(trip.carInit, trip.carNo);
                this.isNew = false;

                if (communicateOutside) {
                    const message: CommunicationMessage = {
                        subscriberName: 'TripScreen',
                        action: 'update',
                        data: trip,
                    };
                    this.communicationService.sendData(message);
                }
            }),
        );
    }

    /**
     * Used in conjunction with railtrac-trip-details->@Output() formValidChange.
     * Keeps parent component in sync with the validity of a trip.
     * @param isValid Is the child trip valid.
     */
    onFormValidChange(isValid: boolean): void {
        this.isTripValid = isValid;
    }

    async reloadClms(): Promise<void> {
        //: CLM firing off reload means recalc was called and the trip should be reloaded.
        await firstValueFrom(this.getClms());
        this.reloadTrip(false);
    }

    reloadCommodityShipped(verifyCompartNumbers = false): void {
        this.getCommodityShipped().subscribe({
            complete: () => {
                if (verifyCompartNumbers) {
                    this.verifyCompartNumbers();
                }
            },
        });
    }

    reloadTrip(communicateOutside = false): void {
        this.tripId = this.tripId ?? this.trip.ormId;
        this.getTrip(communicateOutside)
            .pipe(
                switchMap(() =>
                    forkJoin([
                        this.getCurrentClm(),
                        this.getClms(),
                        this.getEquipment(),
                        this.getCommodityShipped(),
                        this.getHotLists(),
                    ]),
                ),
                finalize(() => {
                    //this.processEquipmentCompartments();
                }),
            )
            .subscribe();
    }

    getClms(): Observable<RtClm[]> {
        return this.clmService.getClmsForTrip(this.tripId ?? this.trip.ormId).pipe(
            tap((c) => {
                this.clms = c;
            }),
        );
    }

    private getCurrentClm() {
        return this.clmService.getCurrentClm(this.trip.carInit, this.trip.carNo).pipe(
            tap((c) => {
                this.currentClm = c;
            }),
        );
    }

    getEquipment(): Observable<Equipment> {
        return this.equipmentService.read(this.trip.tcmEquipmentOrmId).pipe(
            tap((eq) => {
                this.equipment = eq;
            }),
        );
    }

    getCommodityShipped(): Observable<RtCommodityShipped[]> {
        return this.tripService.getCommoditiesShipped(this.tripId ?? this.trip.ormId).pipe(
            tap((cs) => {
                this.commoditiesShipped = cs;
            }),
        );
    }

    onTripValuesChange($event: RtTrip): void {
        this.alteredTripRefs = $event;
    }

    setTripRefs(alteredTrip: RtTrip): RtTrip {
        Object.keys(this.alteredTripRefs)
            .filter((key) => /trip_ref.*/.test(key))
            .forEach((key) => {
                alteredTrip[key] = this.alteredTripRefs[key];
            });

        return alteredTrip;
    }

    //saveChanges(alteredTrip: RtTrip, repeat: boolean = false) {
    saveChanges(repeat = false): void {
        this.isSaving = true;
        this.errorMessage = '';
        this.alteredTrip = this.tripDetails.tripForm.getRawValue();
        this.alteredTrip.userId = this.userName;
        this.alteredTrip.commoditiesShipped = this.commoditiesShipped;
        this.alteredTrip = this.setTripRefs(this.alteredTrip);

        //this.alteredTrip.commoditiesShipped = this.compartmentCommoditiesModified;
        if (this.isNew || this.alteredTrip.ormId === 0) {
            this.create(repeat);
        } else {
            this.update();
        }
    }

    update(): void {
        this.tripService
            .update(this.tripId ?? this.alteredTrip.ormId, this.alteredTrip)
            .pipe(
                tap(() => {
                    this._snackbar.open('Changes saved!', 'Ok', { duration: 3000 });
                    this.isSaving = false;
                }),
                catchError((error) => {
                    this.errorMessage = error.toString();
                    this._snackbar.open('Error saving changes!', 'Ok', {
                        duration: 3000,
                        panelClass: 'error-snack',
                    });
                    this.isSaving = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    saveAndCreateNew(): void {
        this.saveChanges(true);
    }

    onDiversionCreated(): void {
        this.reloadTrip(true);
    }

    processEquipmentCompartments(): void {
        const eq = this.equipment || <Equipment>{};
        const comm = this.commoditiesShipped;
        const currCompartNums = comm.map((m) => m.commodityCompart);
        const eqCompartNums = [...new Set([1, ...eq.equipmentCompart.map((m) => m.compartNo)])];
        const missingComms = this.difference(currCompartNums, eqCompartNums);

        const compartmentCommodities = eqCompartNums.map((cno) => {
            const commFound = comm.find((f) => f.commodityCompart === cno);
            return (
                commFound ||
                new RtCommodityShipped(
                    eq.equipmentInit,
                    eq.equipmentNo,
                    cno,
                    this.trip.shipDatetime,
                )
            );
        });

        const unassignedCommodities = missingComms.map((cno) => {
            return comm.find((f) => f.commodityCompart === cno);
        });

        this.compartmentCommodities = compartmentCommodities;
        this.compartmentCommoditiesModified = [...compartmentCommodities];
        this.unassignedCommodities = unassignedCommodities;
    }

    scroll(container: HTMLElement, component?: MatExpansionPanel): void {
        container.scrollIntoView({ behavior: 'smooth' } as ScrollIntoViewOptions);
        if (component instanceof MatExpansionPanel) {
            component.open();
        }
    }

    private difference<T>(a: T[], b: T[]): T[] {
        const setB = new Set(b);
        return [...new Set(a)].filter((x) => !setB.has(x));
    }

    reloadHotList(): Observable<[RtCarHotList[], RtTrip]> {
        return forkJoin([this.getHotLists(), this.getTrip(true)]);
    }

    addHotList(): void {
        const model = [
            <TripCarHotListDto>{
                tripId: this.tripId,
                carNo: this.trip.carNo,
                carInit: this.trip.carInit,
                hotListType: 'GNRL',
                hotListCategory: 'GNRL',
            },
        ];
        const dialogRef = this.dialogService.open(BdsHotListDetailsDialogComponent, {
            data: <BdsHotListDialogData>{
                action: 'add',
                title: 'Add Hot Lists',
                hotLists: model,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(switchMap(() => this.reloadHotList()))
            .subscribe();
    }

    confirmAddHotList(): void {
        if (this.trip.tripStatus === 'C') {
            const confirmInfo: BdsDialogConfirmModel = {
                content: `The trip selected is Closed. Are you sure you would like to continue?`,
                actionText: 'Yes',
                dismissiveText: 'No',
                defaultToYes: false,
            };
            const dialogRef = this.dialogService.open(BdsDialogConfirmComponent, {
                width: '430px',
                data: confirmInfo,
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.addHotList();
                }
            });
        } else {
            this.addHotList();
        }
    }

    onResolveTripOptions(e: string): void {
        switch (e) {
            case 'newTrip':
                this.createTripService.emitCreateTripChange('');
                break;
            case 'addComment':
                this.commentsComponent.onSaved('Comment Saved');
                break;
            case 'addDestinationCriteria':
                this.createTripService.emitRefreshTripGridChange(true);
                break;
            case 'reapplyClms':
                //navigate to CLM sectionqt
                this.tripclmpanel.open();
                this.setCurrentSection('tripclmanchor');

                if (this.clmComponent) {
                    //component initialized
                    this.clmFunctionsService.emitReapplyClmChange(true);
                } else {
                    //component not initialized - set flag and wait for component to load
                    this.isReapplyClms = true;
                }
                break;
            default:
                break;
        }
    }

    //#region Commodity Shipped
    onAddProductClick(): void {
        const product = new RtCommodityShipped();
        product.carInit = this.trip.carInit;
        product.carNo = this.trip.carNo;
        product.rtTripOrmId = this.trip.ormId;
        product.updateDateTime = new Date();
        product.shipDate = this.trip.shipDatetime;
        product.userId = this.userName;
        if (this.commoditiesShipped) {
            product.commodityCompart = this.commoditiesShipped.length + 1;
        } else {
            product.commodityCompart = 1;
        }

        const y: RtTripCommodityShippedDialogOptions = {
            product: product,
            productOp: 'add',
        };

        const dialogRef = this.dialog.open(RtTripCommodityShippedDialogComponent, {
            width: '800px',
            data: y,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.commodityService
                    .add(result)
                    .pipe(take(1))
                    .subscribe(
                        () => {
                            this.reloadCommodityShipped();
                            this._snackbar.open('Product Saved.', 'Ok');
                        },
                        () => {
                            this._snackbar.open('Error during save.', 'Ok');
                        },
                    );
            }
        });
    }

    onDeleteProductClick(ormId: number): void {
        this.commodityService
            .delete(ormId)
            .pipe(take(1))
            .subscribe(
                () => {
                    this._snackbar.open('Product deleted.', 'Ok');
                    this.reloadCommodityShipped(true);
                    //this.verifyCompartNumbers();
                },
                () => {
                    this._snackbar.open('Error during delete.', 'Ok');
                },
            );
    }

    verifyCompartNumbers(): void {
        let idx = 1;
        this.commoditiesShipped.forEach((child) => {
            if (child.commodityCompart == idx) {
                idx++;
            } else {
                child.commodityCompart = idx;
                this.commoditiesShipped[idx - 1] = child;
                this.commodityService
                    .edit(child)
                    .pipe(take(1))
                    .subscribe(() => {
                        this.reloadCommodityShipped();
                    });
            }
        });
    }

    onEditProductClick(compart: RtCommodityShipped): void {
        compart.updateDateTime = new Date();
        compart.userId = this.userName;
        const y: RtTripCommodityShippedDialogOptions = {
            product: compart,
            productOp: 'edit',
        };

        const dialogRef = this.dialog.open(RtTripCommodityShippedDialogComponent, {
            width: '800px',
            data: y,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.commodityService
                    .edit(result)
                    .pipe(take(1))
                    .subscribe(
                        () => {
                            this._snackbar.open('Product Saved.', 'Ok');
                            this.reloadCommodityShipped();
                        },
                        () => {
                            this._snackbar.open('Error during save.', 'Ok');
                        },
                    );
            }
        });
    }

    async getHotListData(): Promise<void> {
        if (!this.trip) {
            return;
        }
        const result = await firstValueFrom(
            this.hotListService.getShipmentHotLists(this.trip?.carInit, this.trip?.carNo),
        );

        if (result) {
            this.hotLists = [];
            result.forEach((data) => {
                this.hotLists.push({
                    ...data,
                    tripId: this.trip.ormId,
                } as TripCarHotListDto);
            });
        }
    }

    async getCommodityShippedData(): Promise<void> {
        if (!this.trip) {
            return;
        }

        const result = await firstValueFrom(
            this.tripService.getCommoditiesShipped(this.trip?.ormId),
        );

        this.commoditiesShipped = [];
        if (result) {
            this.commoditiesShipped = result;
        }
    }

    //#endregion Commodity Shipped
}
