import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RtSidenavComponent } from './rt-sidenav.component';
import { BdsUiSidebarModule } from '@bds/ui';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { BdsApplicationsModule } from '@bds/applications';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';

import { DefaultFastracQueryParamPipe } from '@bds/railtrac-internal-services';

@NgModule({
    declarations: [RtSidenavComponent],
    imports: [
        CommonModule,
        BdsUiSidebarModule,
        MatButtonModule,
        MatDividerModule,
        MatExpansionModule,
        MatMenuModule,
        MatRippleModule,
        FontAwesomeModule,
        BdsApplicationsModule,
        RouterModule,
        DefaultFastracQueryParamPipe,
    ],
    exports: [RtSidenavComponent],
})
export class RtSidenavModule {}
