import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'CalculateVariance' })
export class CalculateVariance implements PipeTransform {
    transform(rowData: any): number {
        var date1 = new Date(rowData.orig_eda_eta_cust);
        var date2 = new Date(rowData.rqst_dlv_date_time);
        var diff = Math.abs(date1.getTime() - date2.getTime());
        var diffDays = Math.ceil(diff / (1000 * 3600 * 24));

        return diffDays;
    }
}
