<div class="sticky-top mat-elevation-z6">
    <mat-toolbar class="trip-header-bar bds-toolbar-blue">
        <mat-toolbar-row>
            <span>
                <fa-icon [icon]="iconTrain" [fixedWidth]="true" size="lg"></fa-icon>
                Edit {{ tripIds.length }} Trips <span>for Mass Update</span>
                <!-- {{trip.carInit}} {{trip.carNo}} <span class="small">{{trip.shipDatetime | date: 'MM/dd/yyyy HH:mm:ss'}}</span> -->
                <span *ngIf="isDirty">*</span>
                <span *ngIf="isSaving">
                    <small>
                        (saving...)
                        <fa-icon [icon]="iconSpinner" [spin]="true"></fa-icon>
                    </small>
                </span>
            </span>
            <span class="fill-remaining-space"></span>
            <span>
                <button
                    mat-stroked-button
                    (click)="saveChanges()"
                    [disabled]="!isDirty || isSaving || isReadOnly"
                >
                    <fa-icon [icon]="iconSave"></fa-icon> Update {{ tripIds.length }} Trips
                </button>
                <button mat-icon-button [matMenuTriggerFor]="tripOptionsMenu">
                    <fa-icon [icon]="iconVerticalEllipsis"></fa-icon>
                </button>
                <button mat-icon-button (click)="tripSidenav.toggle()">
                    <fa-icon [icon]="iconMenu"></fa-icon>
                </button>
            </span>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="container-fluid jump-nav">
        <mat-chip-listbox>
            <mat-chip-option
                bdsScrollSpyLink="bdsTripGrid"
                color="accent"
                [selectable]="false"
                [highlighted]="(scrollSpyService.currentSection | async) === 'bdsTripGrid'"
                >Shipment Grid
            </mat-chip-option>
            <mat-chip-option
                bdsScrollSpyLink="tripshipmentdetailsanchor"
                color="accent"
                [selectable]="false"
                [highlighted]="
                    (scrollSpyService.currentSection | async) === 'tripshipmentdetailsanchor'
                "
                >Shipment Details
            </mat-chip-option>
            <mat-chip-option
                bdsScrollSpyLink="tripproductshippedanchor"
                color="accent"
                [selectable]="false"
                [highlighted]="
                    (scrollSpyService.currentSection | async) === 'tripproductshippedanchor'
                "
            >
                Products Shipped
            </mat-chip-option>
            <mat-chip-option
                bdsScrollSpyLink="tripdiversionanchor"
                (click)="tripdiversionpanel.open()"
                color="accent"
                [selectable]="false"
                [highlighted]="(scrollSpyService.currentSection | async) === 'tripdiversionanchor'"
                >Diversions
            </mat-chip-option>
            <mat-chip-option
                bdsScrollSpyLink="tripequipanchor"
                (click)="tripequippanel.open()"
                color="accent"
                [selectable]="false"
                [highlighted]="(scrollSpyService.currentSection | async) === 'tripequipanchor'"
                >Reporting Categories
            </mat-chip-option>
            <mat-chip-option
                bdsScrollSpyLink="tripcommentsanchor"
                (click)="tripcommentsPanel.open()"
                color="accent"
                [selectable]="false"
                [highlighted]="(scrollSpyService.currentSection | async) === 'tripcommentsanchor'"
                >Comments
            </mat-chip-option>
            <mat-chip-option
                bdsScrollSpyLink="hotListAnchor"
                (click)="hotListPanel.open()"
                color="accent"
                [selectable]="false"
                [highlighted]="(scrollSpyService.currentSection | async) === 'hotListAnchor'"
                >Hot Lists
            </mat-chip-option>
        </mat-chip-listbox>
    </div>
</div>
<mat-sidenav-container>
    <mat-sidenav
        mode="side"
        position="end"
        class="mat-elevation-z4 pt-3 side-nav"
        #tripSidenav
        [opened]="true"
    >
        <div class="side-nav-class" *ngFor="let trip of selectedTrips">
            <span (click)="showTrip(trip.id)">
                <ng-container [ngSwitch]="trip.state">
                    <fa-icon
                        [icon]="iconStatusSaved"
                        [fixedWidth]="true"
                        *ngSwitchCase="'saved'"
                        class="text-success"
                    ></fa-icon>
                    <fa-icon
                        [icon]="iconStatusSaving"
                        [fixedWidth]="true"
                        *ngSwitchCase="'saving'"
                        class="text-primary"
                        [spin]="true"
                    ></fa-icon>
                    <fa-icon
                        [icon]="iconStatusFailed"
                        [fixedWidth]="true"
                        *ngSwitchCase="'failed'"
                        class="text-danger"
                    ></fa-icon>
                    <fa-icon
                        [icon]="iconStatusNone"
                        [fixedWidth]="true"
                        *ngSwitchDefault
                        class="text-muted"
                        style="opacity: 0.1"
                    ></fa-icon>
                </ng-container>
                {{ trip.key.carInit }}{{ trip.key.carNo }}
            </span>
            <div *ngIf="trip.errors && trip.errors.length">
                <div class="error-warning" *ngFor="let error of trip.errors">
                    {{ error.errorMessage }}
                </div>
            </div>
        </div>
        <!-- <mat-nav-list dense> -->
        <!-- <mat-list-item *ngFor="let tripKey of selectedTripKeys">
                <button mat-button (click)="showTrip(tripKey.ormId)">{{ tripKey.carInit }}{{ tripKey.carNo }} {{ tripKey.shipDatetime | date:'yyyy-MM-dd HH:mm:ss' }}</button>
            </mat-list-item> -->
        <!-- <mat-list-item *ngFor="let trip of selectedTrips">
                <span matListItemTitle (click)="showTrip(trip.id)">
                    <ng-container [ngSwitch]="trip.state">
                        <fa-icon
                            [icon]="iconStatusSaved"
                            [fixedWidth]="true"
                            *ngSwitchCase="'saved'"
                            class="text-success"
                        ></fa-icon>
                        <fa-icon
                            [icon]="iconStatusSaving"
                            [fixedWidth]="true"
                            *ngSwitchCase="'saving'"
                            class="text-primary"
                            [spin]="true"
                        ></fa-icon>
                        <fa-icon
                            [icon]="iconStatusFailed"
                            [fixedWidth]="true"
                            *ngSwitchCase="'failed'"
                            class="text-danger"
                        ></fa-icon>
                        <fa-icon
                            [icon]="iconStatusNone"
                            [fixedWidth]="true"
                            *ngSwitchDefault
                            class="text-muted"
                            style="opacity: 0.1"
                        ></fa-icon>
                    </ng-container>
                    {{ trip.key.carInit }}{{ trip.key.carNo }}
                </span>
            </mat-list-item>
        </mat-nav-list> -->
    </mat-sidenav>
    <div class="pt-3">
        <!-- Trip Details-->
        <div bdsScrollSpySection="tripdetailsanchor" [scrollMargin]="116" #tripdetailsanchor>
            <div *ngIf="!combinedTrip">Loading...</div>
            <rt-trip-details
                *ngIf="!!combinedTrip"
                [trip]="combinedTrip"
                [tripRefFieldDefs]="tripRefFieldDefs$ | async"
                [multipleTrips]="true"
                [(dirty)]="isDirty"
                (dirtyValues)="getDirtyValues($event)"
                [highlightDirty]="true"
                [allowPopout]="allowPopout"
                (onPopout)="popoutChild($event)"
                (addComment)="addComment()"
            >
            </rt-trip-details>
        </div>
        <!-- Commodity Shipped-->
        <div
            class="container"
            bdsScrollSpySection="tripproductshippedanchor"
            [scrollMargin]="108"
            #tripproductshippedanchor
        >
            <h4>Products Shipped</h4>
            <div class="mb-3">
                <rt-trip-commodity-shipped
                    [(commodityShipped)]="combinedCommShipped"
                    [(dirty)]="isDirty"
                    [highlightDirty]="true"
                    (dirtyValues)="commChanges = $event"
                ></rt-trip-commodity-shipped>
            </div>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <fa-icon [icon]="iconGroup" [fixedWidth]="true"></fa-icon>
                        Set Individual Commodity Info
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mb-3">
                    <div class="scrollable-volume">
                        <ng-container *ngFor="let item of selectedCommodityShippedKeys">
                            <div class="row">
                                <div class="col-10">
                                    <rt-multi-trip-commodity-shipped-volumes
                                        [commodityShipped]="item"
                                        [(dirty)]="isDirty"
                                    ></rt-multi-trip-commodity-shipped-volumes>
                                </div>

                                <div class="col-1 pull-right">
                                    <button mat-icon-button>
                                        <fa-duotone-icon
                                            [icon]="iconDelete"
                                            primaryColor="red"
                                            secondaryColor="red"
                                            [fixedWidth]="true"
                                            (click)="onDeleteProductClick(item?.ormId)"
                                        ></fa-duotone-icon>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </mat-expansion-panel>
            <div style="padding: 10px"></div>
            <div
                class="mb-3"
                bdsScrollSpySection="tripdiversionanchor"
                [scrollMargin]="116"
                #tripdiversionanchor
            >
                <mat-expansion-panel
                    #tripdiversionpanel
                    (opened)="onDiversionPanelOpened()"
                    (closed)="onDiversionPanelClosed()"
                >
                    <mat-expansion-panel-header>
                        <fa-icon [icon]="iconDiversion" [fixedWidth]="true"></fa-icon>
                        Diversions
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <rt-multi-diversion
                            #diversionComponent
                            [tripIds]="tripIds"
                            [creatingDiversion]="creatingDiversion"
                            (diversionCreated)="onDiversionCreated($event)"
                            (diversionCancelled)="onDiversionHide()"
                        >
                        </rt-multi-diversion>
                    </ng-template>
                </mat-expansion-panel>
            </div>
            <div
                class="mb-3"
                bdsScrollSpySection="tripequipanchor"
                [scrollMargin]="116"
                #tripequipanchor
            >
                <mat-expansion-panel #tripequippanel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <fa-icon [icon]="iconGroup" [fixedWidth]="true"></fa-icon>
                            Reporting Categories
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <mat-toolbar class="customer-header-bar bds-toolbar-blue">
                            <mat-toolbar-row>
                                <span> Mass Update Reporting Categories </span>
                                <span *ngIf="isSaving">
                                    <small>
                                        (saving...)
                                        <fa-icon [icon]="iconSpinner" [spin]="true"></fa-icon>
                                    </small>
                                </span>
                                <span class="fill-remaining-space"></span>
                                <span>
                                    <button mat-stroked-button (click)="addEquipmentCategory()">
                                        <fa-icon [icon]="iconAdd"></fa-icon>
                                        <span class="text-uppercase"
                                            >Modify Reporting Category</span
                                        >
                                    </button>
                                </span>
                            </mat-toolbar-row>
                        </mat-toolbar>
                        <ng-container
                            *ngIf="
                                !!equipCategoriesGroup &&
                                !!equipCatgValuesGroup &&
                                !!equipCatItemsGroup &&
                                !!selectedTripKeys
                            "
                        >
                            <div class="row">
                                <div class="col-2"><b>Equipment</b></div>
                                <div class="col-10">
                                    <div class="row">
                                        <div class="col-3"><b>Category</b></div>
                                        <div class="col-3"><b>Category Value</b></div>
                                        <div class="col-6"><b>Category Effective Dates</b></div>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngFor="let trip of selectedTripKeys; let idx = index">
                                <hr *ngIf="idx > 0" />
                                <div class="row">
                                    <div class="col-2">{{ trip.carInit }}{{ trip.carNo }}</div>
                                    <div class="col-10">
                                        <div
                                            class="row"
                                            *ngFor="
                                                let equipItems of equipCatItemsGroup[
                                                    trip.tcmEquipmentOrmId
                                                ]
                                            "
                                        >
                                            <div class="col-3">
                                                {{
                                                    equipCategoriesGroup[
                                                        equipItems.equipReportCatgId
                                                    ].equipReportCategory
                                                }}
                                            </div>
                                            <div class="col-3">
                                                {{
                                                    equipCatgValuesGroup[
                                                        equipItems.equipReportCatgValId
                                                    ].equipReportCatgVal
                                                }}
                                            </div>
                                            <div class="col-6">
                                                {{
                                                    equipItems.effectDate | date : 'medium' : 'UTC'
                                                }}
                                                -
                                                <span
                                                    *ngIf="
                                                        !!equipItems.expireDate;
                                                        else presenttemplate
                                                    "
                                                    >{{
                                                        equipItems.expireDate
                                                            | date : 'medium' : 'UTC'
                                                    }}</span
                                                >
                                                <ng-template #presenttemplate>PRESENT</ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-template>
                </mat-expansion-panel>
            </div>
            <!-- Comments-->
            <div
                class="mb-3"
                bdsScrollSpySection="tripcommentsanchor"
                [scrollMargin]="116"
                #tripcommentsanchor
            >
                <mat-expansion-panel #tripcommentsPanel>
                    <mat-expansion-panel-header>
                        <fa-icon [icon]="iconComments" [fixedWidth]="true"></fa-icon>
                        Comments
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <rt-multi-comment [trips]="selectedTripKeys"></rt-multi-comment>
                        <!-- <rt-multi-comment-list [trips]="selectedTripKeys"></rt-multi-comment-list> -->
                    </ng-template>
                </mat-expansion-panel>
            </div>
            <!-- Hotlists-->
            <div
                class="mb-3"
                bdsScrollSpySection="hotListAnchor"
                [scrollMargin]="116"
                #hotListAnchor
            >
                <mat-expansion-panel #hotListPanel>
                    <mat-expansion-panel-header>
                        <fa-icon [icon]="iconHotList" [fixedWidth]="true"></fa-icon>
                        Hot Lists
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <rt-multi-hot-list [trips]="selectedTripKeys"></rt-multi-hot-list>
                    </ng-template>
                </mat-expansion-panel>
            </div>
        </div>

        <!-- <div class="container-fluid">
            <div class="mb-3" bdsScrollSpySection="tripclmanchor" [scrollMargin]="116" #tripclmanchor>
            <mat-expansion-panel #tripclmpanel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <fa-icon [icon]="iconHistory" [fixedWidth]="true"></fa-icon>Current CLM: {{trip.sightCode}} - {{trip.locationCity}}, {{trip.locationState}} - {{trip.clmDateTime | date:'MM/dd/yyyy'}}</mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                <rt-clm></rt-clm>
                </ng-template>
            </mat-expansion-panel>
            </div>
            <div class="mb-3" bdsScrollSpySection="triprouteanchor" [scrollMargin]="116" #triprouteanchor>
            <mat-expansion-panel #triproutepanel>
                <mat-expansion-panel-header>
                <mat-panel-title><fa-icon [icon]="iconRoute" [fixedWidth]="true"></fa-icon> Route</mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                <bds-route [routeCode]="trip.routeCode"></bds-route>
                </ng-template>
            </mat-expansion-panel>
            </div>
            <div class="mb-3" bdsScrollSpySection="tripcommentsanchor" [scrollMargin]="116" #tripcommentsanchor>
            <mat-expansion-panel #tripcommentspanel>
                <mat-expansion-panel-header>
                <mat-panel-title><fa-icon [icon]="iconComments" [fixedWidth]="true"></fa-icon> Comments</mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                <rt-comments-for-trip [tripId]="trip.ormId"></rt-comments-for-trip>
                </ng-template>
            </mat-expansion-panel>
            </div>
        </div> -->
    </div>
</mat-sidenav-container>

<mat-menu #tripOptionsMenu="matMenu" xPosition="before">
    <button mat-menu-item (click)="reApplyClms()">Reapply CLMs</button>
    <button mat-menu-item>Shop Form</button>
    <button mat-menu-item (click)="addClmsDialog()">Add CLMs</button>
</mat-menu>

<div style="padding-bottom: 500px"></div>
