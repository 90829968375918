<div class="container-fluid" [formGroup]="filterForm">
    <div class="row">
        <div class="col-6">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label> Car Init </mat-label>
                <input matInput tabindex="0" formControlName="carInit" bdsShoutcase />
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label> Car Number </mat-label>
                <input matInput formControlName="carNo" />
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="dx-field-label">CLM From Date</div>
            <dx-date-box
                name="clmFromDate"
                formControlName="clmFromDate"
                class="w-100 bds-dx-custom-material"
                [stylingMode]="dxMatStyle"
                type="date"
                dateSerializationFormat="yyyy-MM-dd"
                displayFormat="MM/dd/yy"
                [showClearButton]="true"
                [useMaskBehavior]="true"
            >
                <!-- <dx-validator>
                    <dxi-validation-rule
                        type="required"
                        message="CLM From Date is required."
                    ></dxi-validation-rule>
                </dx-validator> -->
            </dx-date-box>
        </div>
        <div class="col-6">
            <div class="dx-field-label">CLM To Date</div>
            <dx-date-box
                name="clmToDate"
                formControlName="clmToDate"
                class="w-100 bds-dx-custom-material"
                [stylingMode]="dxMatStyle"
                type="date"
                dateSerializationFormat="yyyy-MM-dd"
                displayFormat="MM/dd/yy"
                [showClearButton]="true"
                [useMaskBehavior]="true"
            >
                <!-- <dx-validator>
                    <dxi-validation-rule
                        type="required"
                        message="CLM To Date is required."
                    ></dxi-validation-rule>
                </dx-validator> -->
            </dx-date-box>
        </div>
    </div>
    <div class="row" *ngIf="filterForm.errors && filterForm.errors.dateRangeInvalid">
        <mat-error> CLM From Date must be less than or equal to CLM To Date </mat-error>
    </div>
</div>
