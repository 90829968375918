import { Injectable } from "@angular/core";
import { FastracDefaultService } from "@bds/services";
import { Observable, of, tap, map } from "rxjs";


@Injectable({ providedIn: 'root' })
export class FastracDefaultCacheService {
    private _cache = new Map<number, number>();

    constructor(private fastracDefaultService: FastracDefaultService) { }

    getFastracDefaultByGridId(gridId: number): Observable<number> {
        if (this._cache.has(gridId)) {
            return of(this._cache.get(gridId));
        }
        if (!this._cache.has(gridId)) {
            // Fetch Values
            return this.fastracDefaultService.getFastracDefaults().pipe(
                tap((defaults) => {
                    defaults.forEach((defaultVal) => {
                        this._cache.set(defaultVal.gridId, defaultVal.fastracId);
                    });
                }),
                map(() => {
                    const fastrac = this._cache.get(gridId);
                    return fastrac ?? null;
                })
            );
        }
    }
}
