import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, shareReplay, catchError } from 'rxjs/operators';
import { ApiErrorHandlerService } from '@bds/helpers';
import { RtCustomerTypeDefault, RtCustomerTypeDefaultAdapter } from '@bds/railtrac-models';

@Injectable({
    providedIn: 'root',
})
export class RtCustomerTypeDefaultService {
    controllerName = 'rtCustomerTypeDefault';

    constructor(
        private http: HttpClient,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private adapter: RtCustomerTypeDefaultAdapter,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    getCustomerTypeDefaults(custType: string): Observable<RtCustomerTypeDefault[]> {
        return this.http
            .get<RtCustomerTypeDefault[]>(
                `${this.odatabaseUrl}${this.controllerName}?$filter=custType eq '${custType}'`,
            )
            .pipe(map((response) => response['value'].map((data) => this.adapter.adapt(data))));
    }

    getByCustTypeAndLE(custType: string, le: string): Observable<RtCustomerTypeDefault> {
        if (custType && le) {
            const filter = `$filter=custType eq '${custType}' and loadedEmptyIndicator eq '${le}'`;
            return this.http
                .get<any>(`${this.odatabaseUrl}${this.controllerName}?$top=1&${filter}`)
                .pipe(
                    map((data) => {
                        if (data && data.value && data.value.length) {
                            return this.adapter.adapt(data.value[0]);
                        }
                        return null;
                    }),
                    shareReplay(),
                    catchError((err) => this.apiErrorHandler.handleError(err)),
                );
        } else {
            return of(null);
        }
    }
}
