import { RAILTRAC_CONFIG } from '../tokens/railtrac-config';
import { buildApiReadServiceInjectionToken } from '@bds/odata';

export const FastracReaderService = buildApiReadServiceInjectionToken<any>(
    'FastracReaderService',
    'Fastrac',
    'Fastrac',
    RAILTRAC_CONFIG,
    'root',
);
