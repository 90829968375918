<dx-data-grid
    id="bdsReportGrid"
    bds-grid
    [dataSource]="gridSource"
    height="80vh"
    minHeight="200px"
    dataRowTemplate="dataRowTemplateName"
>
    <dxi-column caption="Fleet ID"></dxi-column>
    <dxi-column caption="Business Group"></dxi-column>
    <dxi-column caption="AT CONSIGNEE"></dxi-column>
    <dxi-column caption="AT ORIGIN EMPTY"></dxi-column>
    <dxi-column caption="AT ORIGIN LOADED"></dxi-column>
    <dxi-column caption="AT SHOP"></dxi-column>
    <dxi-column caption="NO BILLS"></dxi-column>
    <dxi-column caption="NO TRIPS"></dxi-column>
    <dxi-column caption="TO CONSIGNEE"></dxi-column>
    <dxi-column caption="TO ORIGIN"></dxi-column>
    <dxi-column caption="TO SHOP"></dxi-column>
    <dxi-column caption="Grand total"></dxi-column>
    <ng-container *dxTemplate="let item of 'dataRowTemplateName'">
        <tr>
            <td>{{ item.data.fleet_id }}</td>
            <td>{{ item.data.business_group }}</td>
            <td>{{ item.data.at_consignee }}</td>
            <td>{{ item.data.at_origin_empty }}</td>
            <td>{{ item.data.at_origin_loaded }}</td>
            <td>{{ item.data.at_shop }}</td>
            <td>{{ item.data.no_bills }}</td>
            <td>{{ item.data.no_trips }}</td>
            <td>{{ item.data.to_consignee }}</td>
            <td>{{ item.data.to_origin }}</td>
            <td>{{ item.data.to_shop }}</td>
            <td>{{ item.data.GRAND_TOTAL }}</td>
        </tr>
    </ng-container>
</dx-data-grid>
