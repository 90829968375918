<div *ngIf="!!clm">
    <dx-validation-group>
        <div class="container mt-1" [formGroup]="clmForm">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-6 col-xl-3 col-lg-3" *ngIf="!multiClmPopup">
                            <mat-form-field
                                floatLabel="always"
                                class="w-100"
                                appearance="fill"
                                [class.highlight-dirty]="highlightDirty"
                                [class.bds-mat-dense-form-field]="dense"
                            >
                                <mat-label>
                                    Car Init
                                    <span class="dirty-indicator" *ngIf="highlightDirty">*</span>
                                </mat-label>
                                <input
                                    matInput
                                    bdsShoutcase
                                    [readonly]="!isNew"
                                    formControlName="carInit"
                                />
                                <mat-hint *ngIf="!isNew">Read only</mat-hint>
                                <mat-hint *ngIf="isNew">Required</mat-hint>
                                <mat-error *ngIf="clmForm.controls.carInit.errors">
                                    {{ getError(clmForm.controls.carInit) }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-xl-3 col-lg-3" *ngIf="!multiClmPopup">
                            <mat-form-field
                                floatLabel="always"
                                class="w-100"
                                appearance="fill"
                                [class.highlight-dirty]="highlightDirty"
                                [class.bds-mat-dense-form-field]="dense"
                            >
                                <mat-label>
                                    Car Number
                                    <span class="dirty-indicator" *ngIf="highlightDirty">*</span>
                                </mat-label>
                                <input
                                    matInput
                                    bdsShoutcase
                                    [readonly]="!isNew"
                                    formControlName="carNo"
                                />
                                <mat-hint *ngIf="!isNew">Read only</mat-hint>
                                <mat-hint *ngIf="isNew">Required</mat-hint>
                                <mat-error *ngIf="clmForm.controls.carNo.errors">
                                    {{ getError(clmForm.controls.carNo) }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-xl-6 col-lg-6">
                            <bds-date-time-picker
                                formControlName="clmDateTime"
                                type="datetime"
                                [readOnly]="!isNew"
                                label="CLM Date"
                                [isValid]="clmForm.controls.clmDateTime.valid"
                            >
                                <mat-error *ngIf="clmForm.controls.clmDateTime.errors">
                                    {{ getError(clmForm.controls.clmDateTime) }}
                                </mat-error>
                                <mat-hint>Required</mat-hint>
                            </bds-date-time-picker>
                        </div>
                        <div class="col-12 col-xl-6 col-lg-6" *ngIf="multiClmPopup">
                            <dx-date-box
                                name="clmProcessedDate"
                                [class.bds-mat-dense-form-field]="dense"
                                class="w-100 bds-dx-custom-material"
                                formControlName="clmProcessedDate"
                                [stylingMode]="dxMatStyle"
                                type="date"
                                dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
                                displayFormat="dd/MM/yyyy"
                                label="CLM Processed Date"
                                validationMessageMode="always"
                                [isValid]="clmForm.controls.clmProcessedDate.valid"
                            >
                                <dx-validator>
                                    <dxi-validation-rule
                                        type="required"
                                        message="CLM Processed Date is required."
                                    ></dxi-validation-rule>
                                </dx-validator>
                            </dx-date-box>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <mat-form-field
                                floatLabel="always"
                                class="w-100"
                                appearance="fill"
                                [class.bds-mat-dense-form-field]="dense"
                            >
                                <mat-label>Location City</mat-label>
                                <input
                                    matInput
                                    bdsShoutcase
                                    formControlName="locationCity"
                                    maxlength="9"
                                />
                                <mat-hint>Required</mat-hint>
                                <mat-error *ngIf="clmForm.controls.locationCity.errors">
                                    {{ getError(clmForm.controls.locationCity) }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6">
                            <mat-form-field
                                floatLabel="always"
                                class="w-100"
                                appearance="fill"
                                [class.highlight-dirty]="highlightDirty"
                                [class.bds-mat-dense-form-field]="dense"
                            >
                                <mat-label
                                    >Location State
                                    <span class="dirty-indicator" *ngIf="highlightDirty"
                                        >*</span
                                    ></mat-label
                                >
                                <mat-select formControlName="locationState">
                                    <mat-option></mat-option>
                                    <mat-option
                                        *ngFor="let item of stateCodeLookup | async"
                                        [value]="item.code"
                                    >
                                        {{ item.code }} - {{ item.description }}
                                    </mat-option>
                                </mat-select>
                                <mat-hint>Required</mat-hint>
                                <mat-error *ngIf="clmForm.controls.locationState.errors">
                                    {{ getError(clmForm.controls.locationState) }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!--<div class="col-lg-4">
                            <mat-form-field
                                floatLabel="always"
                                class="w-100"
                                appearance="fill"
                                [class.highlight-dirty]="highlightDirty"
                                [class.bds-mat-dense-form-field]="dense"
                            >
                                <mat-label>
                                    Location SPLC
                                    <span class="dirty-indicator" *ngIf="highlightDirty">*</span>
                                </mat-label>
                                <input matInput bdsShoutcase formControlName="locationSplc" />
                                <mat-error *ngIf="clmForm.controls.locationSplc.errors">
                                    {{ getError(clmForm.controls.locationSplc) }}
                                </mat-error>
                            </mat-form-field>
                        </div>-->
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <mat-form-field
                                floatLabel="always"
                                class="w-100"
                                appearance="fill"
                                [class.bds-mat-dense-form-field]="dense"
                            >
                                <mat-label>Sight Code</mat-label>
                                <mat-select formControlName="sightCode">
                                    <mat-option
                                        *ngFor="let sightCode of sightCodes"
                                        [value]="sightCode.id"
                                        matTooltip="{{ sightCode.description }}"
                                    >
                                        {{
                                            sightCode
                                                ? kvpDisplayTemplate(sightCode.id, sightCode.name)
                                                : ''
                                        }}
                                    </mat-option>
                                </mat-select>
                                <mat-hint *ngIf="isNew">Required</mat-hint>
                                <mat-hint *ngIf="clmForm.controls.sightCode.disabled"
                                    >Read Only</mat-hint
                                >
                                <mat-error *ngIf="clmForm.controls.sightCode.errors">
                                    {{ getError(clmForm.controls.sightCode) }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6">
                            <mat-form-field
                                floatLabel="always"
                                class="w-100"
                                appearance="fill"
                                [class.highlight-dirty]="highlightDirty"
                                [class.bds-mat-dense-form-field]="dense"
                            >
                                <mat-label>
                                    Load/Empty
                                    <span class="dirty-indicator" *ngIf="highlightDirty">*</span>
                                </mat-label>
                                <mat-select formControlName="loadEmptyFlag" [readonly]="!isNew">
                                    <mat-option></mat-option>
                                    <mat-option
                                        *ngFor="let item of loadEmptyFlags$ | async"
                                        [value]="item.key"
                                    >
                                        {{ kvpDisplayTemplate(item.key, item.value) }}
                                    </mat-option>
                                </mat-select>
                                <mat-hint>Required</mat-hint>
                                <mat-error *ngIf="clmForm.controls.loadEmptyFlag.errors">
                                    {{ getError(clmForm.controls.loadEmptyFlag) }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <mat-form-field
                                floatLabel="always"
                                class="w-100"
                                appearance="fill"
                                [class.highlight-dirty]="highlightDirty"
                                [class.bds-mat-dense-form-field]="dense"
                            >
                                <mat-label>
                                    Destination City
                                    <span class="dirty-indicator" *ngIf="highlightDirty">*</span>
                                </mat-label>
                                <input
                                    matInput
                                    bdsShoutcase
                                    formControlName="destinationCity"
                                    maxlength="9"
                                />
                                <mat-error *ngIf="clmForm.controls.destinationCity.errors">
                                    {{ getError(clmForm.controls.destinationCity) }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6">
                            <mat-form-field
                                floatLabel="always"
                                class="w-100"
                                appearance="fill"
                                [class.highlight-dirty]="highlightDirty"
                                [class.bds-mat-dense-form-field]="dense"
                            >
                                <mat-label
                                    >Destination State
                                    <span class="dirty-indicator" *ngIf="highlightDirty"
                                        >*</span
                                    ></mat-label
                                >
                                <mat-select formControlName="destinationState">
                                    <mat-option></mat-option>
                                    <mat-option
                                        *ngFor="let item of stateCodeLookup | async"
                                        [value]="item.code"
                                    >
                                        {{ item.code }} - {{ item.description }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="clmForm.controls.destinationState.errors">
                                    {{ getError(clmForm.controls.destinationState) }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <dx-select-box
                                class="w-100 bds-dx-custom-material bds-mat-dense-form-field dx-mat-form-field-floatLabel-always"
                                stylingMode="filled"
                                validationMessagePosition="right"
                                validationMessageMode="always"
                                formControlName="road"
                                [dataSource]="railroadDxDataSource"
                                valueExpr="rrScac"
                                [searchEnabled]="true"
                                [searchExpr]="['rrScac']"
                                label="Railroad"
                                [displayExpr]="displayRoad"
                                [isValid]="clmForm.controls.road.valid"
                            >
                            </dx-select-box>
                            <mat-error *ngIf="clmForm.controls.road.errors">{{
                                getError(clmForm.controls.road)
                            }}</mat-error>
                            <mat-hint>Required</mat-hint>
                        </div>
                        <div class="col-lg-6">
                            <mat-form-field
                                floatLabel="always"
                                class="w-100"
                                appearance="fill"
                                [class.highlight-dirty]="highlightDirty"
                                [class.bds-mat-dense-form-field]="dense"
                            >
                                <mat-label>
                                    Train Junction
                                    <span class="dirty-indicator" *ngIf="highlightDirty">*</span>
                                </mat-label>
                                <input matInput bdsShoutcase formControlName="trainJunction" />
                                <mat-error *ngIf="clmForm.controls.trainJunction.errors">
                                    {{ getError(clmForm.controls.trainJunction) }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!--<div class="col-lg-6">
                            <mat-form-field
                                floatLabel="always"
                                class="w-100"
                                appearance="fill"
                                [class.highlight-dirty]="highlightDirty"
                                [class.bds-mat-dense-form-field]="dense"
                            >
                                <mat-label>
                                    Route Code
                                    <span class="dirty-indicator" *ngIf="highlightDirty">*</span>
                                </mat-label>
                                <input matInput bdsShoutcase formControlName="routeCode" />
                                <mat-error *ngIf="clmForm.controls.routeCode.errors">
                                    {{ getError(clmForm.controls.routeCode) }}
                                </mat-error>
                            </mat-form-field>
                        </div>-->
                    </div>
                    <!-- <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> Destination </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row">
                            <div class="col-lg-5">
                                <mat-form-field
                                    floatLabel="always"
                                    class="w-100"
                                    appearance="fill"
                                    [class.highlight-dirty]="highlightDirty"
                                    [class.bds-mat-dense-form-field]="dense"
                                >
                                    <mat-label>
                                        Destination City
                                        <span class="dirty-indicator" *ngIf="highlightDirty"
                                            >*</span
                                        >
                                    </mat-label>
                                    <input
                                        matInput
                                        bdsShoutcase
                                        formControlName="destinationCity"
                                    />
                                    <mat-error *ngIf="clmForm.controls.destinationCity.errors">
                                        {{ getError(clmForm.controls.destinationCity) }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3">
                                <mat-form-field
                                    floatLabel="always"
                                    class="w-100"
                                    appearance="fill"
                                    [class.highlight-dirty]="highlightDirty"
                                    [class.bds-mat-dense-form-field]="dense"
                                >
                                    <mat-label
                                        >Destination State
                                        <span class="dirty-indicator" *ngIf="highlightDirty"
                                            >*</span
                                        ></mat-label
                                    >
                                    <mat-select formControlName="destinationState">
                                        <mat-option></mat-option>
                                        <mat-option
                                            *ngFor="let item of stateCodeLookup | async"
                                            [value]="item.code"
                                        >
                                            {{ item.code }} - {{ item.description }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="clmForm.controls.destinationState.errors">
                                        {{ getError(clmForm.controls.destinationState) }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4">
                                <mat-form-field
                                    floatLabel="always"
                                    class="w-100"
                                    appearance="fill"
                                    [class.highlight-dirty]="highlightDirty"
                                    [class.bds-mat-dense-form-field]="dense"
                                >
                                    <mat-label>
                                        Destination SPLC
                                        <span class="dirty-indicator" *ngIf="highlightDirty"
                                            >*</span
                                        >
                                    </mat-label>
                                    <input
                                        matInput
                                        bdsShoutcase
                                        formControlName="destinationSplc"
                                    />
                                    <mat-error *ngIf="clmForm.controls.destinationSplc.errors">
                                        {{ getError(clmForm.controls.destinationSplc) }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-expansion-panel> -->
                    <!-- <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> Rail Road </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row">
                            <div class="col-lg-3">
                                <dx-select-box
                                    [stylingMode]="dxMatStyle"
                                    [class.bds-mat-dense-form-field]="dense"
                                    [class.dx-mat-form-field-floatLabel-always]="
                                        matFloatLabel == 'always'
                                    "
                                    class="bds-dx-custom-material flex-fill"
                                    formControlName="road"
                                    [dataSource]="railroadDxDataSource"
                                    fieldTemplate="inputField"
                                    itemTemplate="item"
                                    valueExpr="rrScac"
                                    [searchEnabled]="true"
                                    [searchExpr]="['rrScac', 'rrName']"
                                    label="Railroad"
                                    validationMessageMode="always"
                                    [isValid]="clmForm.controls.road.valid"
                                >
                                    <dxo-drop-down-options minWidth="250px"></dxo-drop-down-options>
                                    <div *dxTemplate="let data of 'item'">
                                        <span>{{
                                            data ? kvpDisplayTemplate(data.rrScac, data.rrName) : ''
                                        }}</span>
                                    </div>
                                    <div *dxTemplate="let data of 'inputField'">
                                        <dx-text-box
                                            label="Railroad"
                                            style="display: inline-block"
                                            [value]="
                                                data
                                                    ? kvpDisplayTemplate(data.rrScac, data.rrName)
                                                    : ''
                                            "
                                        >
                                        </dx-text-box>
                                    </div>
                                    <dx-validator>
                                        <dxi-validation-rule
                                            type="required"
                                            message="Railroad is required."
                                        ></dxi-validation-rule>
                                        <dxi-validation-rule
                                            type="stringLength"
                                            max="4"
                                            message="Railroad can only be 4 characters."
                                        ></dxi-validation-rule>
                                    </dx-validator>
                                </dx-select-box>
                            </div>
                            <div class="col-lg-4">
                                <mat-form-field
                                    floatLabel="always"
                                    class="w-100"
                                    appearance="fill"
                                    [class.highlight-dirty]="highlightDirty"
                                    [class.bds-mat-dense-form-field]="dense"
                                >
                                    <mat-label>
                                        Train Junction
                                        <span class="dirty-indicator" *ngIf="highlightDirty"
                                            >*</span
                                        >
                                    </mat-label>
                                    <input matInput bdsShoutcase formControlName="trainJunction" />
                                    <mat-error *ngIf="clmForm.controls.trainJunction.errors">
                                        {{ getError(clmForm.controls.trainJunction) }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-5">
                                <mat-form-field
                                    floatLabel="always"
                                    class="w-100"
                                    appearance="fill"
                                    [class.highlight-dirty]="highlightDirty"
                                    [class.bds-mat-dense-form-field]="dense"
                                >
                                    <mat-label>
                                        CLM Event
                                        <span class="dirty-indicator" *ngIf="highlightDirty"
                                            >*</span
                                        >
                                    </mat-label>
                                    <input matInput bdsShoutcase formControlName="clmEvent" />
                                    <mat-error *ngIf="clmForm.controls.clmEvent.errors">
                                        {{ getError(clmForm.controls.clmEvent) }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5">
                                <dx-date-box
                                    name="clmProcessedDate"
                                    [class.bds-mat-dense-form-field]="dense"
                                    class="w-100 bds-dx-custom-material"
                                    formControlName="clmProcessedDate"
                                    [stylingMode]="dxMatStyle"
                                    type="datetime"
                                    dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
                                    displayFormat="MM/dd/yyy h:mm:ss a"
                                    label="CLM Processed Date"
                                    validationMessageMode="always"
                                    [isValid]="clmForm.controls.clmProcessedDate.valid"
                                >
                                    <dx-validator>
                                        <dxi-validation-rule
                                            type="required"
                                            message="CLM Processed Date is required."
                                        ></dxi-validation-rule>
                                    </dx-validator>
                                </dx-date-box>
                            </div>
                            <div class="col-lg-7">
                                <mat-form-field
                                    floatLabel="always"
                                    class="w-100"
                                    appearance="fill"
                                    [class.highlight-dirty]="highlightDirty"
                                    [class.bds-mat-dense-form-field]="dense"
                                >
                                    <mat-label>
                                        Source
                                        <span class="dirty-indicator" *ngIf="highlightDirty"
                                            >*</span
                                        >
                                    </mat-label>
                                    <input matInput bdsShoutcase formControlName="source" />
                                    <mat-error *ngIf="clmForm.controls.source.errors">
                                        {{ getError(clmForm.controls.source) }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-expansion-panel> -->
                    <!--<mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> Timing </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row">
                            <div class="col-6">
                                <mat-form-field
                                    floatLabel="always"
                                    class="w-100"
                                    appearance="fill"
                                    [class.highlight-dirty]="highlightDirty"
                                    [class.bds-mat-dense-form-field]="dense"
                                >
                                    <mat-label>
                                        Actual Hours to Origin
                                        <span class="dirty-indicator" *ngIf="highlightDirty"
                                            >*</span
                                        >
                                    </mat-label>
                                    <input
                                        matInput
                                        type="number"
                                        formControlName="actualHoursToOrigin"
                                    />
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field
                                    floatLabel="always"
                                    class="w-100"
                                    appearance="fill"
                                    [class.highlight-dirty]="highlightDirty"
                                    [class.bds-mat-dense-form-field]="dense"
                                >
                                    <mat-label>
                                        Actual Hours to Destination
                                        <span class="dirty-indicator" *ngIf="highlightDirty"
                                            >*</span
                                        >
                                    </mat-label>
                                    <input
                                        matInput
                                        type="number"
                                        formControlName="actualHoursToDestination"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <mat-form-field
                                    floatLabel="always"
                                    class="w-100"
                                    appearance="fill"
                                    [class.highlight-dirty]="highlightDirty"
                                    [class.bds-mat-dense-form-field]="dense"
                                >
                                    <mat-label>
                                        Dwell Time
                                        <span class="dirty-indicator" *ngIf="highlightDirty"
                                            >*</span
                                        >
                                    </mat-label>
                                    <input matInput type="number" formControlName="dwellTime" />
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field
                                    floatLabel="always"
                                    class="w-100"
                                    appearance="fill"
                                    [class.highlight-dirty]="highlightDirty"
                                >
                                    <mat-label>
                                        CLM Differential Time
                                        <span class="dirty-indicator" *ngIf="highlightDirty"
                                            >*</span
                                        >
                                    </mat-label>
                                    <input
                                        matInput
                                        type="number"
                                        formControlName="clmDifferentialTime"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> Previous CLM Data </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row">
                            <div class="col-6">
                                <mat-form-field
                                    floatLabel="always"
                                    class="w-100"
                                    appearance="fill"
                                    [class.highlight-dirty]="highlightDirty"
                                    [class.bds-mat-dense-form-field]="dense"
                                >
                                    <mat-label>
                                        Previous SPLC
                                        <span class="dirty-indicator" *ngIf="highlightDirty"
                                            >*</span
                                        >
                                    </mat-label>
                                    <input matInput bdsShoutcase formControlName="previousSplc" />
                                    <mat-error *ngIf="clmForm.controls.previousSplc.errors">
                                        {{ getError(clmForm.controls.previousSplc) }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field
                                    floatLabel="always"
                                    class="w-100"
                                    appearance="fill"
                                    [class.highlight-dirty]="highlightDirty"
                                    [class.bds-mat-dense-form-field]="dense"
                                >
                                    <mat-label>
                                        Miles From Previous
                                        <span class="dirty-indicator" *ngIf="highlightDirty"
                                            >*</span
                                        >
                                    </mat-label>
                                    <input
                                        matInput
                                        type="number"
                                        formControlName="milesFromPrevious"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-expansion-panel>-->
                </div>
                <br />
                <br />
            </div>
        </div>
    </dx-validation-group>
</div>
