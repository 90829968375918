<bds-fastrac-design-display [fastracId]="fastracId" [showFastrac]="showFastrac">
</bds-fastrac-design-display>
<dx-data-grid
    bds-grid
    #grid
    [fastracId]="fastracId"
    [bdsFastracVisible]="showFastrac"
    [bdsUseFastrac]="true"
    id="bdsEquipmentGrid"
    [dataSource]="dataSource"
    height="85vh"
    [bdsAllowAddRecord]="true"
>
</dx-data-grid>
