import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { ApiErrorHandlerService } from '@bds/helpers';
import { RtTripDiversion, RtWhoPays } from '@bds/railtrac-models';
import { RtTripsByRoad } from '../models/rt-trips-by-road.model';
import { RtDiversionAdapterService } from './rt-diversion-adapter.service';
import { RtDiversionFormModel } from '../models/rt-diversion-form.model';
import { RtDivertRequest } from '../models/rt-divert-request.model';

@Injectable({
    providedIn: 'root',
})
export class RtDiversionService {
    controllerName: string = 'tripGrid';
    diversionTypeControllerName: string = 'rtdiversiontype';
    diversionTypeList: { key: string; value: string }[] = [];
    whoPaysControllerName: string = 'whopays';
    whoPaysList: RtWhoPays[] = [];

    constructor(
        private http: HttpClient,
        @Inject('BASE_API_URL') private apibaseUrl: string,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private apiErrorHandler: ApiErrorHandlerService,
        private adapterService: RtDiversionAdapterService,
    ) {}

    create(diversion: RtDiversionFormModel): Observable<any> {
        const diversionsToCreate: RtTripsByRoad[] = this.adapterService.filterRequestedDiversions(
            diversion.roads,
        );
        const request: RtDiversionFormModel = { ...diversion, roads: diversionsToCreate };
        console.log('REQUEST TO API', request);

        return this.http.post<RtDivertRequest>(`${this.apibaseUrl}diversion`, request).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((err) => {
                if (err && err.status === 422) {
                    return throwError(err);
                } else {
                    return this.apiErrorHandler.handleError(err);
                }
            }),
        );
    }

    getDivertTypes(): Observable<{ key: string; value: string }[]> {
        if (this.diversionTypeList && this.diversionTypeList.length > 0) {
            return of(this.diversionTypeList);
        }

        return this.http
            .get<string>(`${this.odatabaseUrl}${this.diversionTypeControllerName}`)
            .pipe(
                map((data: any) => {
                    let valueList: { key: string; value: string }[] = [];
                    if (data.value && data.value.length) {
                        valueList = data.value.map((v) => {
                            return {
                                key: v.diversionType,
                                value: v.diversionDescription,
                            };
                        });
                    }
                    this.diversionTypeList = valueList;
                    return valueList;
                }),
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    getTripsToDivert(keys: number[]): Observable<RtTripsByRoad[]> {
        return this.http
            .get<RtTripDiversion>(`${this.apibaseUrl}${this.controllerName}/tripsToDivert`, {
                params: {
                    ids: keys.join(','),
                },
            })
            .pipe(
                map((data: any) => {
                    return this.adapterService.groupByRoad(data);
                }),
                shareReplay(),
                catchError((err) => {
                    if (err && err.status === 422) {
                        return throwError(this.showDiversionErrors(err));
                    } else {
                        return this.apiErrorHandler.handleError(err);
                    }
                }),
            );
    }

    getWhoPaysList(): Observable<RtWhoPays[]> {
        if (this.whoPaysList && this.whoPaysList.length > 0) {
            return of(this.whoPaysList);
        }

        return this.http.get<RtWhoPays[]>(`${this.odatabaseUrl}${this.whoPaysControllerName}`).pipe(
            map((data: any) => {
                let valueList: RtWhoPays[] = [];
                if (data.value && data.value.length) {
                    valueList = data.value;
                }
                this.whoPaysList = valueList;
                return valueList;
            }),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    private showDiversionErrors(err): { status: number; message: string } {
        if (err && err.status === 422) {
            const errMsg: string = (err['error'] || '').toLowerCase();

            let dialogMsg: string = '';
            if (errMsg.indexOf('closed trips') > -1) {
                dialogMsg = `You cannot divert car because the trip is closed!\nIf a diversion is required, the trip must first be re-opened.`;
            } else if (errMsg.indexOf('origin') > -1) {
                dialogMsg = `You cannot divert car because it is at origin!\nIf a diversion is required, the trip must first be re-opened.`;
            } else if (errMsg.indexOf('consignee') > -1) {
                dialogMsg = `You cannot divert car because it is at consignee!\nIf a diversion is required, close this trip and create a new one.`;
            } else if (errMsg.indexOf('status') > -1) {
                dialogMsg = 'Shipments do not have the same Car Status!';
            } else if (errMsg.indexOf('onewayround') > -1) {
                dialogMsg = 'Shipments do not have the same Oneway/Round status!';
            } else if (errMsg.indexOf('l/e') > -1) {
                dialogMsg = 'Shipments do not have the same Load/Empty status!';
            } else {
                dialogMsg = 'Unable to divert selected trip(s).';
            }

            return { status: 422, message: dialogMsg };
        }

        return { status: err.status || 400, message: 'Error retrieving trips!' };
    }
}
