import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { BdsDxDataGridModule } from '@bds/components';
import { BourqueCoreModule } from '@bds/core';
import { BdsFastracModule } from '@bds/fastrac';

import { DxLookupModule } from 'devextreme-angular/ui/lookup';
import { DxiValidationRuleModule } from 'devextreme-angular/ui/nested';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { CommonModule } from '@angular/common';
import { DxMatField } from './abstract-components/bds-abstract-control-value.accessor';
import { BdsRailroadSelectComponent } from './components/bds-railroad-select/bds-railroad-select.component';
import { BdsShipmentTypeSelectComponent } from './components/bds-shipment-type-select/bds-shipment-type-select.component';
import { BdsSplcSelectDialogComponent } from './components/bds-splc-select/bds-splc-select-dialog/bds-splc-select-dialog.component';
import { BdsSplcSelectComponent } from './components/bds-splc-select/bds-splc-select.component';
import { BdsCustomerSelectComponent } from './components/bds-customer-select/bds-customer-select.component';
import { BdsStateSelectComponent } from './components/bds-state-select/bds-state-select.component';
import { AnyPipe } from './pipes/any.pipe';
import { LookupPipe } from './pipes/lookup.pipe';
import { BdsRouteSelectComponent } from './components/bds-route-select/bds-route-select.component';
import { BdsDialogMultiConfirmComponent } from './components/bds-dialog-multi-confirm/bds-dialog-multi-confirm.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BdsErpcOriginSelectComponent } from './components/bds-erpc-origin-select/bds-erpc-origin-select.component';
import { BdsCustomerSearchDialogComponent } from './components/search/bds-customer-search-dialog/bds-customer-search-dialog.component';
import { BdsErpcOriginSelectDialogComponent } from './components/bds-erpc-origin-select/bds-erpc-origin-select-dialog/bds-erpc-origin-select-dialog.component';
import { BdsSplcErpcSelectComponent } from './components/bds-splc-erpc-select/bds-splc-erpc-select.component';
import { BdsSplcErpcSelectDialogComponent } from './components/bds-splc-erpc-select/bds-splc-erpc-select-dialog/bds-splc-erpc-select-dialog.component';
import { BdsGridDetailsModule } from '@bds/components';
import { BdsSupplierSelectComponent } from './components/bds-supplier-select/bds-supplier-select.component';
import { BdsSupplierSearchDialogComponent } from './components/search/bds-supplier-search-dialog/bds-supplier-search-dialog.component';
import { DxValidatorModule } from 'devextreme-angular';

@NgModule({
    declarations: [
        DxMatField,
        AnyPipe,
        LookupPipe,
        BdsShipmentTypeSelectComponent,
        BdsCustomerSelectComponent,
        BdsSplcSelectComponent,
        BdsRailroadSelectComponent,
        BdsSplcSelectDialogComponent,
        BdsStateSelectComponent,
        BdsRouteSelectComponent,
        BdsDialogMultiConfirmComponent,
        BdsErpcOriginSelectComponent,
        BdsCustomerSearchDialogComponent,
        BdsErpcOriginSelectDialogComponent,
        BdsSupplierSelectComponent,
        BdsSupplierSearchDialogComponent,
        BdsSplcErpcSelectComponent,
        BdsSplcErpcSelectDialogComponent,
    ],
    imports: [
        DxSelectBoxModule,
        FormsModule,
        CommonModule,
        DxTextBoxModule,
        DxLookupModule,
        DxiValidationRuleModule,
        DxValidatorModule,
        BdsDxDataGridModule,
        DxDataGridModule,
        BourqueCoreModule,
        BdsFastracModule,
        BdsGridDetailsModule,
        MatButtonModule,
        MatDialogModule,
    ],
    exports: [
        DxMatField,
        AnyPipe,
        LookupPipe,
        BdsShipmentTypeSelectComponent,
        BdsCustomerSelectComponent,
        BdsSplcSelectComponent,
        BdsRailroadSelectComponent,
        BdsStateSelectComponent,
        BdsRouteSelectComponent,
        BdsDialogMultiConfirmComponent,
        BdsErpcOriginSelectComponent,
        BdsCustomerSearchDialogComponent,
        BdsErpcOriginSelectDialogComponent,
        BdsSupplierSelectComponent,
        BdsSupplierSearchDialogComponent,
        BdsSplcErpcSelectComponent,
        BdsSplcErpcSelectDialogComponent,
    ],
})
export class BdsSmartComponentsModule {}
