import { Component, OnInit } from '@angular/core';
import { RailtracReportsService } from '../../railtrac-reports.service';
import { PageService } from '@bds/core';
import { ReportConstants } from '../../models/report-parameters.model';

@Component({
    selector: 'lib-transit-time-trip-cycle-detail',
    templateUrl: './transit-time-trip-cycle-detail.component.html',
    styleUrl: './transit-time-trip-cycle-detail.component.css',
})
export class TransitTimeTripCycleDetailComponent implements OnInit {
    public gridId = 128;
    public gridSource: any;
    public displayReport: boolean = false;
    public controlNames: string[] = ['Begin Date', 'End Date'];
    displayedColumns: string[] = [];

    constructor(public reportService: RailtracReportsService, public pageService: PageService) {}

    ngOnInit(): void {
        this.pageService.setHeader('Transit Time Trip Cycle Detail Report');
    }

    onGetReportClick(event: any) {
        this.displayReport = true;
        this.gridSource = this.reportService.CreateAspNetDatastore(
            ReportConstants.ReportDataApiCall,
            this.gridId.toString(),
            event.startDate,
            event.endDate,
        );
    }

    onCancelClick() {
        this.displayReport = false;
    }
}
