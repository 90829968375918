import { Component, OnInit } from '@angular/core';
import { bdsGridModeType } from '@bds/components';
import { RailtracReportsService } from '../../railtrac-reports.service';
import { PageService } from '@bds/core';
import { ReportConstants } from '../../models/report-parameters.model';

@Component({
    selector: 'lib-cs-summary-origin-history',
    templateUrl: './cs-summary-origin-history.component.html',
    styleUrl: './cs-summary-origin-history.component.css',
})
export class CsSummaryOriginHistoryComponent implements OnInit {
    public gridId = 145;
    public gridMode: bdsGridModeType = 'single';
    public apiCall: string = ReportConstants.ReportDataApiCall;
    public pageHeader: string = 'Car Status Summary - Origin History Report';
    public gridSource: any;
    public selectedRowKeys: Array<number> = [];
    public displayReport: boolean = false;
    public controlNames: string[] = ['Process Begin Date', 'Process End Date'];

    constructor(public reportService: RailtracReportsService, public pageService: PageService) {}

    ngOnInit() {
        this.pageService.setHeader(this.pageHeader);
    }

    onGetReportClick(event: any) {
        this.displayReport = true;
        this.gridSource = this.reportService.CreateAspNetDatastore(
            ReportConstants.ReportDataApiCall,
            this.gridId.toString(),
            event.startDate,
            event.endDate,
        );
    }

    onCancelClick() {
        this.displayReport = false;
    }
}
