<dx-data-grid
    id="bdsReportGrid"
    [dataSource]="gridSource"
    height="80vh"
    minHeight="200px"
    [allowColumnResizing]="false"
    dataRowTemplate="dataRowTemplateName"
>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
    <dxi-column caption="Car Init."></dxi-column>
    <dxi-column caption="Car No."></dxi-column>
    <dxi-column dataType="date" [format]="{ type: 'shortDate' }" caption="Ship Date"></dxi-column>
    <dxi-column dataField="" caption="Consignee No."></dxi-column>
    <dxi-column dataField="" caption="Consignee Name"></dxi-column>
    <dxi-column dataField="" caption="CommCode"></dxi-column>
    <dxi-column dataField="" caption="Location City"></dxi-column>
    <dxi-column dataField="" caption="Location State"></dxi-column>
    <dxi-column dataField="" caption="Road"></dxi-column>
    <ng-container *dxTemplate="let item of 'dataRowTemplateName'">
        <tr class="main-row">
            <td>{{ item.data.car_init }}</td>
            <td>{{ item.data.car_no }}</td>
            <td>{{ item.data.ship_date_time }}</td>
            <td>{{ item.data.cust_name }}</td>
            <td>{{ item.data.cust_name }}</td>
            <td>{{ item.data.comm_code }}</td>
            <td>{{ item.data.loc_city }}</td>
            <td>{{ item.data.loc_state }}</td>
            <td>{{ item.data.road }}</td>
        </tr>
        <tr class="second-row">
            <td></td>
            <td colspan="2">
                <div>
                    <span><b>Comments: </b></span>{{ item.data.comment_date }}
                </div>
            </td>
            <td colspan="7">{{ item.data.comments }}</td>
        </tr>
        <tr>
            <td>{{ item.data.comment_type }}</td>
            <td>{{ item.data.comment_code }}</td>
        </tr>
    </ng-container>
</dx-data-grid>
