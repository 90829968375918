<rt-report-parameters
    [controlNames]="controlNames"
    (getReport)="onGetReportClick($event)"
    (cancelReport)="onCancelClick()"
>
</rt-report-parameters>
<div *ngIf="displayReport">
    <dx-data-grid
        id="bdsReportGrid"
        [dataSource]="gridSource"
        height="80vh"
        minHeight="200px"
        [allowColumnResizing]="false"
        dataRowTemplate="dataRowTemplateName"
    >
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
        <dxi-column caption="Fleet ID"></dxi-column>
        <dxi-column caption="Business Group"></dxi-column>
        <dxi-column caption="*  At Origin  *">
            <dxi-column caption="Empty"></dxi-column>
            <dxi-column caption="Loaded"></dxi-column>
        </dxi-column>
        <dxi-column caption="To Consignee"></dxi-column>
        <dxi-column caption="To Shop"></dxi-column>
        <dxi-column caption="At Consignee"></dxi-column>
        <dxi-column caption="At Shop"></dxi-column>
        <dxi-column caption="To Origin"></dxi-column>
        <dxi-column caption="No. Bill"></dxi-column>
        <dxi-column caption="No. Trips"></dxi-column>
        <dxi-column caption="Total"></dxi-column>
        <dxi-column caption="% Utilization"></dxi-column>
        <ng-container *dxTemplate="let item of 'dataRowTemplateName'">
            <tr>
                <td>{{ item.data.fleet_id }}</td>
                <td>{{ item.data.business_group }}</td>
                <td>{{ item.data.at_origin_empty }}</td>
                <td>{{ item.data.at_origin_loaded }}</td>
                <td>{{ item.data.to_consignee }}</td>
                <td>{{ item.data.to_shop }}</td>
                <td>{{ item.data.at_consignee }}</td>
                <td>{{ item.data.at_shop }}</td>
                <td>{{ item.data.to_origin }}</td>
                <td>{{ item.data.no_bills }}</td>
                <td>{{ item.data.no_trips }}</td>
                <td>{{ item.data.total_cars }}</td>
                <td>{{ item.data.percent_util }}</td>
            </tr>
            <tr>
                <td colspan="2">&nbsp;</td>
                <td>{{ item.data.at_origin_empty_percent }} %</td>
                <td>{{ item.data.at_origin_loaded_percent }} %</td>
                <td>{{ item.data.to_consignee_percent }} %</td>
                <td>{{ item.data.to_shop_percent }} %</td>
                <td>{{ item.data.at_consignee_percent }} %</td>
                <td>{{ item.data.at_shop_percent }} %</td>
                <td>{{ item.data.to_origin_percent }} %</td>
                <td>{{ item.data.no_bills_percent }} %</td>
                <td>{{ item.data.no_trips_percent }} %</td>
                <td colspan="2">&nbsp;</td>
            </tr>
        </ng-container>
        <dxo-summary>
            <dxi-total-item
                column="business_group"
                displayFormat="Totals:"
                alignment="right"
            ></dxi-total-item>
            <dxi-total-item
                column="business_group"
                displayFormat="Avg:"
                alignment="right"
            ></dxi-total-item>
            <dxi-total-item
                column="business_group"
                displayFormat="Min:"
                alignment="right"
            ></dxi-total-item>
            <dxi-total-item
                column="business_group"
                displayFormat="Max:"
                alignment="right"
            ></dxi-total-item>
            <dxi-total-item
                column="days_enroute"
                summaryType="sum"
                displayFormat="{0}"
                showInColumn="days_enroute"
            ></dxi-total-item>
            <dxi-total-item
                column="days_enroute"
                summaryType="avg"
                displayFormat="{0}"
                showInColumn="days_enroute"
                valueFormat="###0.##"
            ></dxi-total-item>
            <dxi-total-item
                column="days_enroute"
                summaryType="min"
                displayFormat="{0}"
                showInColumn="days_enroute"
            ></dxi-total-item>
            <dxi-total-item
                column="days_enroute"
                summaryType="max"
                displayFormat="{0}"
                showInColumn="days_enroute"
            ></dxi-total-item>
            <dxi-total-item
                column="days_to_origin"
                summaryType="sum"
                displayFormat="{0}"
                showInColumn="days_to_origin"
            ></dxi-total-item>
            <dxi-total-item
                column="days_to_origin"
                summaryType="avg"
                displayFormat="{0}"
                showInColumn="days_to_origin"
                valueFormat="###0.##"
            ></dxi-total-item>
            <dxi-total-item
                column="days_to_origin"
                summaryType="min"
                displayFormat="{0}"
                showInColumn="days_to_origin"
            ></dxi-total-item>
            <dxi-total-item
                column="days_to_origin"
                summaryType="max"
                displayFormat="{0}"
                showInColumn="days_to_origin"
            ></dxi-total-item>
        </dxo-summary>
    </dx-data-grid>
</div>
