import {
    CdkDragDrop,
    CdkDragEnter,
    CdkDragExit,
    CdkDragSortEvent,
    moveItemInArray,
} from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { faFastrac, faRailtrac, faTrainDuo } from '@bds/fa-svg-icons';
import { Fastrac, FastracDefault, FastracGrid } from '@bds/models';
import { FastracBuilderService, FastracDefaultService } from '@bds/services';
import {
    faAddressBook,
    faChartNetwork,
    faCodeBranch,
    faCog,
    faCogs,
    faFireAlt,
    faFlag,
    faFlaskPotion,
    faHome,
    faMapMarkerAlt,
    faPaperclip,
    faRoute,
    faRss,
    faStar,
    faUsers,
    faWrench,
    faTrain,
    faFile,
    faFolderTree,
    faInfoSquare,
} from '@fortawesome/pro-duotone-svg-icons';
import { faGripLinesVertical } from '@fortawesome/pro-regular-svg-icons';
import { faCaretRight, faEllipsisVAlt } from '@fortawesome/pro-solid-svg-icons';
import { AsyncSubject, Observable, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReferenceApplicationPathProvider } from '@bds/reference-pathing';
import { EquipmentApplicationPathProvider } from '@bds/equipment-pathing';

@Component({
    selector: 'rt-sidenav',
    templateUrl: './rt-sidenav.component.html',
    styleUrls: ['./rt-sidenav.component.scss'],
})
export class RtSidenavComponent implements OnInit {
    FastracGrid = FastracGrid;
    caretRight = faCaretRight;
    defaultFastracs: FastracDefault[];
    fastracs: Fastrac[];
    jeopardizedShipmentFastracs: Fastrac[];
    defaultRouteFastrac: Fastrac;
    divertibleShipmentsFastrac: Fastrac;
    defaultTripFastrac: Fastrac;

    iconHome = faHome;
    iconClms = faRss;
    iconCommodities = faFlaskPotion;
    iconCustomers = faAddressBook;
    iconDiversions = faCodeBranch;
    iconEquipment = faWrench;
    iconEquipmentProfile = faCogs;
    iconFastrac = faFastrac;
    iconFavorites = faStar;
    iconFleetAssignment = faPaperclip;
    iconGear = faCog;
    iconGrip = faGripLinesVertical;
    iconJeopardizedShipments = faFlag;
    iconMore = faEllipsisVAlt;
    iconRecipients = faUsers;
    iconRoutes = faRoute;
    iconTrips = faTrainDuo;
    iconWorkflow = faChartNetwork;
    iconRailtrac = faRailtrac;
    iconOrigins = faMapMarkerAlt;
    iconAdmin = faCog;
    iconHotListGeneral = faFireAlt;
    iconRailroad = faTrain;
    iconFile = faFile;
    iconFolderTree = faFolderTree;
    iconInfoSquare = faInfoSquare;

    showReorder = true;
    sidenavOpened = false;

    private destroyer$ = new AsyncSubject<void>();

    @Input() isAuthenticated: boolean = false;

    @Output() sectionChanged: EventEmitter<string> = new EventEmitter();
    fastracs$: Observable<FastracDefault[]>;

    get fastracDefaults(): FastracDefault[] {
        return [...this.fastracDefaultService.userDefaults];
    }

    constructor(
        public fastracBuilderService: FastracBuilderService,
        public fastracDefaultService: FastracDefaultService,
        private router: Router,
        public referenceAppPathProvider: ReferenceApplicationPathProvider,
        public equipmentAppPathProvider: EquipmentApplicationPathProvider,
    ) {}

    ngOnInit() {
        if (this.isAuthenticated) {
            this.onAuthentication();
            this.fastracs$ = this.fastracDefaultService.getFastracDefaults();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.isAuthenticated &&
            !changes.isAuthenticated.firstChange &&
            this.isAuthenticated
        ) {
            this.onAuthentication();
        }
        this.fastracs$ = this.fastracDefaultService.getFastracDefaults();
    }

    ngOnDestroy() {
        this.destroyer$.next(null);
        this.destroyer$.complete();
    }

    fastracFavListDrop(event: CdkDragDrop<any>) {
        moveItemInArray(this.fastracs, event.previousIndex, event.currentIndex);
        for (let i = 0; i < this.fastracs.length; i++) {
            if (this.fastracs[i].favoriteOrder !== i + 1) {
                this.fastracBuilderService.setFavoritesOrder(this.fastracs[i].id, i + 1);
            }
        }
    }

    fastracFavListEntered(event: CdkDragEnter<any>) {}

    fastracFavListExited(event: CdkDragExit<any>) {}

    fastracFavListSorted(event: CdkDragSortEvent<any>) {}

    getDefaultForRoute(childRoute: string): number {
        if (!childRoute || childRoute.indexOf('/') < 0) {
            return 0;
        }

        const page: string = childRoute.split('/')[1];
        const gridId = FastracGrid[page];
        const gridDefault = this.fastracDefaults.find((d) => d.gridId === gridId);

        if (gridDefault) {
            return gridDefault.fastracId;
        }

        return 0;
    }

    async onAuthentication(): Promise<void> {
        this.fastracBuilderService.fastracList.pipe(takeUntil(this.destroyer$)).subscribe((s) => {
            const routeGridId = FastracGrid['routes'];

            const routeFastracs = (s ?? []).filter(
                (x) => x.gridId === routeGridId && x.ownerId === 'BDS',
            );

            this.defaultRouteFastrac = (routeFastracs ?? []).find((x) => x.name === 'SYSTEM Route');

            this.defaultTripFastrac = (s ?? []).find(
                (x) => x.name === 'SYSTEM Trip' && x.ownerId === 'BDS',
            );

            this.fastracs = [
                ...s
                    .filter((f) => f.isFavorite)
                    .sort((a, b) => (a.favoriteOrder || 0) - (b.favoriteOrder || 0)),
            ];
            const jsGridId = FastracGrid['jeopardized'];

            this.divertibleShipmentsFastrac = (s ?? []).find(
                (x) => x.name === 'Template: Divertable Shipments',
            );

            const jsGridDefault = this.fastracDefaults.find((d) => d.gridId === jsGridId);
            this.jeopardizedShipmentFastracs = [];
            if (!!jsGridDefault) {
                this.jeopardizedShipmentFastracs.push(
                    s.find((f) => f.id === jsGridDefault.fastracId),
                );
            }
            this.jeopardizedShipmentFastracs.push(
                ...s
                    .filter(
                        (f) =>
                            f.gridId === jsGridId &&
                            f.ownerId === 'BDS' &&
                            f.id !== jsGridDefault.fastracId,
                    )
                    .sort((a, b) => {
                        const nameA = a.name.toUpperCase();
                        const nameB = b.name.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    }),
            );
        });

        // TODO: Determine if we need to remove ALL other calls for getting Fastracs - this would be
        // fantastic with using NgRx, but is likely why we get double API calls for most calls.
        // Not sure if this causes a memory leak since no takeUntil on subscription.
        try {
            await firstValueFrom(this.fastracBuilderService.getAllFastracs());
        } catch {
            void this.onAuthentication();
            return;
        }

        if (!this.fastracDefaults.length) {
            await firstValueFrom(this.fastracDefaultService.getFastracDefaults());
        }
    }

    onFastracLinkClicked(fastrac: Fastrac): void {
        if (!fastrac) {
            return;
        }

        const childRoute = '/' + FastracGrid[fastrac.gridId];
        const optionalParams = { fId: fastrac.id };

        this.onItemClicked(childRoute, optionalParams);
    }

    onItemClicked(childRoute: string, optionalParams?: object, queryParams?: object): void {
        if (!childRoute || childRoute.trim() === '/') {
            return;
        }

        if (!optionalParams) {
            optionalParams = {};
        }

        // Set up optional parameters, including fastracId
        if (!optionalParams['fId']) {
            const defaultId = this.getDefaultForRoute(childRoute);

            if (defaultId) {
                optionalParams['fId'] = defaultId;
            }
        }

        if (queryParams && Object.keys(queryParams).length) {
            optionalParams['queryParams'] = queryParams;
        }

        if (Object.keys(optionalParams).length) {
            void this.router.navigate([childRoute, optionalParams], {
                queryParams: optionalParams,
            });
        } else {
            void this.router.navigate([childRoute]);
        }
    }

    onSectionChange(section: string): void {
        this.sectionChanged.emit(section);
    }

    onSidebarMoreButtonClick(event: Event, button: string): void {
        event.stopPropagation();
    }

    getFastracDefault(gridId: number): number {
        const defaultItem: FastracDefault = this.fastracDefaults.find((d) => d.gridId === gridId);

        if (defaultItem) {
            return defaultItem.fastracId;
        }
        return 0;
    }
}
