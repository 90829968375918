<dx-data-grid
    id="bdsReportGrid"
    bds-grid
    #bdsReportGrid
    [dataSource]="gridSource"
    height="80vh"
    minHeight="200px"
    dataRowTemplate="dataRowTemplateName"
>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
    <dxi-column
        caption="Car Init."
        [allowSorting]="true"
        sortOrder="asc"
        sortIndex="0"
    ></dxi-column>
    <dxi-column caption="Car No." [allowSorting]="true" sortOrder="asc" sortIndex="1"></dxi-column>
    <dxi-column caption="Ship Date"></dxi-column>
    <dxi-column caption="Consignee No."></dxi-column>
    <dxi-column caption="Consignee Name"></dxi-column>
    <dxi-column caption="Lease Party"></dxi-column>
    <dxi-column caption="Rider"></dxi-column>
    <dxi-column caption="Lease Start"></dxi-column>
    <dxi-column caption="Lease End"></dxi-column>
    <dxi-column caption="Days to Lend"></dxi-column>
    <dxi-column caption="CommCode"></dxi-column>
    <dxi-column caption="Fleet"></dxi-column>
    <dxi-column caption="Group"></dxi-column>
    <dxi-column caption="Status"></dxi-column>
    <dxi-column caption="Comments"></dxi-column>
    <ng-container *dxTemplate="let item of 'dataRowTemplateName'">
        <tr>
            <td>{{ item.data.car_init }}</td>
            <td>{{ item.data.car_no }}</td>
            <td>{{ item.data.ship_date_time }}</td>
            <td>{{ item.data.cust_no }}</td>
            <td>{{ item.data.cust_name }}</td>
            <td>{{ item.data.lessor_no }}</td>
            <td>{{ item.data.lessor_rider_no }}</td>
            <td>{{ item.data.lessor_beg_date }}</td>
            <td>{{ item.data.lessor_end_date }}</td>
            <td>{{ item.data.dd1 }}</td>
            <td>{{ item.data.comm_code }}</td>
            <td>{{ item.data.fleet_id }}</td>
            <td>{{ item.data.business_group }}</td>
            <td>{{ item.data.car_status }}</td>
            <td>{{ item.data.COMMENTS }}</td>
        </tr>
        <tr>
            <td colspan="3">
                <b>Current Location:</b> {{ item.data.loc_city }} {{ item.data.loc_state }}
            </td>
            <td>{{ item.data.sight_code }}</td>
            <td>{{ item.data.clm_date_time }}</td>
            <td>{{ item.data.le }}</td>
            <td>{{ item.data.road }}</td>
            <td>{{ item.data.dest_city }}</td>
            <td>{{ item.data.dest_state }}</td>
            <td colspan="5"></td>
        </tr>
    </ng-container>
</dx-data-grid>
