<dx-select-box
    [stylingMode]="style"
    [class.bds-mat-dense-form-field]="dense"
    [class.dx-mat-form-field-floatLabel-always]="matFloatLabel === 'always'"
    class="bds-dx-custom-material flex-fill"
    style="opacity: 1"
    [style.margin-bottom.px]="errors ? -1 : 0"
    [(ngModel)]="value"
    [dataSource]="dataSource"
    [valueExpr]="valueExpr"
    [displayExpr]="splcDisplayExpr"
    [searchEnabled]="true"
    searchTimeout="200"
    [dropDownOptions]="popupDim"
    itemTemplate="item"
    fieldTemplate="field"
    (onSelectionChanged)="selected.emit($event?.selectedItem)"
>
    <dxi-button
        *ngIf="enableSearchPopup"
        name="searchOrigin"
        [options]="searchButtonOptions"
    ></dxi-button>
    <dxi-button name="dropDown"></dxi-button>
    <div *dxTemplate="let data of 'field'">
        <dx-text-box
            style="display: inline-block"
            width="100%"
            [value]="getDisplayValue(data)"
            [label]="!required ? placeholder : placeholder + ' *'"
        ></dx-text-box>
    </div>
    <div *dxTemplate="let data of 'item'">
        <div class="custom-icon">
            {{ searchDisplay(data) }}
        </div>
    </div>
</dx-select-box>
<ng-content></ng-content>
<div></div>
