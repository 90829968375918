import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'GetTotalCount' })
export class GetTotalCount implements PipeTransform {
    transform(rowData: any): number {
        var carStatus = rowData.car_status;
        var atOriginEmptyCount = 0;
        var atOriginLoadedCount = 0;
        var toConsigneeCount = 0;
        var toShopCount = 0;
        var atConsigneeCount = 0;
        var atShopCount = 0;
        var toOriginCount = 0;
        var noBillsCount = 0;
        var noTripsCount = 0;
        var totalCount = 0;

        if (carStatus == 'AT ORIGIN EMPTY') {
            atOriginEmptyCount = rowData.car_status_count;
        } else if (carStatus == 'AT ORIGIN LOADED') {
            atOriginLoadedCount = rowData.car_status_count;
        } else if (carStatus == 'TO CONSIGNEE') {
            toConsigneeCount = rowData.car_status_count;
        } else if (carStatus == 'TO SHOP') {
            toShopCount = rowData.car_status_count;
        } else if (carStatus == 'AT CONSIGNEE') {
            atConsigneeCount = rowData.car_status_count;
        } else if (carStatus == 'AT SHOP') {
            atShopCount = rowData.car_status_count;
        } else if (carStatus == 'TO ORIGIN') {
            toOriginCount = rowData.car_status_count;
        } else if (carStatus == 'NO BILLS') {
            noBillsCount = rowData.car_status_count;
        } else if (carStatus == 'NO TRIPS') {
            noTripsCount = rowData.car_status_count;
        }

        totalCount =
            atOriginEmptyCount +
            atOriginLoadedCount +
            toConsigneeCount +
            toShopCount +
            atConsigneeCount +
            atShopCount +
            toOriginCount +
            noBillsCount +
            noTripsCount;
        return totalCount;
    }
}
