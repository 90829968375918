<dx-data-grid
    id="bdsReportGrid"
    bds-grid
    #bdsReportGrid
    [dataSource]="gridSource"
    height="80vh"
    minHeight="200px"
    dataRowTemplate="dataRowTemplateName"
>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
    <dxi-column caption="City"></dxi-column>
    <dxi-column caption="State"></dxi-column>
    <dxi-column caption="TOTAL"></dxi-column>
    <dxi-column caption="@ORIG/E"></dxi-column>
    <dxi-column caption="@ORIG"></dxi-column>
    <dxi-column caption="SPOTTED"></dxi-column>
    <dxi-column caption="ARRIVED"></dxi-column>
    <dxi-column caption="DWELL"></dxi-column>
    <dxi-column caption="TO ORIG"></dxi-column>
    <dxi-column caption="EARLY"></dxi-column>
    <dxi-column caption="ONTIME"></dxi-column>
    <dxi-column caption="LATE"></dxi-column>
    <dxi-column caption="NON MOV"></dxi-column>
    <dxi-column caption="B/O"></dxi-column>
    <ng-container *dxTemplate="let item of 'dataRowTemplateName'">
        <tr>
            <td>{{ item.data.dest_city }}</td>
            <td>{{ item.data.dest_state }}</td>
            <td>{{ item.data.total }}</td>
            <td>{{ item.data.at_origin_empty }}</td>
            <td>{{ item.data.at_origin }}</td>
            <td>{{ item.data.spotted }}</td>
            <td>{{ item.data.arrived }}</td>
            <td>{{ item.data.dwell }}</td>
            <td>{{ item.data.to_origin }}</td>
            <td>{{ item.data.early }}</td>
            <td>{{ item.data.ontime }}</td>
            <td>{{ item.data.late }}</td>
            <td>{{ item.data.non_mov }}</td>
            <td>{{ item.data.bo_count }}</td>
        </tr>
    </ng-container>
</dx-data-grid>
