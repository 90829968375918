import { Component } from '@angular/core';
import { RailtracReportsService } from '../../railtrac-reports.service';
import { PageService } from '@bds/core';
import { ReportConstants } from '../../models/report-parameters.model';

@Component({
    selector: 'lib-cs-summary-by-fleet-business-group',
    templateUrl: './cs-summary-by-fleet-business-group.component.html',
    styleUrl: './cs-summary-by-fleet-business-group.component.css',
})
export class CsSummaryByFleetBusinessGroupComponent {
    public gridId = 140;
    public apiCall: string = ReportConstants.ReportDataApiCall;
    public pageHeader: string = 'Car Status Summary - Current by Fleet & Business Group Report';
    public gridSource: any[];

    constructor(public reportService: RailtracReportsService, public pageService: PageService) {}

    ngOnInit() {
        this.pageService.setHeader(this.pageHeader);
        this.gridSource = this.reportService.CreateAspNetDatastore(
            this.apiCall,
            this.gridId.toString(),
        );
    }
}
