import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { bdsGridModeType } from '@bds/components';
import { CommunicationService } from '@bds/core';
import { FastracScreenBaseComponent } from '@bds/fastrac';
import { CarIdTransformService, ScrollSpyService } from '@bds/helpers';
import { CarIdTransformService as CarIdTransformLocalService } from '@bds/railtrac-internal-services';
import { RtClm, RtClmSightCode, RtClmView } from '@bds/railtrac-models';
import { faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons';
import {
    faClipboardCheck,
    faEdit,
    faPlus,
    faRss,
    faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { default as DataSource, default as DxDataSource } from 'devextreme/data/data_source';
import { RailtracClmService } from '../railtrac-clm/railtrac-clm.service';
import { FastracColsService } from '@bds/services';
import { ErrorListItem } from '@bds/rt-advance-models';
import { CreateTripService } from '@bds/railtrac';
import { EdmLiteral } from 'devextreme/data/odata/utils';
import { DatePipe } from '@angular/common';
import { DxDataGridComponent } from 'devextreme-angular';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'rt-clm-grid',
    templateUrl: './rt-clm-grid.component.html',
    styleUrls: ['./rt-clm-grid.component.scss'],
})
export class RtClmGridComponent
    extends FastracScreenBaseComponent
    implements OnInit, OnChanges, OnDestroy
{
    allowModeToggle = false;
    clmSightCodes: RtClmSightCode[] = [];
    clmTypes: { clmType: string; clmTypeDisplay: string }[];
    gridSource: DxDataSource;
    gridColumns: any;
    gridSelectionMode: bdsGridModeType = 'single';
    iconAdd = faPlus;
    iconClm = faRss;
    iconDelete = faTrashAlt;
    iconEdit = faEdit;
    iconPopout = faExternalLinkAlt;
    iconReapply = faClipboardCheck;
    reapplyClmsLoading = false;
    selectedClm: RtClm;
    selectedRowKeys: number[] = [];
    fastracId = 0;
    showFastrac = false;
    public gridMode: bdsGridModeType = 'single';
    isSaving = false;
    errors: ErrorListItem[] = [];

    @Input() gridId = 98;
    @Input() carInit = '';
    @Input() carNumber = '';
    @Input() disabledFields: string[] | string = '';
    @Input() tripId: number;
    @Input() shipmentDate: Date;
    @Input() showNavigateButton = false;
    @Input() navigateButtonText = '';
    @Output() navigate = new EventEmitter();
    @Output() clmSelect = new EventEmitter<RtClmView>();

    @ViewChild('bdsClmGrid') dataGrid: DxDataGridComponent;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public gridColumnService: FastracColsService,
        public communicationService: CommunicationService,
        public carIdTransformService: CarIdTransformService,
        public carIdTransformLocalService: CarIdTransformLocalService,
        public clmService: RailtracClmService,
        public dialog: MatDialog,
        private datePipe: DatePipe,
        public createTripService: CreateTripService,
        public scrollSpyService: ScrollSpyService,
    ) {
        super(router, route, gridColumnService, communicationService, carIdTransformService);
        this.clmTypes = [
            { clmType: 'Archive', clmTypeDisplay: 'Archived' },
            { clmType: 'Current', clmTypeDisplay: 'Current' },
            { clmType: 'Historical', clmTypeDisplay: 'History' },
        ];

        this.clmService.getClmSightCodes().subscribe((sightCodes: Array<RtClmSightCode>) => {
            sightCodes.sort((a, b) => (a.id < b.id ? -1 : 1));

            for (const sc of sightCodes) {
                if (sc.id === 'Q') {
                    sc.description = 'Start of a Shipment.';
                    break;
                }
            }

            this.clmSightCodes = sightCodes;
        });
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.reloadGrid();
        setTimeout(() => {
            this.dataGrid?.instance?.repaint();
        }, 0);
    }
    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.errors = [];
        const tripIdChange = changes['tripId'];

        if (
            changes.carInit &&
            !changes.carInit.firstChange &&
            changes.carNumber &&
            !changes.carNumber.firstChange
        ) {
            this.reloadGrid();
        } else if (tripIdChange) {
            this.reloadGrid();
        }
    }

    onGridModeChanged(e) {
        console.log('onGridModeChanged', e);
    }

    navigateToSection(): void {
        this.navigate.emit();
    }

    async onSelectionChanged(e) {
        this.errors = [];
        this.selectedClm = e.selectedRowsData[0];
        const clm = await firstValueFrom(this.clmService.getClm(this.selectedClm.ormId));
        this.clmSelect.emit(clm);
    }

    calculateCellValue(rowData): string {
        return `${rowData?.carInit}${rowData?.carNo}`;
    }

    getODataClmApiSource(): DataSource {
        const ds = this.clmService.getODataStore();
        if (this.carInit && this.carNumber) {
            if (this.shipmentDate) {
                const dateLiteral = this.datePipe.transform(
                    this.shipmentDate,
                    "yyyy-MM-dd'T'HH:mm:ss.SSZZZZZ",
                    'UTC',
                );

                ds.filter([
                    ['carInit', '=', this.carInit],
                    'and',
                    ['carNo', '=', this.carNumber],
                    'and',
                    ['clmDateTime', '>=', new EdmLiteral(dateLiteral)],
                ]);
            } else {
                ds.filter([['carInit', '=', this.carInit], 'and', ['carNo', '=', this.carNumber]]);
            }
            ds.sort({ selector: 'clmDateTime', desc: true });
        }
        return ds;
    }

    reloadGrid(): void {
        this.errors = [];
        this.gridSource = this.getODataClmApiSource();
    }

    formatClmSightCodeDisplay(val: RtClmSightCode): string {
        return `${val.id} - ${val.name}`;
    }
}
