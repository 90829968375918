import { Inject, inject, Injectable } from '@angular/core';
import { CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { FastracDefaultCacheService } from '../fastrac-defaults/fastrac-default-cache.service';
import { FastracReaderService } from '../reference/railtrac-reference-services';
import { ApiReadService } from '@bds/odata';
import { catchError, firstValueFrom, of } from 'rxjs';

export const fastracGuard: CanActivateFn = async (route, state) => {
    const fId = +route.queryParamMap.get('fId');
    const gridId = route.data?.gridId;
    return await inject(FastracGuardService).canActivate(gridId, fId, state);
};

@Injectable({ providedIn: 'root' })
export class FastracGuardService {
    constructor(
        private route: Router,
        private fastracDefaults: FastracDefaultCacheService,
        @Inject(FastracReaderService) fastracReader: ApiReadService<any>,
    ) {}

    async canActivate(
        gridId: number,
        fId: number | null,
        state: RouterStateSnapshot,
    ): Promise<boolean | UrlTree> {
        if (fId && fId !== 0) {
            const fastrac = await this.getFastracById(fId);
            if (fastrac && fastrac.gridId === gridId) {
                return true;
            }
        }
        if (gridId) {
            const url = this.route.parseUrl(state.url);
            const defaultFastrac = await this.getFastracDefaultByGridId(gridId);
            if (defaultFastrac) {
                url.queryParams.fId = defaultFastrac;
                return url;
            }
        }
    }

    private async getFastracDefaultByGridId(gridId: number) {
        return firstValueFrom(this.fastracDefaults.getFastracDefaultByGridId(gridId));
    }

    private async getFastracById(fastracId: number) {
        return firstValueFrom(
            inject(FastracReaderService)
                .getByKey(fastracId)
                .pipe(catchError(() => of(null))),
        );
    }
}
