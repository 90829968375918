import { RtCommodityShippedService } from './../../rt-commodity-shipped/rt-commodity-shipped.service';
import {
    TripCommentDialogData,
    RtTripCommentDialogComponent,
} from './../rt-trip-comment-dialog/rt-trip-comment-dialog.component';
import {
    Component,
    OnInit,
    OnChanges,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    NgZone,
    ViewChild,
} from '@angular/core';
import {
    RtTrip,
    RtTripUK,
    RtTripComment,
    RtTripAdapter,
    RtTripRefFieldDef,
    RtCommodityShipped,
    RtCommodityShippedUK,
    RtClmView,
} from '@bds/railtrac-models';
import { PopoutEvent } from '../../popout-event';
import {
    faSpinner,
    faInfo,
    faAddressBook,
    faFlaskPotion,
    faRoute,
    faLayerGroup,
    faWrench,
    faCodeBranch,
    faCommentAltLines,
    faEllipsisVAlt,
    faBars,
    faHandHoldingUsd,
    faFire,
    faExternalLink,
    faCheck,
    faExclamation,
    faClipboard,
    faObjectGroup,
    faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/pro-duotone-svg-icons';
import { faTrain, faClmHistory, faSave } from '@bds/fa-svg-icons';
import { RailtracTripService } from '../railtrac-trip.service';
import { RtDiversionService } from './../../rt-diversion';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScrollSpyService } from '@bds/helpers';
import { Observable, Subscription, firstValueFrom, forkJoin, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import {
    EquipReportCategoryService,
    EquipReportCatgEquipService,
    EquipReportCatgValueService,
} from '@bds/equipment';
import {
    EquipmentReportCategoriesDialogOptions,
    EquipmentReportCategoriesDialogComponent,
} from '@bds/equipment';
import {
    Equipment,
    EquipReportCategory,
    EquipReportCatgEquip,
    EquipReportCatgValue,
} from '@bds/equipment';
import { RtCommodityShippedForm } from '../rt-commodity-shipped-form.service';
import { CommodityShippedService, RtTripCommentService } from '@bds/data-access';
import { ErrorHandlerService } from '@bds/railtrac-internal-services';
import { ErrorListItem, ErrorResultWithObject } from '@bds/rt-advance-models';
import { RailtracClmService } from '../../railtrac-clm/railtrac-clm.service';
import {
    RtMultiTripClmDialogComponent,
    TripClmDialogData,
} from '../rt-multi-trip-clm-dialog/rt-multi-trip-clm-dialog.component';

interface ISelectedTrip {
    id: number;
    key: RtTripUK;
    state: 'none' | 'saving' | 'saved' | 'failed';
    errors?: ErrorListItem[];
}

@Component({
    selector: 'rt-multi-trip',
    templateUrl: './rt-multi-trip.component.html',
    styleUrls: ['./rt-multi-trip.component.scss'],
    providers: [RtCommodityShippedForm],
})
export class RtMultiTripComponent implements OnInit, OnChanges {
    @Input() tripIds: number[];
    @Input() tripRefFieldDefs$: Observable<RtTripRefFieldDef[]>;
    @Input() allowPopout: boolean;
    @Input() isReadOnly = false;
    @Output() popout: EventEmitter<PopoutEvent<number>> = new EventEmitter<PopoutEvent<number>>();

    currentHttpRequest: Subscription;
    combinedTrip: RtTrip;
    selectedTripKeys: RtTripUK[];
    selectedTrips: ISelectedTrip[];

    creatingDiversion = false;

    combinedCommShipped: RtCommodityShipped;
    selectedCommodityShippedKeys: RtCommodityShippedUK[] = [];

    equipCatItemsGroup: { [key: number]: EquipReportCatgEquip[] };
    equipCategoriesGroup: { [key: number]: EquipReportCategory };
    equipCatgValuesGroup: { [key: number]: EquipReportCatgValue };

    equipCatItems: EquipReportCatgEquip[];

    changes: Map<string, any> | any[] | any | undefined;
    commChanges: Map<string, any> | any[] | any | undefined;

    isDirty = false;
    isSaving = false;

    iconSpinner = faSpinner;
    iconTrain = faTrain;
    iconDetail = faInfo;
    iconCustomer = faAddressBook;
    iconCommodity = faFlaskPotion;
    iconRoute = faRoute;
    iconAssignment = faLayerGroup;
    iconHistory = faClmHistory;
    iconProfile = faWrench;
    iconSave = faSave;
    iconComments = faCommentAltLines;
    iconVerticalEllipsis = faEllipsisVAlt;
    iconMenu = faBars;
    iconDetention = faHandHoldingUsd;
    iconDiversion = faCodeBranch;
    iconHotList = faFire;
    iconPopout = faExternalLink;
    iconGroup = faObjectGroup;
    iconAdd = faPlus;
    iconDelete = faTrashAlt;
    iconStatusSaved = faCheck;
    iconStatusSaving = faSpinner;
    iconStatusFailed = faExclamation;
    iconStatusNone = faClipboard;

    @ViewChild('tripdiversionpanel') tripdiversionpanel: MatExpansionPanel;

    constructor(
        private tripService: RailtracTripService,
        private commodityShippedService: RtCommodityShippedService,
        private tripCommentService: RtTripCommentService,
        private equipReportCategoryService: EquipReportCategoryService,
        private equipReportCatgEquipService: EquipReportCatgEquipService,
        private equipReportCatgValueService: EquipReportCatgValueService,
        private adapter: RtTripAdapter,
        private _snackbar: MatSnackBar,
        public scrollSpyService: ScrollSpyService,
        public dialog: MatDialog,
        private zone: NgZone,
        private formService: RtCommodityShippedForm,
        public tripDiversionService: RtDiversionService,
        private errorHandler: ErrorHandlerService,
        private commodityService: CommodityShippedService,
        private clmService: RailtracClmService,
    ) {}

    ngOnInit() {
        this.getCategories();
        this.getCategoryValues();
    }

    //#region EquipmentCategory
    getCategories(): void {
        this.equipReportCategoryService
            .getAll()
            .pipe(take(1))
            .subscribe((res) => {
                const grp: { [key: number]: EquipReportCategory } = {};
                res.map((m) => {
                    grp[m.ormId] = m;
                });
                this.equipCategoriesGroup = grp;
            });
    }

    onDeleteProductClick(ormId: number): void {
        this.commodityService
            .delete(ormId)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this._snackbar.open('Product deleted.', 'Ok');
                    this.selectedCommodityShippedKeys = this.selectedCommodityShippedKeys.filter(
                        (data) => data.ormId !== ormId,
                    );
                },
                error: () => {
                    this._snackbar.open('Error during delete.', 'Ok');
                },
            });
    }

    getCategoryValues(): void {
        this.equipReportCatgValueService
            .getAll()
            .pipe(take(1))
            .subscribe((res) => {
                const grp: { [key: number]: EquipReportCatgValue } = {};
                res.map((m) => {
                    grp[m.ormId] = m;
                });
                this.equipCatgValuesGroup = grp;
            });
    }

    //#endregion EquipmentCategory

    getFilters(trp: any): string {
        let filter = '(';
        if (trp.equipId && trp.shipmentDate) {
            filter += `equipmentId eq ${trp.equipId} and (expireDate ge ${trp.shipmentDate} or expireDate eq null)`;

            if (trp.tripCloseDate) {
                filter += ` and effectDate le ${trp.tripCloseDate}`;
            }
        } else if (trp.equipId) {
            filter = `equipmentId eq ${trp.equipId}`;
        } else {
            console.warn(
                'No filters applied for retrieving equipment categories. Equipment ID is not defined.',
            );
        }
        filter += ')';
        return filter;
    }

    addEquipmentCategory(): void {
        const data = new EquipmentReportCategoriesDialogOptions();
        data.beforeCloseAction = this.processEquipCatg;
        data.title = 'Assign Reporting Categories';
        data.item = this.equipCatItems;
        data.equipment = this.selectedTripKeys.map(
            (m) =>
                <Equipment>{
                    ormId: m.tcmEquipmentOrmId,
                    equipmentInit: m.carInit,
                    equipmentNo: m.carNo,
                },
        );
        const dialogRef = this.dialog.open(EquipmentReportCategoriesDialogComponent, {
            width: '850px',
            data: data,
        });

        dialogRef.afterClosed().subscribe(
            (result) => {
                if (!!result) {
                    this.fetchData();
                    this.zone.run(() => {
                        console.log('running zone defense');
                    });
                    this._snackbar.open('Reporting Categories saved successfully.');
                }
            },
            (error) => {
                this._snackbar.open('Error while saving Reporting Categories.');
            },
        );
    }

    //Get trip(s) data
    fetchData(): void {
        this.tripService
            .readCombined(this.tripIds)
            .pipe(
                tap((s) => {
                    this.combinedTrip = s.trip;
                    this.selectedTrips = s.keys.sort(this.carIdSort).map<ISelectedTrip>((data) => ({
                        id: data.ormId,
                        key: this.adapter.adapt(data),
                        state: 'none',
                    }));
                    this.selectedTripKeys = s.keys.sort(this.carIdSort);
                }),
                switchMap(() =>
                    forkJoin(
                        this.selectedTripKeys.map((m) =>
                            this.equipReportCatgEquipService.getFiltered(
                                this.getFilters({
                                    equipId: m.tcmEquipmentOrmId,
                                    shipmentDate: m.shipDatetime,
                                    tripCloseDate: m.tripCloseDateTime,
                                }),
                            ),
                        ),
                    ),
                ),
            )
            .subscribe((response) => {
                const s = response.reduce((acc, val) => acc.concat(val), []);
                const grp: { [key: number]: EquipReportCatgEquip[] } = {};
                s.map((m) => {
                    (grp[m.equipmentId] = grp[m.equipmentId] || []).push(m);
                });
                this.equipCatItemsGroup = grp;
                this.equipCatItems = s;
            });
    }

    onDiversionCreated(event: boolean): void {
        this.fetchData();
        this.onDiversionHide();
    }

    onDiversionHide(): void {
        if (this.tripdiversionpanel) {
            setTimeout(() => this.tripdiversionpanel.close());
        }
    }

    onDiversionPanelClosed(): void {
        this.creatingDiversion = false;
    }

    onDiversionPanelOpened(): void {
        this.creatingDiversion = true;
    }

    processEquipCatg = (item: EquipReportCatgEquip[]): boolean => {
        // 'ormId': new FormControl(eqCat.ormId, [ ]),
        // 'equipmentId': new FormControl(eqCat.equipmentId, [ ]),
        // 'equipReportCatgId': new FormControl(eqCat.equipReportCatgId, [ ]),
        // 'equipReportCatgValId': new FormControl(eqCat.equipReportCatgValId, [ Validators.required, Validators.min(1) ]),
        // 'effectDate': new FormControl(eqCat.effectDate, [ ]),
        // 'expireDate': new FormControl(eqCat.expireDate, [ ]),
        // 'lastModifiedUser': new FormControl(eqCat.lastModifiedUser, [ ]),
        // 'lastModifiedDatetime': new FormControl(eqCat.lastModifiedDatetime, [ ]),
        // '__status': new FormControl('', [ ]),
        item.forEach((eqCat) => {
            const status = (eqCat as any).__status;
            switch (status) {
                case 'Insert': {
                    eqCat.effectDate = this.selectedTripKeys.filter(
                        (f) => f.tcmEquipmentOrmId === eqCat.equipmentId,
                    )[0].shipDatetime;
                    this.equipReportCatgEquipService.create(eqCat).subscribe((s) => {
                        console.log('equipReportCatgEquipService.create.subscribe', s);
                    });
                    break;
                }
                case 'Delete': {
                    eqCat.expireDate = new Date();
                    this.equipReportCatgEquipService.update(eqCat.ormId, eqCat).subscribe((s) => {
                        console.log('equipReportCatgEquipService.update/delete.subscribe', s);
                    });
                    break;
                }
                case 'Update': {
                    this.equipReportCatgEquipService.update(eqCat.ormId, eqCat).subscribe((s) => {
                        console.log('equipReportCatgEquipService.update.subscribe', s);
                    });
                    break;
                }
            }
        });

        return true;
    };

    private carIdSort = (
        a: { carInit: string; carNo: string },
        b: { carInit: string; carNo: string },
    ) => {
        const nameA = `${(a.carInit + a.carNo).toUpperCase()}`;
        const nameB = `${(b.carInit + b.carNo).toUpperCase()}`;
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        // names must be equal
        return 0;
    };

    ngOnChanges(changes: SimpleChanges) {
        const tripIdChange = changes['tripIds'];
        if (!!tripIdChange) {
            const tripIdCurrent: number[] = tripIdChange.currentValue;
            this.combinedTrip = null;
            this.fetchData();
            this.loadSelectedCommodityShipped(tripIdCurrent);
        }
    }

    loadSelectedCommodityShipped(tripIdCurrent: number[]): void {
        this.commodityShippedService.readCombined(tripIdCurrent).subscribe((s) => {
            this.combinedCommShipped = s.commoditiesShipped;
            this.selectedCommodityShippedKeys = s.keys.sort(this.carIdSort);
        });
    }

    addComment(): void {
        const dialogRef: MatDialogRef<RtTripCommentDialogComponent, RtTripComment[]> =
            this.dialog.open(RtTripCommentDialogComponent, {
                data: {
                    maxLength: 155,
                    tripUKs: this.selectedTripKeys,
                } as TripCommentDialogData,
                minWidth: 480,
            });
        dialogRef.afterClosed().subscribe((comments) => {
            if (comments) {
                const requests: Observable<RtTripComment>[] = [];
                comments.forEach((comment) => {
                    console.warn('MUST CHANGE RT_TRIP_COMMENT.SERVICE TO GET USER NAME!!!!');
                    comment.userId = 'shawnl';
                    requests.push(this.tripCommentService.create(comment));
                });

                forkJoin(requests).subscribe((s) => {
                    this._snackbar.open('Comments have been saved.');
                });
            }
        });
    }

    showTrip(ormId: number): void {
        console.log('showTrip clicked', ormId);
    }

    getDirtyValues($event: Map<string, any> | any[] | any | undefined): void {
        // TODO: Make sure the Trip Refs 7 - 20 are in here
        this.changes = $event;
    }

    scroll(container: HTMLElement, component?: MatExpansionPanel): void {
        container.scrollIntoView({ behavior: 'smooth' } as ScrollIntoViewOptions);
        if (component instanceof MatExpansionPanel) {
            component.open();
        }
    }

    saveChanges(): void {
        this.selectedTrips.forEach((item) => {
            item.state = 'saving';
        });
        let isSuccess = true;
        forkJoin({ saveTrip: this.saveTrip(), saveCommodity: this.saveCommodity() }).subscribe(
            (response) => {
                // TODO this will not handle partial success. Refactor for testing purposes.
                this.isSaving = false;
                this.loadSelectedCommodityShipped(this.tripIds);
                this.isDirty = false;
                this.selectedTrips.forEach((trip) => {
                    let success = true;
                    let errors: ErrorListItem[] = [];
                    if (response.saveTrip) {
                        const status = response.saveTrip.find((i) => i.key === trip.id).value; //response.saveTrip[trip.id];
                        if (status) {
                            success = status.succeeded;
                            let errorObject: ErrorResultWithObject = {
                                bourqueDataResult: status,
                                relatedObject: null,
                            };
                            if (!success) {
                                //Parsing for any other errors generated by DB triggers
                                this.errorHandler.parseResultWithObject(errorObject).subscribe({
                                    error: (err) => {
                                        err.forEach((val) => errors.push(Object.assign({}, val)));
                                    },
                                });
                            }
                        }
                    }
                    if (response.saveCommodity) {
                        const commodityResponse = response.saveCommodity;
                        const commodity = this.selectedCommodityShippedKeys.find(
                            (x) => x.rtTripOrmId === trip.id,
                        );
                        const status = commodityResponse.find((x) => x[commodity.ormId]);
                        if (status) {
                            success = status[commodity.ormId].succeeded;
                        }
                    }
                    trip.state = success ? 'saved' : 'failed';
                    isSuccess = !isSuccess ? isSuccess : success;
                    if (errors.length > 0) {
                        trip.errors = [];
                        errors.forEach((val) => trip.errors.push(Object.assign({}, val)));
                    }
                });

                if (isSuccess) {
                    this._snackbar.open('Saved successfully.', 'Ok');
                    return;
                } else {
                    this._snackbar.open('Saved failed.', 'Ok');
                }
            },
        );
    }

    saveTrip(): Observable<any> {
        if (this.changes) {
            return this.tripService.setCombined(
                this.selectedTrips.map((m) => m.id),
                this.changes,
            );
        }
        return of(null);
    }

    saveCommodity(): Observable<any> {
        let uniqueChanges$ = of([]);
        const uniqueChanges = this.formService.getCommodityShippedUniqueFormChanges(
            this.selectedCommodityShippedKeys ?? [],
        );
        if (uniqueChanges.length > 0) {
            uniqueChanges$ = forkJoin(
                uniqueChanges.map((change) =>
                    this.commodityShippedService.setCombined([change.key.ormId], change.changes),
                ),
            );
        }
        return uniqueChanges$;
    }

    async reApplyClms(): Promise<void> {
        const result = await firstValueFrom(this.clmService.bulkReapplyClms(this.tripIds));

        if (result) {
            if (result.succeeded) {
                this.combinedTrip = null;
                this.fetchData();
                this.loadSelectedCommodityShipped(this.tripIds);
                this._snackbar.open('CLMs Reapplied!', 'Ok', {
                    duration: 3000,
                });
                return;
            }

            this._snackbar.open(result.errors[0].defaultDescription, 'Error', {
                duration: 3000,
            });
        }
    }

    addClmsDialog(): void {
        const clms = this.selectedTripKeys.map(({ carInit, carNo }) => {
            return { carInit, carNo };
        }) as RtClmView[];

        const dialogRef: MatDialogRef<RtMultiTripClmDialogComponent, TripClmDialogData> =
            this.dialog.open(RtMultiTripClmDialogComponent, {
                data: {
                    clms,
                } as TripClmDialogData,
                minWidth: 1000,
            });
        dialogRef.afterClosed().subscribe((clms) => {
            if (clms) {
                const requests: Observable<TripClmDialogData>[] = [];
                forkJoin(requests).subscribe((s) => {
                    this._snackbar.open('CLMs have been saved.');
                });
            }
        });
    }
}
