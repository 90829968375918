import { Component, OnInit } from '@angular/core';
import { RailtracReportsService } from '../../railtrac-reports.service';
import { PageService } from '@bds/core';
import { ReportConstants } from '../../models/report-parameters.model';

@Component({
    selector: 'lib-rt-outbound-by-customer-csr-report',
    templateUrl: './rt-outbound-by-customer-csr-report.component.html',
    styleUrls: ['./rt-outbound-by-customer-csr-report.component.css'],
})
export class RtOutboundByCustomerCsrReportComponent implements OnInit {
    public gridId = 119;
    public apiCall: string = ReportConstants.ReportDataApiCall;
    public pageHeader: string = 'Outbound At Customer By Customer & CSR Report';
    public gridSource: any[];

    constructor(public reportService: RailtracReportsService, public pageService: PageService) {}

    ngOnInit() {
        this.pageService.setHeader(this.pageHeader);
        this.gridSource = this.reportService.CreateAspNetDatastore(
            this.apiCall,
            this.gridId.toString(),
        );
    }
}
