import { Pipe, PipeTransform } from '@angular/core';
import { FastracDefaultCacheService } from '../fastrac-defaults/fastrac-default-cache.service';

@Pipe({
    name: 'defaultFastracQueryParam',
    standalone: true,
})
export class DefaultFastracQueryParamPipe implements PipeTransform {
    constructor(private fastracService: FastracDefaultCacheService) {}
    transform(value: any, ...args: any[]): any {
        const gridId = args[0];

        const fastrac = this.fastracService.getFastracDefaultByGridId(gridId);
        fastrac.subscribe((fId) => {
            if (fId) value['fId'] = fId;
        });

        return value;
    }
}
