import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiErrorHandlerService } from '@bds/helpers';
import { RtRouteCode, RtRouteCodeAdapter } from '@bds/railtrac-models';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map, delay } from 'rxjs/operators';
import ODataStore from 'devextreme/data/odata/store';
import Store from 'devextreme/data/abstract_store';
import DataSource from 'devextreme/data/data_source';
import { BdsInternalDataResult } from '@bds/models';

// @Injectable()
// export abstract class RtRouteApiServiceOptions {
//     apiUrl: string = `api/route/`;
//     odataUrl: string = `odata/route`;
// }

@Injectable({
    providedIn: 'root',
})
export class RtRouteService {
    controllerName = 'route';
    constructor(
        private http: HttpClient,
        //private options: RtRouteApiServiceOptions,
        @Inject('BASE_API_URL') private apibaseUrl: string,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        public adapter: RtRouteCodeAdapter,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    public refreshRouteGridSource = new Subject<boolean>();

    // Observable string streams
    refreshRouteGridChangeEmitted$ = this.refreshRouteGridSource.asObservable();

    read(routeCode: string): Observable<RtRouteCode> {
        return this.readViaOdata(routeCode);
    }

    readViaApi(routeCode: string): Observable<RtRouteCode> {
        return this.http
            .get<RtRouteCode>(`${this.apibaseUrl}${this.controllerName}/${routeCode}`)
            .pipe(
                map((data: any) => this.adapter.adapt(data)),
                //  retry(3),
                catchError(this.handleError),
            );
    }

    readViaOdata(routeCode: string): Observable<RtRouteCode> {
        return this.http
            .get<RtRouteCode>(`${this.odatabaseUrl}${this.controllerName}('${routeCode}')`)
            .pipe(
                map((data: any) => this.adapter.adapt(data)),
                // retry(3),
                catchError(this.handleError),
            );
    }

    // TODO: Replace methods using getStore with this method if possible
    getODataStore(): ODataStore {
        return new ODataStore({
            version: 4,
            url: `${this.odatabaseUrl}${this.controllerName}`,
            key: 'routeCode',
            deserializeDates: false,
        });
    }

    getRouteCodeDataSource(): DataSource {
        return new DataSource({
            store: this.getODataStore(),
            paginate: true,
            pageSize: 100,
        });
    }

    // TODO: Not sure why this method does not have a return of ODataStore, but code requiring it fails to compile if this method is used
    getStore(): Store {
        return new ODataStore({
            version: 4,
            url: `${this.odatabaseUrl}${this.controllerName}`,
            key: 'routeCode',
        });
    }

    create(routeCode: RtRouteCode): Observable<Object> {
        const model = this.adapter.toServer(routeCode);
        model['segments'] = routeCode['segments'];
        return this.http
            .post(`${this.apibaseUrl}${this.controllerName}`, model)
            .pipe(catchError((err) => this.apiErrorHandler.handleError(err)));
    }

    update(trip: RtRouteCode): Observable<Object> {
        const model = this.adapter.toServer(trip);
        model['segments'] = trip['segments'];

        return this.http
            .put(`${this.apibaseUrl}${this.controllerName}/${trip.routeCode}`, model)
            .pipe(catchError((err) => this.apiErrorHandler.handleError(err)));
    }

    delete(routeCode: string): Observable<object> {
        const route = {
            routeCode
        };
        return this.http
            .post(`${this.apibaseUrl}${this.controllerName}/delete`, route)
            .pipe(catchError((err) => this.apiErrorHandler.handleError(err)));
    }

    patch(trip: RtRouteCode): Observable<RtRouteCode> {
        console.warn('RailtracRouteService.patchRoute() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    // https://angular.io/guide/http#getting-error-details
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);

            // return an observable with a user-facing error message
            return throwError('A browser or network error occurred');
        } else if (error.status === 404) {
            // return an observable with a user-facing error message
            return throwError('Not Found');
        } else if (error.status === 422) {
            console.error('Found but cannot be used');
            return throwError(error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}`, error);

            // return an observable with a user-facing error message
            return throwError('The server returned an unknown error');
        }
    }
}
