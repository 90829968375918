import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { OverlayContainer } from '@angular/cdk/overlay';
import { RailtracClmService } from '../../railtrac-clm/railtrac-clm.service';
import { ClmType, RtClmMetadata, RtClmSightCode, RtClmView } from '@bds/railtrac-models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, EMPTY, finalize } from 'rxjs';
import { ErrorListItem } from '@bds/rt-advance-models';
import { faCheck, faClipboard, faExclamation, faSpinner } from '@fortawesome/pro-solid-svg-icons';

export class TripClmDialogData {
    clms: RtClmView[];
}

@Component({
    selector: 'rt-multi-trip-clm-dialog',
    templateUrl: './rt-multi-trip-clm-dialog.component.html',
    styleUrls: ['./rt-multi-trip-clm-dialog.component.scss'],
})
export class RtMultiTripClmDialogComponent implements OnInit {
    clmForm: UntypedFormGroup;
    clms: RtClmView[];
    failedClms: { clm: RtClmView; state: string }[] = [];
    sightCodes: RtClmSightCode[] = [];
    mainClm: RtClmView;
    isSaving = false;
    errors: ErrorListItem[] = [];

    iconStatusSaved = faCheck;
    iconStatusSaving = faSpinner;
    iconStatusFailed = faExclamation;
    iconStatusNone = faClipboard;

    constructor(
        public dialogRef: MatDialogRef<RtMultiTripClmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public options: TripClmDialogData,
        private clmService: RailtracClmService,
        private overlayContainer: OverlayContainer,
        private clmMetadata: RtClmMetadata,
        private _snackbar: MatSnackBar,
    ) {
        this.clmForm = this.clmMetadata.getFormGroup();
        this.clms = this.options.clms;
        this.mainClm = this.clms[0];
        this.failedClms = this.clms.map((x) => {
            return { clm: x, state: '' };
        });
        clmService.getClmSightCodes().subscribe((sightCodes: RtClmSightCode[]) => {
            sightCodes.sort((a, b) => (a.name < b.name ? -1 : 1));

            for (const sc of sightCodes) {
                if (sc.id === 'Q') {
                    sc.description = 'Start of a Shipment.';
                    break;
                }
            }

            this.sightCodes = sightCodes;
        });
    }

    ngOnInit(): void {
        this.overlayContainer.getContainerElement().classList.add('dark-theme');
    }

    add(clms: RtClmView[]) {
        return this.clmService.addClms(clms);
    }

    saveChanges(clm: RtClmView) {
        this.isSaving = true;

        this.failedClms = this.clms.map((x) => {
            return { clm: x, state: 'saving' };
        });

        const clms = this.clms.map(({ carInit, carNo }) => {
            return {
                ...clm,
                carInit: carInit,
                carNo: carNo,
                ormId: 0,
                dwellTime: 0,
                clmType: ClmType.Current,
            };
        }) as RtClmView[];

        const obs = this.add(clms);

        obs.pipe(
            catchError((err) => {
                this.afterError(err);
                return EMPTY;
            }),
            finalize(() => {
                this.isSaving = false;
            }),
        ).subscribe((data) => {
            this.failedClms = [...data.relatedObject];

            if (data.relatedObject.length === 0) {
                this.dialogRef.close();
                this._snackbar.open('CLMs saved!', 'Ok', { duration: 3000 });
                this.isSaving = false;
            }

            this.failedClms = this.clms.map((clm) => {
                const details = data.relatedObject.find(
                    (x) => x.carInit === clm.carInit && x.carNo === clm.carNo,
                );
                return {
                    clm,
                    state: details ? 'failed' : 'saved',
                };
            });
        });
    }

    private afterError(error: ErrorListItem[]) {
        this.errors = error;

        this._snackbar.open('Error saving CLM!', 'Error', {
            duration: 3000,
            panelClass: 'error-snack',
        });
        this.isSaving = false;
    }

    /**
     * Takes a key->value pair and formats it for display on the interface.
     * @param {string} key The "key" value.
     * @param {string} value The "value" value.
     */
    kvpDisplayTemplate(key: string, value: string): string {
        const split = !!key && !!value ? ' - ' : '';

        return `${key}${split}${value}`;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
