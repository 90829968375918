import {
    Component,
    OnInit,
    OnChanges,
    EventEmitter,
    SimpleChanges,
    Input,
    Output,
} from '@angular/core';
import {
    RtCarHotList,
    RtTrip,
    RtClm,
    RtTripComment,
    RtRouteParticipatingRailroad,
    RtDestinationAlias,
    RtTripCommentDialogModel,
    TripCarHotListDto,
} from '@bds/railtrac-models';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import {
    RtRouteParticipatingRailroadDialogOptions,
    BdsRouteParticipatingRailroadDialogComponent,
    BdsRouteParticipatingRailroadService,
} from '@bds/participating-railroad';
import DataSource from 'devextreme/data/data_source';
import { RtCarHotlistDialogComponent } from '../../rt-car-hotlist/rt-car-hotlist-dialog/rt-car-hotlist-dialog.component';
import { RtDestinationAliasDialogComponent } from '../../rt-jeopardized-shipment/rt-destination-alias-dialog/rt-destination-alias-dialog.component';
import {
    RtRailroadService,
    RtTripCommentService,
    RtCarHotListService,
    RtRouteService,
    RtDestinationAliasService,
} from '@bds/data-access';
import { RtTripEdiDialogComponent } from '../rt-trip-edi-dialog/rt-trip-edi-dialog.component';
import { RtTripCommentDialogComponent } from '../../rt-trip-comment/rt-trip-comment-dialog/rt-trip-comment-dialog.component';
import {
    BdsHotListDetailsDialogComponent,
    BdsHotListDialogData,
} from '../../../../../bds/hotlist/src/public-api';
import { firstValueFrom } from 'rxjs';
import { RtJeopardizedShipmentService } from '../../rt-jeopardized-shipment/rt-jeopardized-shipment.service';

export type RtTripMoreOptionsButtonEvents =
    | 'hotlist'
    | 'addComment'
    | 'addDestinationBypass'
    | 'addParticipatingRoad'
    | 'newTrip'
    | 'removeFleet'
    | 'addOriginCriteria'
    | 'addDestinationCriteria'
    | 'showRtEdiData'
    | 'reapplyClms';

@Component({
    selector: 'rt-trip-more-options',
    templateUrl: './rt-trip-more-options.component.html',
    styleUrls: ['./rt-trip-more-options.component.scss'],
})
export class RtTripMoreOptionsComponent implements OnChanges {
    @Input() trip: RtTrip;
    @Input() clms: RtClm[] = [];
    @Output() change: EventEmitter<void> = new EventEmitter<void>();
    @Output() resolve: EventEmitter<RtTripMoreOptionsButtonEvents> =
        new EventEmitter<RtTripMoreOptionsButtonEvents>();

    iconMore = faEllipsisV;

    constructor(
        public participatingRailroadService: BdsRouteParticipatingRailroadService,
        public hotlistService: RtCarHotListService,
        public destinationAliasService: RtDestinationAliasService,
        public tripCommentService: RtTripCommentService,
        public railroadService: RtRailroadService,
        public routeCodeService: RtRouteService,
        public dialog: MatDialog,
        public snackbar: MatSnackBar,
        public jeopardizedShipmentService: RtJeopardizedShipmentService,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes['trip']) {
            const tripCurrVal: RtTrip = changes['trip'].currentValue;
        }
        if (!!changes['clms']) {
            const clmsCurrVal: RtClm[] = changes['clms'].currentValue;
        }
    }

    private findCurrentClm(clms: RtClm[]) {
        return (clms ?? []).reduce(
            (p, c) => (!p ? c : p?.clmDateTime < c?.clmDateTime ? c : p),
            null,
        );
    }

    addToHotlist(): void {
        this.resolve.emit('hotlist');
        let x: RtCarHotList = new RtCarHotList(
            this.trip.carInit,
            this.trip.carNo,
            this.trip.shipDatetime,
        );
        x.ormId = 0;

        const dialogRef = this.dialog.open(RtCarHotlistDialogComponent, {
            width: '550px',
            data: x,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (!!result) {
                this.hotlistService.create(result).subscribe(
                    (r) => {
                        this.snackbar.open('Added car to hotlist', 'Ok', { duration: 3000 });
                    },
                    (err) => {
                        console.error(err);
                        this.snackbar.open('Error adding car to hotlist', 'Ok', { duration: 8000 });
                    },
                );
            }
            x = result;
        });
    }

    addHotList(): void {
        const model = [
            <TripCarHotListDto>{
                tripId: this.trip.ormId,
                carNo: this.trip.carNo,
                carInit: this.trip.carInit,
                hotListType: 'GNRL',
                hotListCategory: 'GNRL',
            },
        ];
        const dialogRef = this.dialog.open(BdsHotListDetailsDialogComponent, {
            data: <BdsHotListDialogData>{
                action: 'add',
                title: 'Add Hot Lists',
                hotLists: model,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.snackbar.open('Added car to hotlist', 'Ok', { duration: 3000 });
                return;
            }
        });
    }

    createDestinationBypass(): void {
        this.resolve.emit('addDestinationBypass');
        let tripDestCity: string;
        let tripDestState: string;
        const currentClm = this.findCurrentClm(this.clms);
        if (!this.trip) {
            tripDestCity = '';
            tripDestState = '';
        } else if (this.trip.carStatus === '1' || this.trip.carStatus === '2') {
            tripDestCity = this.trip.destinationCity;
            tripDestState = this.trip.destinationState;
        } else {
            tripDestCity = this.trip.returnCity;
            tripDestState = this.trip.returnState;
        }
        let x: RtDestinationAlias = new RtDestinationAlias(
            0,
            tripDestCity,
            tripDestState,
            (currentClm || { destinationCity: '' }).destinationCity,
            (currentClm || { destinationState: '' }).destinationState,
        );

        const dialogRef = this.dialog.open(RtDestinationAliasDialogComponent, {
            width: '550px',
            data: { item: x },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (!!result) {
                this.destinationAliasService.create(result);
                this.snackbar.open('Destination Alias added', 'Ok', { duration: 3000 });
                this.change.emit();
            }
            x = result;
        });
    }

    onAddComment(): void {
        const dialogData: RtTripCommentDialogModel = {
            title: 'Add',
            tripComment: new RtTripComment(
                this.trip.carInit,
                this.trip.carNo,
                this.trip.shipDatetime,
                undefined,
                this.trip.userId,
                undefined,
                undefined,
                '',
                undefined,
                undefined,
                this.trip.ormId,
            ),
        };
        const dialogRef = this.dialog.open(RtTripCommentDialogComponent, {
            width: '500px',
            data: dialogData,
        } as MatDialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.change.emit();
                this.resolve.emit('addComment');
            }
        });
    }

    async getAffectedTripCount(): Promise<number> {
        const trips = await firstValueFrom(
            this.jeopardizedShipmentService.findJeopardizedShipmentByRouteCodes(
                [this.trip.routeCode],
                'OFF ROUTE',
            ),
        );

        if (trips) {
            return trips.length;
        }
    }

    async addParticipatingRailroad(): Promise<void> {
        const routeCode = (this.trip || { routeCode: '' }).routeCode;
        const currentClm = this.findCurrentClm(this.clms);
        const road = ((currentClm ? currentClm.road : this.trip.road) ?? '').trim();
        const count = await this.getAffectedTripCount();
        const x: RtRouteParticipatingRailroad = new RtRouteParticipatingRailroad(
            null,
            routeCode,
            road,
        );
        const y: RtRouteParticipatingRailroadDialogOptions = {
            item: x,
            isReadOnly: true,
            affectedTripCount: count ?? 0,
            railroadDataSource: new DataSource({
                store: this.railroadService.getODataStore(),
            }),
            routeCodeDataSource: new DataSource({
                store: this.routeCodeService.getODataStore(),
                paginate: true,
                pageSize: 20,
            }),
        };

        const dialogRef = this.dialog.open(BdsRouteParticipatingRailroadDialogComponent, {
            width: '550px',
            data: y,
        });

        dialogRef.afterClosed().subscribe((result: RtRouteParticipatingRailroad) => {
            if (result) {
                this.participatingRailroadService
                    .add(result)
                    .pipe(take(1))
                    .subscribe(() => {
                        this.change.emit();
                        this.resolve.emit('addDestinationCriteria');
                        this.snackbar.open('Participating Railroad added', 'Ok');
                    });
            }
        });
    }

    removeFromFleet(): void {
        this.resolve.emit('removeFleet');
    }

    newTrip(): void {
        this.resolve.emit('newTrip');
    }

    addOriginCriteria(): void {
        this.resolve.emit('addOriginCriteria');
    }

    addDestinationCriteria(): void {
        this.resolve.emit('addDestinationCriteria');
    }

    showRtEdiData(): void {
        this.resolve.emit('showRtEdiData');
        const dialogRef = this.dialog.open(RtTripEdiDialogComponent, {
            height: '680px',
            width: '900px',
            data: this.trip.ormId,
        });
    }

    reapplyClms(): void {
        this.resolve.emit('reapplyClms');
    }
}
