import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'GetCountByStatus' })
export class GetCountByStatus implements PipeTransform {
    transform(rowData: any, args?: Array<object>): number {
        if (args) {
            var carStatus = rowData.car_status;
            var statusCount = 0;
            var currentColumn = args[0]['currentColumn'].toString();

            if (currentColumn == 'AT ORIGIN EMPTY' && carStatus == 'AT ORIGIN EMPTY') {
                statusCount = rowData.car_status_count;
            } else if (currentColumn == 'AT ORIGIN LOADED' && carStatus == 'AT ORIGIN LOADED') {
                statusCount = rowData.car_status_count;
            } else if (currentColumn == 'TO CONSIGNEE' && carStatus == 'TO CONSIGNEE') {
                statusCount = rowData.car_status_count;
            } else if (currentColumn == 'TO SHOP' && carStatus == 'TO SHOP') {
                statusCount = rowData.car_status_count;
            } else if (currentColumn == 'AT CONSIGNEE' && carStatus == 'AT CONSIGNEE') {
                statusCount = rowData.car_status_count;
            } else if (currentColumn == 'AT SHOP' && carStatus == 'AT SHOP') {
                statusCount = rowData.car_status_count;
            } else if (currentColumn == 'TO ORIGIN' && carStatus == 'TO ORIGIN') {
                statusCount = rowData.car_status_count;
            } else if (currentColumn == 'NO BILLS' && carStatus == 'NO BILLS') {
                statusCount = rowData.car_status_count;
            } else if (currentColumn == 'NO TRIPS' && carStatus == 'NO TRIPS') {
                statusCount = rowData.car_status_count;
            }
            return statusCount;
        }
    }
}
