<bds-fastrac-design-display
    [gridId]="gridId"
    [fastracId]="fastracId"
    [showFastrac]="showFastrac"
    (showFastracChange)="onFastracVisibleChanged($event)"
    (fastracIdChange)="onFastracIdChanged($event)"
    (fastracDesignsChange)="onFastracDesignsChanged($event)"
    (applyFastrac)="onApplyFastrac($event)"
>
</bds-fastrac-design-display>
<bds-fastrac-dx-grid-wrapper
    [dataSource]="gridSource"
    [selectedRowKeys]="selectedRowKeys"
    [gridId]="gridId"
    (bdsShowFastrac)="onShowFastrac()"
    (fastracIdChange)="onGridFastracIdChanged($event)"
    [bdsFastracDesignList]="fastracList"
    [fastracDesign]="currentFastrac"
    [fastracId]="fastracId"
    [bdsCustomizeColumns]="customizeColumns"
    [selectionMode]="gridMode"
>
</bds-fastrac-dx-grid-wrapper>
