import { KeyValue } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { nameof } from '@bds/core';
import { BdsStateService, RtRailroadService } from '@bds/data-access';
import { FormErrorHandlerService } from '@bds/helpers';
import { RtClm, RtClmMetadata, RtClmSightCode, RtClmView, RtStateCode } from '@bds/railtrac-models';
import { dxMatStyle, toDatabaseTime } from '@bds/smart-components';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import DataSource from 'devextreme/data/data_source';
import { BehaviorSubject, Observable } from 'rxjs';
import { RailtracClmService } from '../railtrac-clm.service';

interface ClmDetailsEmittedFields {
    locationSplc?: string;
    locationCity?: string;
    locationState?: string;
}

@Component({
    selector: 'rt-clm-details',
    templateUrl: './railtrac-clm-details.component.html',
    styleUrls: ['./railtrac-clm-details.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class RailtracClmDetailsComponent implements OnChanges {
    @Input() clm: RtClmView;
    @Input() dense = true;
    @Input() disabledFields: Array<string> | string = '';
    @Input() highlightDirty = false;
    @Input() multiClmPopup = false;

    @Output() onClmChange: EventEmitter<ClmDetailsEmittedFields> =
        new EventEmitter<ClmDetailsEmittedFields>();

    clmForm: UntypedFormGroup;
    headerSize = 'h3';
    iconEdit = faEdit;
    loadEmptyFlags = new BehaviorSubject<Array<KeyValue<string, string>>>([]);
    loadEmptyFlags$ = this.loadEmptyFlags.asObservable();
    matFloatLabel = 'always';
    matInputStyle = 'fill';
    matResultStyle = 'standard';
    matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    railroadDxDataSource: DataSource;
    sightCodes: Array<RtClmSightCode>;
    stateCodeLookup: Observable<RtStateCode[]>;

    get dxMatStyle(): string {
        return dxMatStyle(this.matStyle);
    }

    get carId(): string {
        return `${this.clm.carInit}${this.clm.carNo}`;
    }

    get carInit(): string {
        return this.clm.carInit;
    }

    get carNumber(): string {
        return this.clm.carNo;
    }

    get isNew(): boolean {
        return this.clm && !this.clm.ormId;
    }

    get ormId(): number {
        return this.clm.ormId;
    }

    get routeCode(): string {
        return this.clm.routeCode;
    }

    public get ClmForm(): UntypedFormGroup {
        return this.clmForm;
    }

    private _clmDefaultValues: Partial<RtClmView> = {
        clmDateTime: toDatabaseTime(),
    };

    constructor(
        public dialog: MatDialog,
        public clmMetaData: RtClmMetadata,
        public clmService: RailtracClmService,
        private formErrorService: FormErrorHandlerService,
        public stateApiService: BdsStateService,
        public railroadService: RtRailroadService,
    ) {
        // Had to remove {updateOn: 'blur'} to get Shoutcase to work properly
        this.clmForm = new UntypedFormGroup({});
        clmMetaData.setupFormGroup(this.clmForm);
        this.subscribeToFormChanges(this.clmForm);

        clmService.getClmSightCodes().subscribe((sightCodes: Array<RtClmSightCode>) => {
            sightCodes.sort((a, b) => (a.name < b.name ? -1 : 1));

            for (const sc of sightCodes) {
                if (sc.id === 'Q') {
                    sc.description = 'Start of a Shipment.';
                    break;
                }
            }

            this.sightCodes = sightCodes;
        });

        this.stateCodeLookup = stateApiService.getLookup();
        this.railroadDxDataSource = new DataSource({
            store: this.railroadService.getODataStore(),
            searchExpr: ['rrScac', 'rrName'],
            paginate: true,
            pageSize: 100,
        });
    }

    ngOnChanges(): void {
        if (this.clm) {
            if (this.clm.ormId) {
                this.clmForm.reset(this.clm);
            } else {
                this.clmForm.reset({ ...this.clm, ...this._clmDefaultValues });
            }

            this.loadEmptyFlags.next(this.getLoadEmptyFlags());
        }

        this.disableFields();
    }

    /**
     * Based on fields within disabledFields, disable fields on the details.
     */
    disableFields(): void {
        if (!this.disabledFields) {
            return;
        }

        const fields = Array.isArray(this.disabledFields)
            ? this.disabledFields
            : (this.disabledFields as string).split(',');

        for (const field of fields) {
            this.clmForm.controls[field].disable();
        }
    }

    getError(formItem: UntypedFormControl | UntypedFormGroup | UntypedFormArray): string {
        return this.formErrorService.getFormError(formItem);
    }

    /**
     * Get an array of flags for a Load/Empty dropdown.
     * @param allowUnknown should unknown be allowed. Default: true
     */
    getLoadEmptyFlags(allowUnknown?: boolean): {
        key: string;
        value: string;
    }[] {
        const flags = [
            {
                key: 'E',
                value: 'Empty',
            },
            {
                key: 'L',
                value: 'Loaded',
            },
        ];

        const unknownAllowed = allowUnknown == void 0 || allowUnknown;

        if (unknownAllowed && this.clm.loadEmptyFlag == `U`) {
            flags.push({
                key: 'U',
                value: 'Unknown',
            });
        }

        flags.sort();
        return flags;
    }

    /**
     * Takes a key->value pair and formats it for display on the interface.
     * @param {string} key The "key" value.
     * @param {string} value The "value" value.
     */
    kvpDisplayTemplate(key: string, value: string): string {
        const split = !!key && !!value ? ' - ' : '';

        return `${key}${split}${value}`;
    }

    displayRoad = (item: { rrScac: string; rrName: string }): string =>
        item ? `${item?.rrScac} - ${item?.rrName}` : '';

    /**
     * Subscribe to the necessary fields on the specified `form`.
     * @param {FormGroup} form
     */
    subscribeToFormChanges(form: UntypedFormGroup): void {
        form.get(nameof<RtClm>('locationCity')).valueChanges.subscribe((val) => {
            this.onClmChange.emit({
                locationCity: val,
            });
        });

        form.get(nameof<RtClm>('locationSplc')).valueChanges.subscribe((val) => {
            this.onClmChange.emit({
                locationSplc: val,
            });
        });

        form.get(nameof<RtClm>('locationState')).valueChanges.subscribe((val) => {
            this.onClmChange.emit({
                locationState: val,
            });
        });

        form.get(nameof<RtClm>('loadEmptyFlag')).valueChanges.subscribe((val) => {
            let isDirty = form.get(nameof<RtClm>('loadEmptyFlag')).dirty;

            if (isDirty && val != `U`) {
                this.loadEmptyFlags.next(this.getLoadEmptyFlags(false));
            }
        });
    }
}
