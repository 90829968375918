<h4 mat-dialog-title>
    <span>Add CLM to Multiple Cars</span>
</h4>
<div mat-dialog-content>
    <div class="row">
        <div class="col-3">
            <div class="container">
                <div class="row" *ngFor="let clm of failedClms">
                    <span>
                        <ng-container [ngSwitch]="clm.state">
                            <fa-icon
                                [icon]="iconStatusSaved"
                                [fixedWidth]="true"
                                *ngSwitchCase="'saved'"
                                class="text-success"
                            ></fa-icon>
                            <fa-icon
                                [icon]="iconStatusSaving"
                                [fixedWidth]="true"
                                *ngSwitchCase="'saving'"
                                class="text-primary"
                                [spin]="true"
                            ></fa-icon>
                            <fa-icon
                                [icon]="iconStatusFailed"
                                [fixedWidth]="true"
                                *ngSwitchCase="'failed'"
                                class="text-danger"
                            ></fa-icon>
                            <fa-icon
                                [icon]="iconStatusNone"
                                [fixedWidth]="true"
                                *ngSwitchDefault
                                class="text-muted"
                                style="opacity: 0.1"
                            ></fa-icon>
                        </ng-container>
                        {{ clm?.clm?.carInit }}{{ clm?.clm?.carNo }}
                    </span>
                </div>
            </div>
        </div>
        <div class="col-8">
            <rt-clm-details
                [clm]="mainClm"
                [highlightDirty]="true"
                [multiClmPopup]="true"
                #details
            ></rt-clm-details>
        </div>
    </div>
</div>

<div mat-dialog-actions class="flex-row-reverse">
    <button
        mat-button
        class="text-uppercase"
        color="accent"
        (click)="saveChanges(details.clmForm.value)"
        [disabled]="isSaving || details.clmForm.invalid"
    >
        Save
    </button>
    <button mat-button class="text-uppercase" [mat-dialog-close]="" color="accent">Close</button>
</div>
