<div class="col-12">
    <dx-data-grid
        bds-grid
        [bdsAllowGridModeToggle]="allowModeToggle"
        id="bdsClmGrid"
        [dataSource]="gridSource"
        height="600px"
        [allowColumnReordering]="false"
        [allowColumnResizing]="true"
        [columnAutoWidth]="true"
        [showBorders]="true"
        [(selectedRowKeys)]="selectedRowKeys"
        (onSelectionChanged)="onSelectionChanged($event)"
    >
        <dxo-column-chooser [enabled]="true" mode="dragAndDrop"></dxo-column-chooser>
        <dxo-paging [pageSize]="20"></dxo-paging>
        <dxo-selection
            [mode]="gridSelectionMode"
            [allowSelectAll]="false"
            showCheckBoxesMode="always"
        ></dxo-selection>
        <dxo-export [enabled]="true" fileName="RAILTRAC CLM Data"></dxo-export>
        <dxo-scrolling
            mode="virtual"
            rowRenderingMode="virtual"
            columnRenderingMode="virtual"
        ></dxo-scrolling>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>

        <dxi-column dataField="carInit" caption="Car Init"></dxi-column>
        <dxi-column dataField="carNo" caption="Car Number"></dxi-column>
        <dxi-column
            dataField="clmDateTime"
            caption="CLM Date/Time"
            dataType="datetime"
        ></dxi-column>
        <dxi-column dataField="sightCode" caption="Sight Code">
            <dxo-lookup
                [dataSource]="clmSightCodes"
                valueExpr="id"
                [displayExpr]="formatClmSightCodeDisplay"
            >
            </dxo-lookup>
        </dxi-column>
        <dxi-column dataField="locationCity" caption="Location City"></dxi-column>
        <dxi-column dataField="locationState" caption="Location State"></dxi-column>
        <dxi-column dataField="loadEmptyFlag" caption="Load/Empty"></dxi-column>
        <dxi-column dataField="trainJunction" caption="Train Junction"></dxi-column>
        <dxi-column dataField="road" caption="Road"></dxi-column>
        <dxi-column dataField="destinationCity" caption="Destination City"></dxi-column>
        <dxi-column dataField="destinationState" caption="Destination State"></dxi-column>
        <dxi-column dataField="clmType" caption="CLM Type"></dxi-column>
        <dxi-column dataField="clmEvent" caption="CLM Event"></dxi-column>

        <dxo-toolbar *ngIf="showNavigateButton">
            <dxi-item location="before">
                <div bdsScrollSpyLink="tripclmanchor">
                    <dx-button [text]="navigateButtonText" (click)="navigateToSection()">
                    </dx-button>
                </div>
            </dxi-item>
            <dxi-item name="columnChooserButton"></dxi-item>
            <dxi-item name="exportButton"></dxi-item>
        </dxo-toolbar>
    </dx-data-grid>
</div>
