<rt-report-parameters
    [controlNames]="controlNames"
    (getReport)="onGetReportClick($event)"
    (cancelReport)="onCancelClick()"
>
</rt-report-parameters>
<div *ngIf="displayReport">
    <dx-data-grid
        bds-grid
        id="bdsReportGrid"
        [dataSource]="gridSource"
        height="80vh"
        minHeight="200px"
        dataRowTemplate="dataRowTemplateName"
    >
        <dxi-column dataField="NO_OF_TRIPS" caption="No.of Trips"></dxi-column>
        <dxi-column caption="Consignee"></dxi-column>
        <dxi-column caption="Origin"> </dxi-column>
        <dxi-column caption="Destination"></dxi-column>
        <dxi-column caption="Outbound" alignment="center">
            <dxi-column caption="Act Hrs"></dxi-column>
            <dxi-column caption="Rqst Hrs"></dxi-column>
            <dxi-column caption="Var Hrs"></dxi-column>
        </dxi-column>
        <dxi-column caption="# Late Trips"></dxi-column>
        <dxi-column caption="# Early Trips"></dxi-column>
        <ng-container *dxTemplate="let item of 'dataRowTemplateName'">
            <tr class="main-row">
                <td>{{ item.data.NO_OF_TRIPS }}</td>
                <td>{{ item.data.cust_no }} {{ item.data.cust_name }}</td>
                <td>{{ item.data.origin_city }} {{ item.data.origin_state }}</td>
                <td>{{ item.data.dest_city }} {{ item.data.dest_state }}</td>
                <td>{{ item.data.OUT_ACT_HRS }}</td>
                <td>{{ item.data.OUT_REQ_HRS }}</td>
                <td>{{ item.data.OUT_VAR_HRS }}</td>
                <td>{{ item.data.late_trips }}</td>
                <td>{{ item.data.early_trips }}</td>
            </tr>
            <tr class="second-row">
                <td></td>
                <td colspan="4">
                    <div>
                        <span><b>Route Dscr: </b></span>{{ item.data.route_dscr }}
                    </div>
                </td>
                <td colspan="7">
                    <div>
                        <span><b>Dlv Carrier: </b></span>{{ item.data.dlv_road }}
                    </div>
                </td>
            </tr>
        </ng-container>
    </dx-data-grid>
</div>
